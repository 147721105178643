import React from 'react'
import isEmail from 'validator/lib/isEmail'
import ContactStyles from './ContactStyles'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { FaAsterisk } from 'react-icons/fa'
import './contactform.css'
import MediaQuery from 'react-responsive'
import { Spring } from 'react-spring'

const ContactButton = (props) => {
  const buttonStyle = {
    ...props.custom
  }

  const hoverButtonStyle = {
    ...props.custom,
    backgroundImage:'none'
  }

  return(
    <div>
      {
        !props.handleClick ? 
      <a style={{textDecoration:'none'}} href="mailto:sales@7-chord.com">
        <button 
          className="contact-button" 
          onClick={props.handleClick} 
          onMouseEnter={props.hoverOn}
          onMouseLeave={props.hoverOff}
          style={props.hovering ? hoverButtonStyle : buttonStyle}>
          <span>{props.buttonText}</span>
        </button>
      </a> :     
        <button 
          className="contact-button" 
          onClick={props.handleClick} 
          onMouseEnter={props.hoverOn}
          onMouseLeave={props.hoverOff}
          style={props.hovering ? hoverButtonStyle : buttonStyle}>
          <span>{props.buttonText}</span>
        </button>
  }
    </div>


  )
}

///////////////////////////////////////////////////////////////////////////////

class ContactForm extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      exit:false,
      emailError:null,
      fields:{
        firstName:'',
        lastName:'',
        company:'',
        email:'',
        role:'',
        privacyPolicy:false,
        subscribe:false,
        partnership:false
      },
    }
  }

  submitForm = (evt) => {
    evt.preventDefault()
    if (isEmail(this.state.fields.email)) {
      let bodyInfo = Object.assign({}, this.state.fields)
      if (this.state.fields.partnership) bodyInfo['partnership'] = true
      bodyInfo['extraField'] = this.props.extraField
      bodyInfo['subject'] = this.props.subject
      const options = {
        method:'POST',
        body:JSON.stringify(bodyInfo),
        headers:{
          "Content-Type":"application/json; charset=utf-8"
        }
      }
      fetch('/mailer', options)
        .catch(err => console.log(`error ${err}`))
      
      if (this.props.redirect) {
        this.setState({...this.state, emailError:false}, () => this.props.redirect())
      } else {
        this.setState({...this.state, emailError:false})
      }

    } else {
      return this.setState({...this.state, emailError:true})
    }

    if (this.state.fields.subscribe === true) {
      const requestBody = {
        key:'CbFxeEOj5VKuoSZXbfF4JCNOTeT3QFC8',
        email:this.state.fields.email
      }
      const options = {
        method:'POST',
        body:JSON.stringify(requestBody),
        headers:{
          "Content-Type":"application/json; charset=utf-8"
        }
      }
      fetch('/subscribe', options)
        .then(res => console.log(''))
        .catch(err => console.log(`error :${err}`))
    }
  }


  validateSubmit = () => {
    if (
      this.state.fields.firstName === '' ||
      this.state.fields.lastName === '' ||
      this.state.fields.company === '' ||
      this.state.fields.email === '' ||
      this.state.fields.role === '' ||
      this.state.fields.privacyPolicy === false
      ) {
          return true
    }
    return false
  }

  handleFormUpdates = (evt) => {
    const fields = Object.assign({}, this.state.fields)
    fields[evt.target.name] = evt.target.value
    this.setState({fields})
  }

  handleCheckboxUpdates = (evt) => {
    const fields = Object.assign({}, this.state.fields)
    const value =  !fields[evt.target.name]
    fields[evt.target.name] = value
    this.setState({fields})
  }

  handleExitHover = (color) => {
    const exit = !this.state.exit
    this.setState({...this.state, exit})
  }


  render(){
    return(
     <div>
       <MediaQuery maxWidth={699}>
        <Spring
            from={{opacity:'0', transform:'scale(0,0)'}}
            to={{opacity:'1', transform:'scale(1,1'}}
        >
          {props => 
                      <div style={{...ContactStyles.formContainerMobile, ...this.props.positioning, ...props}}>
                      <h2 style={ContactStyles.formHeadingStyleMobile}>{this.props.formTitle}</h2>
                      {
                        !this.props.redirect?
                        <IoIosCloseCircleOutline
                        onMouseEnter={this.handleExitHover}
                        onMouseLeave={this.handleExitHover}
                        onClick={this.props.handleClick}
                        style={this.state.exit ? {...ContactStyles.exitIcon, color:'#D8D8D8'} : ContactStyles.exitIconMobile}
                      /> : null
                      }

                    <div style={ContactStyles.subheadingStyleMobile}>
                      {this.props.subHeading}
                    </div>
                    <form
                      style={ContactStyles.formStyleMobile}
                      onSubmit={this.submitForm}
                    >
                      <div style={ContactStyles.nameContainerMobile}>
                        <label style={ContactStyles.labelStyleMobile}>
                          FIRST NAME
                          <FaAsterisk style={ContactStyles.asterisk}/><br/>
                          <input
                            name="firstName"
                            className="contactform"
                            style={ContactStyles.nameInputStyleMobile}
                            type="text"
                            value={this.state.fields.name}
                            onChange={this.handleFormUpdates}
                          />
                        </label>
                      </div>
                      <br/>
        
                      <div style={ContactStyles.nameContainerMobile}>
                        <label style={ContactStyles.labelStyleMobile}>
                          LAST NAME
                          <FaAsterisk style={ContactStyles.asterisk}/><br/>
                          <input
                            name="lastName"
                            className="contactform"
                            style={ContactStyles.nameInputStyleMobile}
                            type="text"
                            value={this.state.fields.name}
                            onChange={this.handleFormUpdates}
                          />
                          </label>
                        </div>
                        <br/>
        
                        <label style={ContactStyles.labelStyleMobile}>
                          COMPANY
                          <FaAsterisk style={ContactStyles.asterisk}/><br/>
                          <input
                            name="company"
                            className="contactform"
                            style={ContactStyles.inputStyleMobile}
                            type="text"
                            value={this.state.fields.compny}
                            onChange={this.handleFormUpdates}
                          />
                        </label>
                        <br/>
        
                        <label style={ContactStyles.labelStyleMobile}>
                          WORK EMAIL
                          <FaAsterisk style={ContactStyles.asterisk}/>
                          {this.state.emailError ? <span style={{color:`#F56464`, marginLeft:'10px'}}>Invalid Email </span> : null }
                          <br/>
                          <input
                            name="email"
                            className="contactform"
                            style={ContactStyles.inputStyleMobile}
                            type="text"
                            value={this.state.fields.email}
                            onChange={this.handleFormUpdates}
                          />
                        </label>
                        <br/>
        
                        <label style={ContactStyles.labelStyleMobile}>
                          WHAT IS YOUR ROLE?
                          <FaAsterisk style={ContactStyles.asterisk}/><br/>
                          <input
                            name="role"
                            className="contactform"
                            style={ContactStyles.inputStyleMobile}
                            type="text"
                            value={this.state.fields.role}
                            onChange={this.handleFormUpdates}
                          />
                          </label>
                          <br/>
                          {this.props.children}
                          <br/>
        
                        <label style={ContactStyles.checkboxMobile}>
                          <input
                            name="privacyPolicy"
                            className="checkbox"
                            style={ContactStyles.squareCheckboxStyle}
                            type="checkbox"
                            onChange={this.handleCheckboxUpdates}
                            checked={this.state.fields.privacyPolicy}
                          />
                          I agree to 7 Chord's Privacy Policy
                          <FaAsterisk style={ContactStyles.asterisk}/><br/>
                        </label><br/>
                        
                        {
                          this.props.partnership ?
                          <label style={ContactStyles.checkboxMobile}>
                            <input
                              name="partnership"
                              className="checkbox"
                              style={ContactStyles.squareCheckboxStyle}
                              type="checkbox"
                              onChange={this.handleCheckboxUpdates}
                              checked={this.state.fields.partnership}
                            ></input>
                            I'm interested in a POC or strategic partnership
                          </label> : null
                        }
        
                        {this.props.subscribe ?
                          <label style={ContactStyles.checkboxMobile}>
                            <input
                              name="subscribe"
                              className="checkbox"
                              style={ContactStyles.squareCheckboxStyle}
                              type="checkbox"
                              onChange={this.handleCheckboxUpdates}
                              checked={this.state.fields.subscribe}
                            />
                            Subscribe me to your updates
                          </label> : null
                        }
        
                        <br/><br/>
                      <input
                        style={this.validateSubmit() ? 
                          {...ContactStyles.submitButtonMobile, backgroundColor:'#979797', border:'1px solid #979797'} : ContactStyles.submitButtonMobile}
                        type="submit"
                        value="SEND"
                        disabled={this.validateSubmit()}
                      />
                      {this.state.emailError === false ? <span style={{color:'#2BB673'}}>Email Sent!</span> : null}
                      <br/><br/>
        
                    </form>
                  </div>
          }
        </Spring>
      </MediaQuery>

        
       <MediaQuery minWidth={700}>
          <Spring
            from={{opacity:'0', transform:'scale(0,0)'}}
            to={{opacity:'1', transform:'scale(1,1'}}
          >
            {
              props => <div style={{...ContactStyles.formContainer, ...this.props.positioning, ...props}}>
              <span>
                <h2 style={ContactStyles.formHeadingStyle}>{this.props.formTitle}</h2>
                {
                        !this.props.redirect?
                        <IoIosCloseCircleOutline
                        onMouseEnter={this.handleExitHover}
                        onMouseLeave={this.handleExitHover}
                        onClick={this.props.handleClick}
                        style={this.state.exit ? {...ContactStyles.exitIcon, color:'#D8D8D8'} : ContactStyles.exitIconMobile}
                      /> : null
                      }
              </span>
              <div style={ContactStyles.subheadingStyle}>
                {this.props.subHeading}
              </div>
              <form
                style={ContactStyles.formStyle}
                onSubmit={this.submitForm}
              >
                <div style={ContactStyles.nameContainer}>
                  <label style={ContactStyles.labelStyle}>
                    FIRST NAME
                    <FaAsterisk style={ContactStyles.asterisk}/><br/>
                    <input
                      name="firstName"
                      className="contactform"
                      style={ContactStyles.nameInputStyle}
                      type="text"
                      value={this.state.fields.name}
                      onChange={this.handleFormUpdates}
                    />
                  </label>
                </div>
    
                <div style={ContactStyles.nameContainer}>
                  <label style={ContactStyles.labelStyle}>
                    LAST NAME
                    <FaAsterisk style={ContactStyles.asterisk}/><br/>
                    <input
                      name="lastName"
                      className="contactform"
                      style={ContactStyles.nameInputStyle}
                      type="text"
                      value={this.state.fields.name}
                      onChange={this.handleFormUpdates}
                    />
                    </label>
                  </div>
                  <br/>
    
                  <label style={ContactStyles.labelStyle}>
                    COMPANY
                    <FaAsterisk style={ContactStyles.asterisk}/><br/>
                    <input
                      name="company"
                      className="contactform"
                      style={ContactStyles.inputStyle}
                      type="text"
                      value={this.state.fields.compny}
                      onChange={this.handleFormUpdates}
                    />
                  </label>
                  <br/>
    
                  <label style={ContactStyles.labelStyle}>
                    WORK EMAIL
                    <FaAsterisk style={ContactStyles.asterisk}/>
                    {this.state.emailError ? <span style={{color:`#F56464`, marginLeft:'10px'}}>Invalid Email </span> : null }
                    <br/>
                    <input
                      name="email"
                      className="contactform"
                      style={ContactStyles.inputStyle}
                      type="text"
                      value={this.state.fields.email}
                      onChange={this.handleFormUpdates}
                    />
                  </label>
                  <br/>
    
                  <label style={ContactStyles.labelStyle}>
                    WHAT IS YOUR ROLE?
                    <FaAsterisk style={ContactStyles.asterisk}/><br/>
                    <input
                      name="role"
                      className="contactform"
                      style={ContactStyles.inputStyle}
                      type="text"
                      value={this.state.fields.role}
                      onChange={this.handleFormUpdates}
                    />
                    </label>
                    <br/>
                    {this.props.children}
                    <br/>
    
                  <label style={ContactStyles.checkboxLabel}>
                    <input
                      name="privacyPolicy"
                      className="checkbox"
                      style={ContactStyles.squareCheckboxStyle}
                      type="checkbox"
                      onChange={this.handleCheckboxUpdates}
                      checked={this.state.fields.privacyPolicy}
                    />
                    I agree to 7 Chord's Privacy Policy
                    <FaAsterisk style={ContactStyles.asterisk}/><br/>
                  </label><br/>
                  
                  {
                    this.props.partnership ?
                    <label>
                      <input
                        name="partnership"
                        className="checkbox"
                        style={ContactStyles.squareCheckboxStyle}
                        type="checkbox"
                        onChange={this.handleCheckboxUpdates}
                        checked={this.state.fields.partnership}
                      ></input>
                      I'm interested in a POC or strategic partnership
                    </label> : null
                  }
    
                  {this.props.subscribe ?
                    <label>
                      <input
                        name="subscribe"
                        className="checkbox"
                        style={ContactStyles.squareCheckboxStyle}
                        type="checkbox"
                        onChange={this.handleCheckboxUpdates}
                        checked={this.state.fields.subscribe}
                      />
                      Subscribe me to your updates
                    </label> : null
                  }
    
                  <br/><br/>
                <input
                  style={this.validateSubmit() ? 
                    {...ContactStyles.submitButton, backgroundColor:'#979797', border:'1px solid #979797'} : {...ContactStyles.submitButton, cursor:'pointer'}}
                  type="submit"
                  value="SEND"
                  disabled={this.validateSubmit()}
                />
                {this.state.emailError === false ? <span style={{color:'#2BB673'}}>Email Sent!</span> : null}
                <br/><br/>
    
    
              </form>
            </div>
            }
          </Spring>

       </MediaQuery>
     </div>
    )
  }
}

export {ContactButton, ContactForm};
