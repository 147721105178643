import React from 'react'
import BigFooter from './Footer'
import SmallFooter from './FooterResponsive'
import MediaQuery from 'react-responsive'


const Footer = (props) => {
    return(
        <div>
            <MediaQuery maxWidth={950}>
                <SmallFooter/>
            </MediaQuery>
            <MediaQuery minWidth={951}>
                <BigFooter/>
            </MediaQuery>
        </div>
    )
}

export default Footer;