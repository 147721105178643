import React from 'react'
import MediaQuery from 'react-responsive'
import { Link } from 'react-router-dom'
import './Styles/Footer.css'
import Logo from '../../images/Shared/logoOpt.png'

const Footer = (props) => {
    const footerStyle = {
        borderTop:'3px solid rgb(56, 38, 48)',
        width:'60%',
        margin:'0 auto',
        marginTop:'10px'
    }

    const footerItemContainer = {
        width:'calc(33.1%)',
        display:'inline-block',
        marginBottom:'15px'
    }

    const footerItemSmall = {
        with:'50%',
        display:'block',
        margin:'0 auto'
    }

    const logoStyle = {
        height:'30px',
        width:'100px',
        marginTop:'10px'
    }

    const textStyle = {
        position:'relative',
        bottom:'12px',
        fontSize:'14px',
    }

    const textStyleSmall = {
        ...textStyle,
        bottom:'0',
        marginBottom:'15px'
    }

    const contactTextStyle = {
        ...textStyle,
        color:'#27CCE1',
        fontWeight:'700',
        textDecoration:'none'
    }

    const contactTextStyleSmall = {
        ...contactTextStyle,
        ...textStyleSmall,
        margin:'15px 0'
    }

    return(
        <div>
            <MediaQuery maxWidth={1150}>
                <div style={footerStyle}>
                    <div style={footerItemSmall}>
                        <Link className="footer-item" to="/">
                                <img style={logoStyle} src={Logo} />
                        </Link>
                    </div>
                    <div style={footerItemSmall}>
                        <a href="mailto:support@7-chord.com" 
                                style={contactTextStyleSmall}
                                className="footer-item">
                                support@7-chord.com
                        </a>
                    </div>
                    <div style={footerItemSmall}>
                        <span className="footer-item"
                                style={textStyleSmall}>
                                @7 Chord, Inc. All Rights Reserved
                        </span>
                    </div>
                </div>
            </MediaQuery>

            <MediaQuery minWidth={1151}>
                <div style={footerStyle}>
                    <div style={footerItemContainer}>
                        <Link className="footer-item" to="/">
                            <img style={logoStyle} src={Logo} />
                        </Link>
                    </div>

                    <div style={footerItemContainer}>
                        <a href="mailto:support@7-chord.com" 
                            style={contactTextStyle}
                            className="footer-item">
                            support@7-chord.com
                        </a>
                    </div>

                    <div style={footerItemContainer}>
                        <span className="footer-item"
                            style={textStyle}>
                            @7 Chord, Inc. All Rights Reserved
                        </span>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default Footer;