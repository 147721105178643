import Event2 from '../../images/Events/Event2.jpg'
import Event3 from '../../images/Events/Event3.jpg'
import Event4 from '../../images/Events/Event4.jpg'
import Event7 from '../../images/Events/Event7.jpg'
import Bob from '../../images/Events/bobdouglas.jpg'
import Demo from '../../images/Events/fintechdemoday.png'
import Accelerator from '../../images/Events/Accelerator.jpg'
import Roy from '../../images/Events/roy.jpg'

const eventsData = {}

eventsData.row1 = {
    video:'https://www.youtube.com/watch?v=TzITNcGlgKE&t=',
    date:'OCT 30 2018',
    title:'Artificial Intelligence/Machine Learning for Investment Managers (Buy-side panel)',
    subtitle:'The Dynamics Driving Financial Markets, New York Conference, 2018',
    author:'Quantifi, Inc.',
    link:'https://www.quantifisolutions.com/the-dynamics-driving-financial-markets-new-york-2018',
    description:`Quantifi's 5th annual risk conference, at The Yale Club, New York, attracted delegates from across the industry. The panel moderated by 7 Chord’s Kristina Fan explored the current landscape of AI applications in asset/investment management.`
}

eventsData.row2 = {
    photo:Event2,
    date:'SEP 26 2018',
    title:'Are AI and Machine Learning able to change our business? ',
    subtitle:`AI and Machine Learning for Systematic Strategies Conference`,
    author:'BNP Paribas',
    link:'https://bnp.eventogy.com/c/AIandMachineLearningforSystematicStrategies/agenda',
    description:`BNP Paribas hosted the first AI and Machine Learning for Systematic Strategies event. The full day agenda of industry speakers, including 7 Chord, for a packed room of over 95 attendees. `
}

eventsData.row3 = {
    photo:Event3,
    date:'MAY 24 2018',
    title:'Fintech Pitch Event',
    subtitle:'FinTech Connect: Innovating Banking and Financial Services',
    author:'Venture Cafe Kendall, Sponsored by HSBC',
    link:'https://vencaf.org/fintechconnect/',
    description:`Entrepreneurs who are innovating banking and financial services pitched in front of the awesome panel of judges: fintech VCs and angels.`

}

eventsData.row4 = {
    photo:Event4,
    date:'MAY 23 2018',
    title:'How Can FinTechs and Banks work together?',
    subtitle:'Barclays Annual Agile Conference',
    author:'Barclays',
    description:`How can banks and FinTech firms work together? Barclays accelerator start-ups Bank Novo, 7 Chord and Cutover shared their experiences at the Barclays' annual Agile Conference on May 23rd. Moderated by the panel wizard Ben Shaw, Barclays.`
}

eventsData.row5 = {
    video:'https://www.youtube.com/watch?v=3uADTtdHJTk',
    date:'APR 19 2018',
    title:'Demo Day 5.0',
    subtitle:'FinTechSandbox Demo Day',
    link:'https://splashthat.com/sites/view/fintechsandboxdemoday5.splashthat.com',
    author:`At Moody's Analytics`,
    description:`First ever FinTechSandbox Demo Day in New York at Moody's Analytics headquarters in the Financial District. Watch 7 Chord’s pitch here.`
}

eventsData.row6 = {
    video:'https://www.youtube.com/watch?time_continue=4&v=4pPSlfBiwF4',
    date:'OCT 30 2018',
    light:Demo,
    title:'FinTechSandbox Startup Testimonials',
    subtitle:'FinTechSandbox Demo Day',
    author:'7 Chord',
    description:`The teams at Elsen, Cognism and 7 Chord share updates on their progress catalyzed by their participation in the Sandbox program.`
}

eventsData.row7 = {
    photo:Event7,
    date:'MAR 23, 2018',
    title:'HackNYU 2018',
    subtitle:'Hackathon, Makerspace at Tandon School of Engineering',
    link:'http://hacknyu.org/sponsor.html',
    author:'NYU Community',
    description:`7 Chord was a proud sponsor of HackNYU’s annual 48-hour hackathon. We’ve met amazing young men and women who used their software engineering skills to solve real-life problems. `
}

eventsData.row10 = {
    video:'https://techstars.wistia.com/medias/s9raizvb9k',
    light:Bob,
    date:'FEB 6 2018',
    title:'Barclays | Techstars Demo Day 2017',
    subtitle:'Investor Demo Day @ RISE',
    author:'Techstars',
    description:`During the incredible 16 weeks with Techstars and thanks to the support of Barclays credit business we were able to identify new use cases for our predictive pricing and analytics tool-kit. Watch our Demo Day video. `

}

eventsData.row11 = {
    video:'https://www.youtube.com/watch?time_continue=39&v=l6vbjMRZzN0',
    date:'JAN 23 2018',
    light:Accelerator,
    title:'About Barclays Accelerator, powered by Techstars',
    author:'Barclays',
    description:`This is how our firm benefited from being part of the world’s most competitive award winning accelerator, combining the power of Barclays and Techstars. `    
}

eventsData.row12 = {
    video:'https://www.youtube.com/watch?v=eVJJR_iOz6A',
    date:'MAR 29 2019',
    light:Roy,
    title:'MLConf 2019',
    subtitle:'Sharing Lessons Learned in Machine Learning Best Practices',
    author:'MLconf',
    description:"7 Chord's Chief Scientist, Roy Lowrance, talks about handling non-stationarity in financial time-series."
}



export default eventsData;