import React from 'react'
import { Link } from 'react-router-dom'
import { ContactButton } from '../Shared/Forms/ContactForm'
import './Styles/Option.css'

const ProductToggle = (props) => {
    const productStyle = {
        textDecoration:'underline',
        cursor:'pointer'
    }

    return(
        <span style={productStyle}>{props.product}</span>
    )
}


const textStyle = {}
const InitialContent = (props) => {
    const linkStyle = {
        color:'#262A21',
    }

    const supportButton = {
        width:'282px',
        height:'40px',
        border:'1px solid #27CCE1',
        borderRadius:'30px',
        display:'inline-block',
        padding:'0'
    }

    return(
        <div>
            <h1 style={textStyle}>Welcome To BondDroid<sup style={{fontSize:'12px', top:'-1.5em'}}>TM</sup> AutoPrice</h1>
            <p style={textStyle}>
                BondDroid AutoPricer is is an AI system that predicts trade bid and ask levels for thousands 
                of corporate bonds with speed and precision impossible for any human to replicate. It uses a 
                unique approach designed to deliver accuracy across market regimes, issuer credit quality and 
                instrument life cycles. 
            </p>
                
            <p style={textStyle}>
                You can access BondDroid’s price  predictions in real-time via <ProductToggle product="FIX API"/>, <ProductToggle product="Excel API"/> and a <ProductToggle product="web app"/>, which can 
                be launched in a browser or via OpenFin.
            </p>
            <p style={textStyle}>
                In addition, we offer end-of-day snapshot of all price predictions made during the day as a bulk data file 
                delivered via <ProductToggle product="FTP" />.
            </p>
            
            <p style={textStyle}>
                This guide doesn’t cover deployment of BondDroid Enterprise, our software solution. 
                For more information about it, please contact support@7-chord.com.
            </p>
            
            <p style={textStyle}>
                To compare  <Link style={linkStyle} to="/plans">BondDroid Pro</Link>, <Link style={linkStyle} to="/plans">BondDroid Data</Link>, and <Link style={linkStyle} to="/plans">BondDroid Enterprise</Link> please go to <Link style={linkStyle} to="/plans">Plans </Link>
            </p>
            
            <ContactButton
                custom={supportButton}
                buttonText="CONTACT SUPPORT"/>

        </div>
    )
}


export default InitialContent;