import BusinessWire from '../../images/Press/BusinessWire.svg'
import Cision from '../../images/Press/Cision.svg'
import MarketsMedia from '../../images/Press/MarketsMedia.svg'
import TechStars from '../../images/Press/Techstars.svg'
import Quantifi from '../../images/Press/Quantifi.svg'
import Diligently from '../../images/Press/Diligently.svg'
import Barclays from '../../images/Press/Barclays.svg'
import Harrington from '../../images/Press/harrington.png'

const PressReleaseData = {}
const PressArticleData = {}


PressReleaseData.row1 = {
    image:BusinessWire,
    subheading:'Business Wire',
    date:'December 6, 2018',
    title:`7 Chord Inc. Partners with NYU to Bring Deep Tech to Bond Market`,
    description:`7 Chord, Inc., creator of BondDroid™, a proprietary AI system that forecasts bond market trends, was among the 8% of applicants accepted into Creative Destruction Lab-New York City (CDL-NYC) at NYU’s Stern School of Business.`,
    link:`https://www.businesswire.com/news/home/20181206006058/en/7-Chord-Partners-NYU-Bring-Deep-Tech`
}

PressReleaseData.row2 = {
    image:BusinessWire,
    subheading:'Business Wire',
    date:'February 6, 2018',
    title:`Barclays Helps Deliver Breakthrough Innovations in FinTech with New York Accelerator Program`,
    description:`Barclays’ Credit business is supporting 7 Chord with product analysis to identify use cases for their predictive pricing and alpha signals tool.`,
    link:`https://www.businesswire.com/news/home/20180206005950/en/Barclays-Helps-Deliver-Breakthrough-Innovations-FinTech-New`
}

PressReleaseData.row3 = {
    image:MarketsMedia,
    subheading:'Markets Media',
    date:'November 7, 2017',
    title:`2017 Women in Finance Awards Announced`,
    description:`7 Chord’s Founder wins Markets Media Women in Finance Award 2017 for Excellence in Trading Platforms.`,
    link:`https://www.marketsmedia.com/2017-women-finance-award-winners-announced/`
}

PressReleaseData.row4 = {
    image:TechStars,
    subheading:'Techstars',
    date:'October 23, 2017',
    title:`Announcing the 2017 Class of the Barclays Accelerator, Powered by Techstars in NYC!`,
    description:`7 Chord, Inc. is among the nine companies that will be joining the 2017 Barclays Accelerator, Powered by Techstars in NYC. Over the next three months the companies will continue their ascent, redefining financial services as we know it.`,
    link:`https://www.techstars.com/content/accelerators/announcing-2017-class-barclays-accelerator-powered-techstars-nyc/`
}

PressReleaseData.row5 = {
    image:Cision,
    subheading:'PR Web',
    date:'June 1, 2017',
    title:`Machine Learning Corporate Bond Start-up is Selected to Join Boston Data Accelerator`,
    description:` FinTech Sandbox, one of the top fintech accelerators that provides financial technology startups with free access to financial data and infrastructure, welcomed 7 Chord as a resident of its six-month program.`,
    link:`https://www.prweb.com/releases/2017/06/prweb14394951.htm`
}

PressReleaseData.row6 = {
    image:Quantifi,
    subheading:'Quantifi, Inc.',
    date:'February 14, 2017',
    title:`7Chord Seamlessly Integrates Quantifi’s Advanced Model Library`,
    description:`Modern technology is transforming the way secondary credit markets trade. However, the electronic credit market is still in its naissance. It is not surprising that as an asset class it is under-served.`,
    link:`https://www.quantifisolutions.com/7chord-seamlessly-integrates-quantifis-advanced-model-library`
}

PressReleaseData.row7 = {
    image:Harrington,
    subheading:'Harrington Starr',
    date:'February 06, 2019',
    title:'The Definitive List Of The Most Influential Fintechs 2019',
    description:'7Chord listed as one of the most influential fintechs of 2019',
    link:'https://www.harringtonstarr.com/blog/2019/02/the-financial-technologist-q1-2019'
}

PressArticleData.row1 = {
    image:MarketsMedia,
    subheading:'Markets Media',
    date:'December 21, 2018',
    title:`7 Chord Brings AI to Bond Trading`,
    description:`The emerging fintech firm’s BondDroid™ Auto-Pricer goes beyond traditional pre-trade and post-trade data collection and utilization efforts, by using artificial intelligence.`,
    link:`https://www.marketsmedia.com/7-chord-brings-ai-to-bond-trading/`
}

PressArticleData.row2 = {
    image:Barclays,
    subheading:'Barclays Home',
    date:'October 3, 2018',
    title:`Integrating AI into the Fabric of Wall Street`,
    description:`Dealers and asset managers have all this valuable data but they don’t have the tools to turn it into actionable insights, so it goes to waste.`,
    link:`https://home.barclays/news/2018/10/integrating-ai-into-the-fabric-of-wall-street/`
}

PressArticleData.row3 = {
    image:Barclays,
    subheading:'Barclays Home',
    date:'August 21, 2018',
    title:`Women in Derivatives explore machine learning and AI at Barclays`,
    description:`As the finance industry is poised to leverage cutting-edge artificial intelligence (AI), perhaps no topic is more exciting than machine learning, a subset of AI that gives machines access to data so that they can learn for themselves.`,
    link:`https://www.investmentbank.barclays.com/news/women-in-derivatives-ai-event.html`
}

PressArticleData.row4 = {
    image:Barclays,
    subheading:'Barclays Home',
    date:'March 5, 2018',
    title:`Accelerating FinTech Innovation and Growth`,
    description:`With 5,000 applications to join the 11 Accelerator cohorts held to date, the Barclays Accelerator, powered by Techstars, is a programme designed to help FinTech startups deliver breakthroughs.`,
    link:`https://www.investmentbank.barclays.com/news/barclays-fintech-accelerator-2018.html`
}

PressArticleData.row5 = {
    image:Diligently,
    subheading:'Diligently',
    date:'July 31, 2017',
    title:`When AI Becomes Investment Option`,
    description:`Artificial Intelligence as a locus of investment is very tempting for asset owners. `,
    link:`https://www.linkedin.com/pulse/when-ai-becomes-investment-option-monel-amin/`
}


export { PressReleaseData, PressArticleData }