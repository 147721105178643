import React from 'react'
import styled from 'styled-components'
import { Flex } from 'rebass'
import { ContactButton } from '../Shared/Forms/ContactForm'
import Styles from '../Shared/Styles'
import './Styles/Plans.css'
import MediaQuery from 'react-responsive'


const Header = styled(Flex)({
    backgroundColor:'#27CCE1',
    color:'#fff',
    fontFamily:Styles.avenirMediumCondensed,
    width:'100%',
    height:'65px'
})

Header.defaultProps = {
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center'
}

const containerStyle = {
    height:'650px',
    width:'295px',
    margin:'0px 10px',
    boxShadow:'0 15px 35px 0 rgba(50,50,93,0.10)',
    borderRadius:'4px',
}

const containerStyleSmall = {
    ...containerStyle,
    height:'575px'
}

const subContainers = {
    textAlign:'center'
}

const priceDetailContainer = {
    ...subContainers,
    width:'90%',
    margin:'0 auto',
    height:'40px',
    fontSize:'14px'
}

const deploymentContainer = {
    ...subContainers,
    height:'36px',
    color:'#27CCE1',
    fontWeight:'bold',
    width:'70%',
    textAlign:'center',
    margin:'0 auto',
    fontSize:'14px',
    fontFamily:`${Styles.avenirBold}`
}

const detailContainer = {
    width:'70%',
    textAlign:'center',
    margin:'10px auto 0 auto',
    height:'255px'
}

const detailContainerSmall = {
    ...detailContainer,
    height:'200px'
}

const detailList = {
    listStyleType:'none',
    padding:'0'
}

const timeFrame = {
    color:'#fff',
    display:'block',
    textAlign:'center'
}

const contactButtons = {
    padding:'0',
    width:'178px',
    height:'30px',
    boxShadow:'0 5px 5px 0 rgba(38,26,33,0.17)',
    textAlign:'center',
    borderRadius:'15px',
    border:'1px solid #27CCE1',
    cursor:'pointer',
    marginBottom:'10px'
}


const ActionButton = (props) => {
    return(
        <a href="https://www.quandl.com/databases/BD/data" target="_blank" rel="noopener noreferrer">
            <MediaQuery maxWidth={600}>
                <button className="quandl-button-small">
                    {props.action}
                </button>     
            </MediaQuery>   
            <MediaQuery minWidth={601}>
                <button className="quandl-button">
                    {props.action}
                </button>     
            </MediaQuery>   
        </a>

    )
}

const PlanType = (props) => {
    const detailsLarge = props.details.map(detail => <li style={{fontSize:'15px'}}>{detail}</li>)
    const detailsSmall = props.details.map(detail => <li style={{fontSize:'14px'}}>{detail}</li>)
    return(
        <div>
            <MediaQuery maxHeight={725}>
                    <p style={props.top ? timeFrame : {...timeFrame, color:'#261A21'}}>{props.timeFrame}</p>
                    <div style={containerStyleSmall}>

                    <Header>
                        <h2>
                            {props.planType}
                        </h2>
                    </Header>
                    <Flex
                        bg="#fff"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column">
                            <div style={{height:'80px'}}>
                                <h1>{props.price}</h1>
                            </div>

                            <div style={priceDetailContainer}>
                                {props.priceDetails}
                            </div>
                            <br/>
                            <div style={deploymentContainer}>
                                {props.deployment}
                            </div>
                            
                            <div style={detailContainerSmall}>
                                <ul style={detailList}>
                                    {detailsSmall}
                                </ul>
                            </div>

                            <div>
                                {props.quandl ? 
                                    <ActionButton
                                        action={props.action}
                                        background={props.background}
                                        backgroundImage={props.backgroundImage}
                                        background={props.background}/> :
                                    <ContactButton
                                        handleClick={!props.demo ? props.handleClick : null}
                                        buttonText={props.buttonText}
                                        custom={contactButtons}/> 
                                }
                            </div>
                            {props.quandl ?
                                <span style={{display:'block', fontSize:'10px', marginTop:'20px'}}>
                                    * Available through 7 Chord, Inc. as a bundle with Pro
                                </span> : null
                            }

                    </Flex>
                </div> 
            </MediaQuery>

            <MediaQuery minHeight={726}>
            <p style={props.top ? timeFrame : {...timeFrame, color:'#261A21'}}>{props.timeFrame}</p>

                <div style={containerStyle}>
                    <Header>
                        <h2>
                            {props.planType}
                        </h2>
                    </Header>
                    <Flex
                        bg="#fff"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column">
                            <div style={{height:'80px'}}>
                                <h1>{props.price}</h1>
                            </div>

                            <div style={priceDetailContainer}>
                                {props.priceDetails}
                            </div>
                            <br/>
                            <div style={deploymentContainer}>
                                {props.deployment}
                            </div>

                            <div style={detailContainer}>
                                <ul style={detailList}>
                                    {detailsLarge}
                                </ul>
                            </div>

                            <div>
                                {props.quandl ? 
                                    <ActionButton
                                        action={props.action}
                                        background={props.background}
                                        backgroundImage={props.backgroundImage}
                                        background={props.background}/> :
                                    <ContactButton
                                        handleClick={!props.demo ? props.handleClick : null}
                                        buttonText={props.buttonText}
                                        custom={contactButtons}/> 
                                }
                            </div>
                            {props.quandl ?
                                <span style={{display:'block', fontSize:'10px', marginTop:'20px'}}>
                                    * Available through 7 Chord, Inc. as a bundle with Pro
                                </span> : null
                            }

                    </Flex>
                </div>  
            </MediaQuery>
        </div>
    )
}

export default PlanType;