import React from 'react'
import Subscribe from './Subscribe'
import { Link } from 'react-router-dom'
import { FaTwitter, FaLinkedin, FaGithub } from 'react-icons/fa'

import '../Styles/Footer.css'


const Footer = (props) => {
  const footerSectionLeft = {
    width:'55vw',
    paddingLeft:'10vw',
    marginTop:'10px'
  }

  const footerSectionRight = {
    ...footerSectionLeft,
    width:'25vw',
    paddingLeft:'0',
    paddingRight:'10vw',
    marginTop:'10px',
    fontWeight:'600'
  }

  const linkStyle = {
    color:'#FFFFFF',
    textDecoration:'none',
    paddingRight:'20px',
    fontSize:'15px',
    fontWeight:'600'
  }

  const iconContainer = {
    marginLeft:'25px',
    textDecoration:'none'
  }

  const iconStyle = {
    color:'white',
    fontSize:'24px',
    paddingRight:'5px'
  }

  const addressText = {
    color:'#FFFFFF', 
    opacity:'.55',
    fontSize:'15px',
    lineHeight:'20px'
  }
  
  return(
    <div>
      <footer style={props.formOpen ? {position:'absolute', top:props.offset} : {}} className="footer">
        <Subscribe/>
        <div className="footer-links-container">
          <div style={footerSectionLeft}>
            <Link style={linkStyle} to="/">Home</Link>
            <Link style={linkStyle} to="/plans">Plans</Link>
            <Link style={linkStyle} to="/press">Press</Link>
            <a style={linkStyle} target="_blank" href="https://www.medium.com/7-chord">Blog</a>
            <Link style={linkStyle} to="/events">Events</Link>
            <Link style={linkStyle} to="/about">About</Link>
            <Link style={linkStyle} to="/terms">Terms</Link>
            <Link style={linkStyle} to="/privacy">Privacy</Link>
            <div style={{color:'#979797', fontSize:'12px', marginTop:'25px'}}>
              <span style={{lineHeight:'16px', ...addressText}}>
                85 Broad Street, 17th Floor<br/>
                New York, NY 10004<br/><br/>
                info@7-chord.com
              </span>
            </div>
          </div>

          <div style={footerSectionRight}>
            <div className="footer-contact-flex-container">
              <div style={iconContainer}>
                <a href="https://www.linkedin.com/company/7-chord/">
                  <FaLinkedin style={iconStyle}/>
                </a>
              </div>

            <div style={iconContainer}>
              <a href="https://twitter.com/7chordbd">
                <FaTwitter style={iconStyle}/>
              </a>
            </div>

            <div style={iconContainer}>
              <a href="https://github.com/7chord">
                <FaGithub style={iconStyle}/>
              </a>
            </div>

            <div style={iconContainer}>
              <a 
                style={{textDecoration:'none'}} 
                href="https://www.crunchbase.com/organization/7-chord" 
                target="_blank" rel="noopener noreferrer" >
                  <i style={{...iconStyle, paddingBottom:'20px'}} className="socicon-crunchbase"></i>
              </a>
            </div>
          </div>

          <div className="footer-reserved-container">
            <span style={{fontWeight:'400', paddingTop:'65px', ...addressText}}>
              @7 Chord, Inc. All Rights Reserved
            </span>
          </div>

          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer;
