import React from 'react'
import Styles from '../Shared/Styles'
import Cloud from '../../images/Home/newCloud.svg'
import Secure from '../../images/Home/newSecure.svg'
import Pie from '../../images/Home/newPie.svg'
import Row  from '../Shared/Row'
import Column from '../Shared/Column'
import { Link } from 'react-router-dom'
import MediaQuery from 'react-responsive'

import '../../views/Styles/Home/Home.css'


export default class Section6 extends React.Component {

    render(){
      const iconStyle = {
        height:'120px',
        width:'120px',
        display:'block',
        margin:'0 auto'
      }

      const headingStyle = {
        fontSize:'26px',
        width:'100%',
        textAlign:'center',
        height:'40px',
        color:'#261A21',
        fontFamily:`${Styles.avenirBold}`
      }

      const subHeadingStyle = {
        fontWeight:'500',
        fontSize:'14px',
        height:'4.2em',
        width:'70%',
        textAlign:'center',
        margin: '-10px auto 0 auto',
        color:'#261A21',
        lineHeight:'18px'
      }

      const infoStyle = {
        textAlign:'left',
        fontSize:'16px',
        lineHeight:'24px',
        height:'380px',
        color:'#261A21',
      }

      const col1 = {
        image:Secure,
        iconStyle:iconStyle,
        heading:["BondDroid",<sup><small>TM</small></sup>, <span style={{color:"#27CCE1"}}> Enterprise</span>],
        headingStyle:headingStyle,
        subtitle:"DEPLOY IN YOUR ENVIRONMENT AND SUPERCHARGE IT WITH YOUR OWN PROPRIETARY DATA",
        subHeadingStyle:subHeadingStyle,
        info:[`We are the market’s only predictive bond pricing and trend forecasting solution that comes 
               with an option to install our AI-system in clients’ corporate environments, behind internal 
               firewalls.`, <br/>,<br/>, `BondDroid`, <sup><small>TM</small></sup>, ` Enterprise clients can use our standard APIs to extract 
               tradeable insights from their own proprietary data, in addition to publicly or commercially 
               available data.`
        ]
      }

      const col2 = {
        image:Cloud,
        iconStyle:iconStyle,
        heading:["BondDroid",<sup><small>TM</small></sup>, <span style={{color:"#27CCE1"}}> Pro</span>],
        headingStyle:headingStyle,
        subtitle:"SUBSCRIPTION TO THE REAL-TIME PREDICTION STREAM",
        subHeadingStyle:subHeadingStyle,
        info:[`Not ready to manage AI in-house? We will host and maintain it for you. BondDroid`, <sup><small>TM</small></sup>, `Pro subscription 
        customers can leverage our computing power and range of market data sources to generate predictions. `, <br/>,<br/>,
        `Have a unique data source? We can also separately manage a version of BondDroid™ Pro for you.`
        ]
      }

      const col3 = {
        image:Pie,
        iconStyle:iconStyle,
        heading:["BondDroid", <sup><small>TM</small></sup>, <span style={{color:"#27CCE1"}}> Data</span>],
        headingStyle:headingStyle,
        subtitle:["INSTITUTIONAL QUALITY AI-GENERATED PRICES NOW", <br/>, "AVAILABLE IN BULK"],
        subHeadingStyle:subHeadingStyle,
        info:[`Are you back-testing a new trading strategy? Or maybe you are a risk manager, a quant
        or a regulator conducting statistical analysis of the bond market volatility and trends? `, <br/>, <br/>,
        `We now make our AI-generated prices available at an affordable rate in an easy-to-consume historical data file.`
        ],
      }

      return(
          <div>
            <Row>
              <MediaQuery maxWidth={550}>
                <div style={{marginTop:'40px'}}>
                  <Column
                          width="335px"
                          {...col1}
                          {...col1['headingStyle'] = {...col1['headingStyle'], fontSize:'21px'}}
                          {...col1['subHeadingStyle'] = {...col1['subHeadingStyle'], width:'80%'}}
                          textStyle={{...infoStyle, height:'250px'}}
                    />

                    <Column
                      width="335px"
                      {...col2}
                      {...col2['headingStyle'] = {...col2['headingStyle'], fontSize:'21px'}}
                      {...col2['subHeadingStyle'] = {...col2['subHeadingStyle'], width:'80%'}}
                      textStyle={{...infoStyle, height:'240px'}}
                    />

                    <Column
                      width="335px"
                      {...col3['headingStyle'] = {...col3['headingStyle'], fontSize:'21px'}}
                      {...col3['subHeadingStyle'] = {...col3['subHeadingStyle'], width:'80%'}}
                      textStyle={{...infoStyle, height:'240px'}}
                      action={
                        <CustomButton
                          component="/plans"
                          text={"HELP ME CHOOSE A PLAN"}
                        />
                      }
                      {...col3}
                    />
                </div>
              </MediaQuery>

              <MediaQuery minWidth={551} maxWidth={1329}>
                <Column
                      width="335px"
                      {...col1}
                      textStyle={{...infoStyle, height:'250px'}}
                />

                <Column
                  width="335px"
                  {...col2}
                  textStyle={{...infoStyle, height:'240px'}}
                />

                <Column
                  width="335px"
                  textStyle={{...infoStyle, height:'240px'}}
                  action={
                    <CustomButton
                      component="/plans"
                      text={"HELP ME CHOOSE A PLAN"}
                    />
                  }
                  {...col3}
                />
              </MediaQuery>

              <MediaQuery minWidth={1330} maxWidth={1600}>
                  <Column
                    width="335px"
                    {...col1}
                    textStyle={infoStyle}
                  />

                  <Column
                    width="335px"
                    {...col2}
                    textStyle={{...infoStyle, height:'270px'}}
                    action={
                      <CustomButton
                        component="/plans"
                        text={"HELP ME CHOOSE A PLAN"}
                      />
                    }
                  />

                  <Column
                    width="335px"
                    {...col3}
                    textStyle={infoStyle}
                  />
                </MediaQuery>

                <MediaQuery minWidth={1601}>
                  <Column
                      width="335px"
                      {...col1}
                      textStyle={infoStyle}
                  />

                  <Column
                    width="335px"
                    {...col2}
                    textStyle={{...infoStyle, height:'230px'}}
                    action={
                      <CustomButton
                        component="/plans"
                        text={"HELP ME CHOOSE A PLAN"}
                      />
                    }
                  />

                  <Column
                    width="335px"
                    {...col3}
                    textStyle={infoStyle}
                  />
                </MediaQuery>
            </Row>
            
          </div>
      )
    }
  }

  const CustomButton = (props) => {

    return(
      <div style={{fontFamily:`${Styles.avenirDemi}`}}> 
        {
          props.buttonHandler ?
          <button
            onClick={props.buttonHandler}
            className="custom-button"
          >
            {props.text}
          </button>
          : props.component ? <Link style={{textDecoration:'none'}} to={props.component}><button className="custom-button">{props.text}</button></Link>:
          <a style={{textDecoration:'none'}}
             href="mailto:sales@7-chord.com"
          >
            <button className="custom-button">
              {props.text}
            </button>
          </a>
        }
  
      </div>
    )
  }

  export {CustomButton};
