import React from 'react'
import Legal from '../components/Legal/Legal'
import Navbar from '../components/Shared/Navbar/index'
import Footer from '../components/Shared/Footer/index'


export default class Terms extends React.Component {

  componentDidMount(){
    window.scrollTo(0,0)
  }

  render(){
      return(
        <div>
          <Navbar/>

          <Legal heading="Terms of Use" date="December 1, 2018">
            Welcome to 7 Chord!<br/><br/>
            Thanks for using our products and services (“Services”). The Services are provided by 7 Chord, Inc. (“7 Chord”), located at 43 W 23rd Street, 2nd Floor, New York, NY 10010, United States. By using our Services, you agree to these terms. Please read them carefully.
            <br/><br/>
            <b>Using our Services</b><br/><br/>
            You must comply with these terms and any terms and conditions made available to you within the Services (“Additional Terms”).
            <br/><br/>
            <b>Don't misuse our Services</b><br/><br/>
            For example, don’t interfere with our Services or try to access them using a method other than the interface and the instructions that we provide. You may use our Services only as permitted by law, including applicable export and re-export control laws and regulations. We may suspend or stop providing our Services to you if you do not comply with our terms or policies or if we are investigating suspected misconduct.
            <br/><br/>
            <b>Your 7 Chord Account</b><br/><br/>
            A 7 Chord Account is a secure login used to authenticate your identity and prevent unauthorized access by others. You need a 7 Chord Account in order to:
            <br/><br/>
            1. Login to applications that use 7 Chord accounts for authentication
            <br/><br/>
            2. Use our online Services for application management
            <br/><br/>
            During the registration process for a 7 Chord Account, you will establish a user name (“User Name”) and a password (“Password”). You are solely responsible for the confidentiality and use of your User Name and Password, as well as for any use, misuse or communications through the Services using them. If you learn of any unauthorized use of your password or account, contact us at support@7-chord.com. We reserve the right to delete or change your Password, Sign-In Name or Unique Identifier at any time and for any reason.
            <br/><br/>
            <b>Privacy</b><br/><br/>
            7 Chord’s privacy policy explains how we treat the personal data that you provide to us when you use our Services. By using our Services, you agree that 7 Chord can use such data in accordance with our privacy policy.
            <br/><br/>
            <b>Intellectual Property</b><br/><br/>
            The Services contain various information in the form of data, text, graphics, and other materials that belongs to us and/or our third party licensors (the “Content”). You acknowledge that the Services and various elements contained therein are protected by copyrights, trademarks, trade secrets, patents, or other proprietary rights, and that these worldwide rights are valid and protected in all forms, media, and technologies existing now and hereinafter developed. You also acknowledge that the Content is and shall remain the property of us or our licensors. You agree to comply with all intellectual property laws and you shall not encumber any interest in, or assert any rights to, the Content. You may not modify, transmit, participate in the sale or transfer of, or create derivative works based on any Content, in whole or in part. However, you may print a single copy of the Content for your own personal use, provided that you maintain any notices contained in the Content, such as all copyright notices, trademark legends, or other proprietary rights notices.
            <br/><br/>
            The trademarks, service marks, trade names and logos used and displayed on our site (the “Marks”) are registered and unregistered trademarks of ours. All page headers, custom graphics, button icons, and scripts are Marks of ours and may not be copied, imitated or used, in whole or in part, in connection with any product or service that is not authorized by us, in any manner that is likely to cause confusion among consumers, or in any manner that disparages or discredits us. The Marks are and shall remain our sole property. Nothing in these terms shall be construed as granting, by implication, estoppel or otherwise, any license, ownership right, or right to use any Marks. The misuse of the Marks or any other Content is strictly prohibited.
            <br/><br/>
            <b>Modifying and Terminating our Services</b><br/><br/>
            We are constantly changing and improving our Services. We may add or remove functionalities or features, and we may suspend or stop a Service altogether. You can stop using our Services at any time. 7 Chord may also stop providing Services to you, or add or create new limits to our Services at any time.
            <br/><br/>
            <b>Other Warranties and Disclaimers</b><br/><br/>
            NEITHER 7 CHORD NOR ITS SUPPLIERS OR DISTRIBUTORS MAKE, AND THEY EACH HEREBY DISCLAIM, ANY SPECIFIC PROMISES AND WARRANTIES OF ANY KIND WITH RESPECT TO THE SERVICES AND THE CONTENT, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT AND ANY WARRANTIES ARISING FROM A COURSE OF PERFORMANCE, A COURSE OF DEALING OR TRADE USAGE. WE PROVIDE THE SERVICES AND CONTENT “AS IS”.
            <br/><br/>
            Our Services may contain links to third party web sites. Any such links are provided for your convenience only. We do not control those web sites, and we are not responsible for their contents or practices, including their privacy practices. We do not endorse the operators of those sites, nor do we endorse or make any representations with respect to the contents of those sites or any products offered on those sites.
            <br/><br/>
            <b>Indemnification</b><br/><br/>
            You agree to indemnify, defend, and hold us and our officers, directors, members, managers, employees, agents, affiliates, suppliers, successors and assigns harmless from and against any and all claims, liabilities, damages, losses and expenses (including without limitation reasonable fees and costs for attorneys and investigations) arising out of, based on, or in connection with your access to or other use of the Services.
            <br/><br/>
            <b>Business uses of our Services</b><br/><br/>
            If you are using our Services on behalf of a company or other organization, that company or other organization will be bound by these terms, and you hereby warrant and represent that you are authorized to accept and agree to these terms on behalf of such company or other organization.
            <br/><br/>
            <b>About these Terms</b><br/><br/>
            Any dispute or controversy arising under or in connection with this Agreement shall be settled exclusively by arbitration, conducted before a panel of three arbitrators in New York, New York, in accordance with the rules of the American Arbitration Association then in effect.           
            <br/><br/>
            BondDroid<sup>TM</sup> enterprise software is confidential and proprietary information of 7 Chord, Inc.  It may not be copied or reproduced in any form.  This data dictionary is for reference only, intended only to assist coding against BondDroid<sup>TM</sup> APIs.  All information presented herein is considered to be accurate at the time of writing, but no warranty of accuracy is given and no liability in respect of any error or omission is accepted. For detailed message flows of BondDroid<sup>TM</sup> APIs, please contact 7 Chord, Inc. for the relevant specification documentation. This version of data dictionary is provided for the current software release.  Future iterations of BondDroid<sup>TM</sup> APIs may not be backward compatible.
          </Legal>
          <div style={{height:'50px'}}></div>
            <Footer/>
        </div>
      )
  }
}