import React from 'react'
import Option from './Option'
import Cloud from '../../images/Developers/cloud.svg'
import Data from '../../images/Developers/data.svg'
import Display from '../../images/Developers/display.svg'
import Excel from '../../images/Developers/excel.svg'
import File from '../../images/Developers/file.svg'
import Universe from '../../images/Developers/universe.svg'
import './Styles/Option.css'


const ToggleContainer = (props) => {

    const titleContainer = {
        borderBottom:'1px solid rgb(244, 244, 244)',
        width:'50%',
        margin:'0 auto'
    }

    const innerContainer = {
        ...titleContainer,
        paddingLeft:'10px',
        width:'50%',
        margin:'0 auto'
    }

    return(
        <div style={{borderRight:'1px solid rgb(244, 244, 244'}}>
            <div style={titleContainer}>
                <Option
                    show={props.show}
                    updateShow={props.updateShow}  
                    name="Getting Started"
                />
            </div>

            <div style={innerContainer}>
                <Option
                    updateShow={props.updateShow} 
                    image={Universe}
                    show={props.show}
                    name="Universe" 
                />
            </div>

            <div style={innerContainer}>
                <Option
                    updateShow={props.updateShow}  
                    image={Data}
                    name="Data Organization"
                    show={props.show} 
                />
            </div>

            <div style={innerContainer}>
                <Option 
                    updateShow={props.updateShow} 
                    image={File}
                    name="FTP"
                    show={props.show} 
                />
            </div>

            <div style={innerContainer}>
                <Option
                    updateShow={props.updateShow} 
                    image={Cloud}
                    name="FIX API"
                    show={props.show} 
                />
            </div>

            <div style={innerContainer}>
                <Option
                    updateShow={props.updateShow} 
                    image={Excel} 
                    name="Excel API"
                    show={props.show}
                />
            </div>

            <div style={innerContainer}>
                <Option
                    updateShow={props.updateShow}  
                    image={Display}
                    name="Display"
                    show={props.show} 
                />
            </div>

            <div style={titleContainer}>
                <Option
                    updateShow={props.updateShow}
                    show={props.show}  
                    name="Get in Touch" />
            </div>
        </div>
    )
}

export default ToggleContainer;