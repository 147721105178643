import React from 'react'
import Legal from '../components/Legal/Legal'
import Navbar from '../components/Shared/Navbar/index'
import Footer from '../components/Shared/Footer/index'


export default class Privacy extends React.Component {
  
  componentDidMount(){
    window.scrollTo(0,0)
  }

  render(){
    return(
        <div>
          <Navbar/>

          <Legal heading="Privacy Policy" date="December 1, 2018">
            7 Chord Inc. (“7 Chord,” “we,” “us,” or “our”) provides this Privacy Policy because we know that you care about how your information is collected, used, shared and retained. This Privacy Policy applies to information collected when you visit our website (www.7-chord.com) (the “Website”) or use any evaluation software or otherwise use any 7 Chord, Inc. technology (collectively, our “Products”).

            By visiting our Website or using any of our Products, you are agreeing to the terms of this Privacy Policy and the accompanying Terms of Use. Capitalized terms not defined in this Privacy Policy have the meaning ascribed to them in the Terms of Use.

            <br/><br/><b>What information we collect; the purpose of collection and use</b>

            <br/><br/><b>Contact Information.</b><br/><br/>
            We collect contact information on our Website from visitors and customers who wish to obtain information or support with respect to our Products. Contact information is provided via browser forms and includes: first name, last name, email address, job title, name of the organization, country, state/region and phone numbers (fax, cell and/or landline). This contact information is used by our sales, support and Product teams to contact our visitors and customers for the purpose of providing the requested information or support.

            <br/><br/><b>Purpose of Collection and Use.</b><br/><br/>
            We use Contact Information and other information collected pursuant to this Privacy Policy to provide the Products and related support, respond to general inquiries, solicit feedback regarding our Products and support, provide relevant content on our Website, send invoices and receive payments, and communicate with you regarding our current and future Products.

            <br/><br/><b>Cookies.</b><br/><br/>
            Like most websites, we use automatic data collection technology when you visit or use our Website or Products to collect information that identifies your computer or device. This comprises information about your operating system, your IP addresses, browser type and language, referring pages and URLs, keywords, date and time, and what sections of our Website or Products you visit or use, as well as the universally unique ID (“UUID”), advertising identifier (“IDFA”), MAC address; operating system and version (e.g., iOS, Android or Windows), carrier and country location, hardware and processor information (e.g., storage, chip speed, camera resolution, NFC enabled), network type (WiFi, 3G, 4G, LTE), and similar data (collectively, the “usage information”). We collect this usage information by using cookies. Cookies are small packets of data that a website stores on your computer’s hard drive so that your computer will “remember” information about your visit. You can reject cookies by following the directions provided by your browser vendor or by your Internet provider’s “help” file. If you reject cookies, you may still visit and use our Website and Products, but may not be able to use some areas or functionality of our Website or Products. We use cookies (and the usage information collected) to help us identify you and analyze how you use our Website and Products.

            <br/><br/>We also work with trusted third-party partners which use cookies to collect information so that we can contact users.  The trusted third parties we currently work with include the following (click on the links to view these organizations’ privacy policies and find out more about what data they hold about you, what they do with it, and how to opt-out from certain data collection):

            <br/>Drift: https://www.drift.com/privacy-policy/

            <br/><br/>We may share usage information with third parties for the purpose of tailoring, analyzing, managing, reporting, and optimizing advertising you see on our Website, Products and elsewhere. These third parties may use cookies, pixel tags (also called web beacons or clear gifs), and/or other technologies to collect such usage information for such purposes. Pixel tags enable us, and these third-party advertisers, to recognize a browser’s cookie when a browser visits the site on which the pixel tag is located in order to learn which advertisement brings a user to a given site.

            <br/><br/><b>Aggregate Data.</b><br/><br/>
            In an ongoing effort to better understand our users, the Website, and our Products, we might analyze your information in aggregate form to operate, maintain, manage, and improve the Website and our Products. This aggregate information does not identify you personally. We may share this aggregate data with our affiliates, agents, and business partners. We may also disclose aggregated user statistics in order to describe our Products and the Website to current and prospective business partners and to other third parties for other lawful purposes.

            <br/><br/><b>Onward Transfer to Third Parties.</b><br/><br/>
            Like many businesses, we hire other companies to perform certain business-related services. We may disclose personal information to certain types of third-party companies but only to the extent needed to enable them to provide such services. The types of companies that may receive personal information and their functions are: direct marketing assistance (including disclosure to our distributors for direct marketing of our Products), billing, customer service, data storage, hosting services, disaster recovery services, sales support, logistics support companies, and to our affiliates in order to support marketing, sale and delivery of Products. All such third parties function as our agents, performing services at our instruction and on our behalf pursuant to contracts which require they provide at least the same level of privacy protection as is required by this privacy policy and implemented by us. You may opt out of having your personal information transferred to any or all of our categories of agents by contacting us at info@7-chord.com. Please allow us a reasonable time to process your request.

            <br/><br/><b>Opt-In to Certain Onward Transfers.</b><br/><br/>
            We will not transfer your personal information to a third party acting as a controller without first providing you with information regarding the identity of such controller or the nature of such controller’s business, and obtaining your opt-in consent. You may grant such consent by contacting us at info@7-chord.com.
            <br/><br/>
            We will not disclose your sensitive personal information to any third party without first obtaining your opt-in consent. You may grant such consent by contacting us at info@7-chord.com.
            <br/><br/>
            In each instance, please allow us a reasonable time to process your response.

            <br/><br/><b>Business Transfers.</b><br/><br/>
            In the event of a merger, dissolution or similar corporate event, or the sale of all or substantially all of our assets, we expect that the information that we have collected, including personal information, would be transferred to the surviving entity in a merger or the acquiring entity. All such transfers shall be subject to our commitments with respect to the privacy and confidentiality of such personal information as set forth in this privacy policy.

            <br/><br/><b>Disclosure to Public Authorities.</b><br/><br/>
            We may be required to disclose personal information in response to lawful requests by public authorities, including for the purpose of meeting national security or law enforcement requirements. We may also disclose personal information to other third parties when compelled to do so by government authorities or required by law or regulation including, but not limited to, in response to court orders and subpoenas.
            <br/><br/>
            Opt-Out for Direct Marketing; Email Management.
            You may opt out at any time from the use of your personal information for direct marketing purposes by following the instructions at this link: https://7-chord.com/unsubscribe. Please allow us a reasonable time to process your request.
            <br/><br/>
            You may manage your receipt of marketing and non-transactional communications by clicking on the “Manage Email Preferences” link located on the bottom of any 7 Chord’s marketing email and following the instructions found on the page to which the link takes you. You cannot opt out of receiving transactional emails related to any Products that you are currently using.

            <br/><br/><b>Access to your personal information</b><br/><br/>
            Upon request by sending an email to info@7-chord.com, we will provide you with confirmation as to whether we are processing your personal information, and have the data communicated to you within a reasonable time after your request. You have the right to correct, amend or delete your personal information where it is inaccurate or has been processed in violation of this Privacy Policy. We may require payment of a non-excessive fee to defray our expenses in this regard. Please allow us a reasonable time to respond to your inquiries and requests.

            <br/><br/><b>Retention of personal information</b><br/><br/>
            We will retain your personal information in a form that identifies you only for as long as it serves the purpose(s) for which it was initially collected as stated in this Privacy Policy, or subsequently authorized. We may continue processing your personal information for longer periods, but only for the time and to the extent such processing reasonably serves the purposes of archiving in the public interest, journalism, literature and art, scientific or historical research and statistical analysis, and subject to the protection of this Privacy Policy. After such time periods have expired, we may either delete your personal information or retain it in a form such that it does not identify you personally.

            <br/><br/><b>How we protect your Information</b><br/><br/>
            We take very seriously the security and privacy of the personal information that we collect pursuant to this Privacy Policy. Accordingly, we will implement reasonable and appropriate security measures to protect your personal information from loss, misuse and unauthorized access, disclosure, alteration and destruction, taking into account the risks involved in processing and the nature of such data, and comply with applicable laws and regulations.

            <br/><br/><b>Children</b><br/><br/>
            We do not knowingly collect any personal information from children under the age of 13 through the Website or use of our Products. If you are under the age of 13, please do not give us any personal information. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce our privacy policy by instructing their children never to provide personal information to us without their permission. If you have reason to believe that a child under the age of 13 has provided personal information to us, please contact us at info@7-chord.com, and we will endeavor to delete that information from our databases.

            <br/><br/><b>Links to External Websites</b><br/><br/>
            The Website may contain links to third-party websites (“External Sites”). We have no control over the privacy practices of these External Sites. As such, we are not responsible for the privacy policies of those External Sites. You should check the applicable third-party privacy policy and terms of use when visiting any External Sites, and before providing any personal information to such External Sites.

            <br/><br/><b>Important Notice to All Non-US Residents</b><br/><br/>
            Our servers are located in the US. If you are located outside of the US, please be aware that any information provided to us, including personal information, will be transferred from your country of origin to the US. Your decision to provide such data to us, or allow us to collect such data through our website or your use of our Products, constitutes your consent to this data transfer.

            <br/><br/><b>California Privacy Rights</b><br/><br/>
            Pursuant to Section 1798.83 of the California Civil Code, residents of California have the right to obtain certain information about the types of personal information that companies with whom they have an established business relationship (and that are not otherwise exempt) have shared with third parties for direct marketing purposes during the preceding calendar year, including the names and addresses of those third parties, and examples of the types of services or products marketed by those third parties. If you wish to submit a request pursuant to Section 1798.83 of the California Civil Code, please contact us via email at info@7-chord.com.
            <br/><br/>
            7 Chord does not monitor, recognize or honor any opt-out or do not track mechanisms including general web browser, “Do Not Track” settings and/or signals.

            <br/><br/><b>Changes to this Privacy Policy</b><br/><br/>
            This Privacy Policy is effective as of the date stated at the top of this Privacy Policy. We may change this Privacy Policy from time to time, and will post any changes on the Website as soon as they go into effect. By accessing the Website or using any of our Products, after we make any such changes to this Privacy Policy, you are deemed to have accepted such changes. Please refer back to this Privacy Policy on a regular basis.

            <br/><br/><b>How to Contact Us</b><br/><br/>
            If you have questions about this Privacy Policy, please email us at info@7-chord.com, or write to us at 7 Chord Inc. 43 W23rd Street, 2nd Floor, New York, New York 10010.


          </Legal>
          <div style={{height:'50px'}}></div>
            <Footer/>
        </div>
      )
    }
}
