import React from 'react'

const FixContent = (props) => {
    const buttonStyle = {
        border:'0',
        outline:'0',
        cursor:'pointer',
        textDecoration:'underline'
    }
    return(
        <div>
            Fix Docs 
            <button 
                style={buttonStyle}
                onClick={props.downloadFixDocs}
            >This</button>
        </div>
    )
}

export default FixContent;