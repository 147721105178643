import React from 'react'

import Hero from '../Shared/Hero'
import Carousel from '../Shared/Carousel'
import DeskDude from '../../images/Home/deskdude.svg'
import Card from './Card'
import Styles from '../Shared/Styles'
import '../../views/Styles/Home/Home.css'
import MediaQuery from 'react-responsive'


const heroTextContainer = {
  position:'absolute',
  top:'40%',
  transform:'translateY(-50%)',
  width:'100%',
}


const Section4 = (props) => {
  const card1 = {
    theme:'#382630',
    quote:`Bond trading is not as frictionless and transparent as
    equities and 7 Chord has a unique platform with a need in the
    marketplace.`,
    author:`ASSET MANAGER, BOSTON`,
  }

  const card2 = {
    theme:'#27CCE1',
    quote:`Impressive team and traction - definitely agree with making AI
    tools more affordable to everyone in the industry.`,
    author:'BUY-SIDE INSTITUTION'
  }

  const card3 = {
    theme:'rgba(78, 46, 64, 0.29)',
    quote:`The BondDroid tools could be used to uncover hidden opportunities in asset management and within retail principal trading.`,
    author:'FINTECH INVESTOR'
  }

  const quoteCards = [
    <Card
      width="85vw"
      height="242px"
      {...card1}
      custom={{fontSize:'19px', lineHeight:'24px'}}
      left="20px"
      cardPosition={{left:'0', right:'0', margin:'0 auto'}}
    />,
    <Card
      width="85vw"
      height="242px"
      {...card2}
      custom={{fontSize:'19px', lineHeight:'24px'}}
      left="20px"
      cardPosition={{left:'0', right:'0', margin:'0 auto'}}
    />,
    <Card
      width="85vw"
      height="242px"
      {...card3}
      custom={{fontSize:'19px', lineHeight:'24px'}}
      left="20px"
      cardPosition={{left:'0', right:'0', margin:'0 auto'}}
      />
  ]

  return(
    <div style={{marginBottom:'50px'}}>
      <MediaQuery maxWidth={842}>
        <div style={{marginTop:'1050px'}}>
          <Carousel
            items={quoteCards}
          />
        </div>
      </MediaQuery>

      <MediaQuery minWidth={843} maxWidth={900}>
        <div style={{marginTop:'575px'}}>
          <Carousel
            items={quoteCards}
          />
        </div>
      </MediaQuery>

      <MediaQuery minWidth={901} maxWidth={1263}>
        <div className="flex-card-container" style={{marginTop:'550px'}}>
          <Card
            width="calc(100vw/3)"
            height="212px"
            {...card1}
            custom={{fontSize:'16px', lineHeight:'20px'}}
            left="20px"
          />

          <Card
            width="calc(100vw/3)"
            height="212px"
            {...card2}
            custom={{fontSize:'16px', lineHeight:'20px'}}
            left="20px"
          />

          <Card
            width="calc(100vw/3)"
            height="212px"
            {...card3}
            custom={{fontSize:'16px', lineHeight:'20px'}}
            left="20px"
          />
        </div>
      </MediaQuery>


      <MediaQuery minWidth={1264}>
      <div style={{marginTop:'110px'}}>
      <div className="flex-card-container" style={{marginBottom:'50px'}}>
      <MediaQuery maxWidth={1600}>
        <Card
          width="calc(100vw/3)"
          height="212px"
          {...card1}
          custom={{fontSize:'20px', lineHeight:'24px'}}
          left="20px"
        />

          <Card
            width="calc(100vw/3)"
            height="212px"
            {...card2}
            custom={{fontSize:'20px', lineHeight:'24px'}}
            left="20px"
            />

          <Card
            width="calc(100vw/3)"
            height="212px"
            {...card3}
            custom={{fontSize:'20px', lineHeight:'24px'}}
            left="20px"
            />
      </MediaQuery>

      <MediaQuery minWidth={1601} maxWidth={1800}>
        <Card
            width="calc(100vw/3)"
            height="232px"
            {...card1}
            custom={{fontSize:'20px', lineHeight:'30px'}}
            padding={"0px 30px"}
            left="50px"
          />

          <Card
            width="calc(100vw/3)"
            height="232px"
            {...card2}
            custom={{fontSize:'20px', lineHeight:'30px'}}
            padding={"0px 30px"}
            left="50px"
          />

          <Card
            width="calc(100vw/3)"
            height="232px"
            {...card3}
            custom={{fontSize:'20px', lineHeight:'30px'}}
            padding={"0px 30px"}
            left="50px"
          />
      </MediaQuery>

      <MediaQuery minWidth={1801}>
        <Card
            width="calc(100vw/3)"
            height="252px"
            {...card1}
            custom={{fontSize:'20px', lineHeight:'30px'}}
            padding={"0px 30px"}
            left="50px"
          />

          <Card
            width="calc(100vw/3)"
            height="252px"
            {...card2}
            custom={{fontSize:'20px', lineHeight:'30px'}}
            padding={"0px 30px"}
            left="50px"
          />

          <Card
            width="calc(100vw/3)"
            height="252px"
            {...card3}
            custom={{fontSize:'20px', lineHeight:'30px'}}
            padding={"0px 30px"}
            left="50px"
          />
      </MediaQuery>

      </div>

      <div className="flex-home-container">

      <Hero
          height="50vh"
          width="40vw"
          imageS={DeskDude}
          imageM={DeskDude}
          imageL={DeskDude}
          imageXL={DeskDude}
          imageCustomStyle={{backgroundSize:'130% 130%'}}
        />


          <Hero
            height="50vh"
            width="40vw"
            customStyle={{position:'relative'}}
          >
              <div style={heroTextContainer}>
                <MediaQuery maxWidth={1600}>
                  <p style={{fontSize:'23px', color:'#261A21', fontFamily:`${Styles.avenirBold}`}}>
                    Overwhelmed by multiple screens, outdated tech and complicated workflows?
                  </p>
                </MediaQuery>

                <MediaQuery minWidth={1601}>
                  <p style={{fontSize:'23px', color:'#261A21', fontFamily:`${Styles.avenirBold}`, width:'80%'}}>
                    Overwhelmed by multiple screens, outdated tech and complicated workflows?
                  </p>
                </MediaQuery>

                <p style={{fontSize:'16px', lineHeight:'24px', paddingTop:'10px', width:'78%', color:'#261A21'}}>
                  Time is money - and every extra click you make puts you further behind your competition. 
                  But shrinking margins and pressure on fees left bond market participants with little budget 
                  to rebuild their trading technology in-house. Let us help. 
                </p>
              </div>
          </Hero>
        </div>
    </div>
      </MediaQuery>
    </div> 
  )
}

export default Section4;
