import React from 'react'
import MediaQuery from 'react-responsive'
import Quote from '../../images/Home/whiteQuote.svg'

const Card = (props) => {

  const cardContainerStyleSmall = {
    width:props.width,
    backgroundColor:props.theme,
    height:props.height,
    position:'relative',
    ...props.cardPosition
  }

  const cardContainerStyle = {
    width:props.width,
    backgroundColor:props.theme,
    height:props.height,
    position:'relative',
    ...props.cardPosition
  }

  const quoteStyleSmall = {
    color:'white',
    ...props.custom,
    fontWeight:'600',
    position:'absolute',
    top:'15px',
    padding:props.padding
  }

  const quoteStyle = {
    color:'white',
    ...props.custom,
    fontWeight:'600',
    position:'absolute',
    top:'50px',
    padding:props.padding
  }

  const authorStyle = {
    color:'white',
    fontSize:'12px',
    position:'absolute',
    bottom:'20px',
    right:'40px'
  }

  return(
    <div>
      <MediaQuery maxWidth={450}>
        <div style={cardContainerStyleSmall}>
          <img
            alt="User Quote"
            style={{position:'relative', top:'10px', left:props.left}}
            height="20px"
            width="20px"
            src={Quote}
          />
          <blockquote style={quoteStyleSmall}>
            {props.quote}
          </blockquote>
          <span style={authorStyle}>
            {props.author}
          </span>
        </div>
      </MediaQuery>

      <MediaQuery minWidth={451} maxWidth={700}>
        <div style={cardContainerStyleSmall}>
          <img
            alt="User Quote"
            style={{position:'relative', top:'10px', left:props.left}}
            height="30px"
            width="30px"
            src={Quote}
          />
          <blockquote style={quoteStyle}>
            {props.quote}
          </blockquote>
          <span style={authorStyle}>
            {props.author}
          </span>
        </div>
      </MediaQuery>

      <MediaQuery minWidth={701}>
        <div style={cardContainerStyle}>
          <img
            alt="User Quote"
            style={{position:'relative', top:'10px', left:props.left}}
            height="30px"
            width="30px"
            src={Quote}
          />
          <blockquote style={quoteStyle}>
            {props.quote}
          </blockquote>
          <span style={authorStyle}>
            {props.author}
          </span>
        </div>
      </MediaQuery>
    </div>
  )
}

export default Card;
