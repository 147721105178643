import React from 'react'
import { PlanRow, PlanRowSmall, SubRow, SubRowSmall } from './PlanRow'
import {PlanHeading, PlanHeadingSmall} from './PlanHeading'
import { 
    Absent,
    Present
} from './FeaturePresence'
import { Flex } from 'rebass'


const PlanCompare = (props) => {
    return(
        <div style={{position:'relative', bottom:'150px'}}>
                    <Flex flexDirection="column">
                        <PlanHeading/>
                        <PlanRow>
                            <SubRow
                                title={true}
                                col1="USERS"
                                col2="Unlimited, Firmwide"
                                col3="Up to 100 users"
                                col4="Per user"
                                show2={true}
                                show3={true}
                                show4={true}/>
                        </PlanRow>

                        <PlanRow>
                            <SubRow
                                title={true}
                                col1="FREQUENCY"
                                col2="Real-time"
                                col3="Real-time"
                                col4="Delayed end-of-day"
                                show2={true}
                                show3={true}
                                show4={true}/>
                        </PlanRow>

                        <PlanRow>
                            <SubRow title={true} col1="DELIVERY METHOD"/>
                            <SubRow
                                col1="FIX API"
                                col2={<Absent/>}
                                col3={<Present/>}
                                col4={<Absent/>}
                                show2={true}
                                show3={true}
                                show4={true}/>
                            <SubRow
                                col1="FTP"
                                col2={<Absent/>}
                                col3={<Absent/>}
                                col4={<Present/>}
                                show2={true}
                                show3={true}
                                show4={true}/>
                            <SubRow
                                col1="EXCEL API"
                                col2={<Absent/>}
                                col3={<Present/>}
                                col4={<Absent/>}
                                show2={true}
                                show3={true}
                                show4={true}/>
                            <SubRow
                                col1="ONLINE APP"
                                col2={<Present/>}
                                col3={<Present/>}
                                col4={<Absent/>}
                                show2={true}
                                show3={true}
                                show4={true}/>
                            <SubRow
                                col1="PYTHON API"
                                col2={<Absent/>}
                                col3={<Absent/>}
                                col4={<span>Quandl Only</span>}
                                show2={true}
                                show3={true}
                                show4={true}/>
                            <SubRow
                                col1="R API"
                                col2={<Absent/>}
                                col3={<Absent/>}
                                col4={<span>Quandl Only</span>}
                                show2={true}
                                show3={true}
                                show4={true}/>              
                        </PlanRow>


                        <PlanRow>
                            <SubRow title={true} col1="INCLUDED"/>
                            <SubRow
                                col1="CURATED DATASETS"
                                col2={<Present/>}
                                col3={<Present/>}
                                col4={<Present/>}
                                show2={true}
                                show3={true}
                                show4={true}/>
                            <SubRow
                                col1="COMPUTING COSTS"
                                col2={<Absent/>}
                                col3={<Present/>}
                                col4={<Present/>}
                                show2={true}
                                show3={true}
                                show4={true}/>
                            <SubRow 
                                col1="PRE-ENGINEERED FEATURES"
                                col2={<Present/>}
                                col3={<Present/>}
                                col4={<Present/>}
                                show2={true}
                                show3={true}
                                show4={true}/>
                            <SubRow
                                col1="PRICING BOTS"
                                col2={<Present/>}
                                col3={<Present/>}
                                col4={<Present/>}
                                show2={true}
                                show3={true}
                                show4={true}/>
                            <SubRow 
                                col1="TICKS WITH TREASURES (REAL-TIME)"
                                col2={<Present/>}
                                col3={<Present/>}
                                col4="Additional Fee"
                                show2={true}
                                show3={true}
                                show4={true}/>
                            <SubRow
                                col1="HISTORY"
                                col2={<Present/>}
                                col3="Up to 20,000 datapoints per bond"
                                col4="Additional Fee"
                                show2={true}
                                show3={true}
                                show4={true}/>
                        </PlanRow>

                        <PlanRow>
                            <SubRow title={true} col1="TRANSPARENCY" />
                            <SubRow
                                col1="REAL-TIME ACCURACY MONITORING"
                                col2={<Present/>}
                                col3={<Present/>}
                                col4={<Absent/>}
                                show2={true}
                                show3={true}
                                show4={true}/>
                            <SubRow
                                col1="DAILY BACKTEST REPORT"
                                col2={<Present/>}
                                col3={<Present/>}
                                col4={<Absent/>}
                                show2={true}
                                show3={true}
                                show4={true}/>
                            <SubRow
                                col1="METHODOLOGY WHITEPAPER"
                                col2={<Present/>}
                                col3={<Present/>}
                                col4={<Absent/>}
                                show2={true}
                                show3={true}
                                show4={true}/>
                        </PlanRow>

                        <PlanRow>
                            <SubRow title={true} col1="CUSTOMIZATION" />
                            <SubRow 
                                col1="DATA API"
                                col2={<Present/>}
                                col3={<Absent/>}
                                col4={<Absent/>}
                                show2={true}
                                show3={true}
                                show4={true}/>
                            <SubRow 
                                col1="FEATURES"
                                col2={<Present/>}
                                col3={<Absent/>}
                                col4={<Absent/>}
                                show2={true}
                                show3={true}
                                show4={true}/>
                            <SubRow 
                                col1="PRICING BOTS"
                                col2={<Present/>}
                                col3={<Absent/>}
                                col4={<Absent/>}
                                show2={true}
                                show3={true}
                                show4={true}/>
                            <SubRow 
                                col1="PARAMETERS"
                                col2={<Present/>}
                                col3={<Absent/>}
                                col4={<Absent/>}
                                show2={true}
                                show3={true}
                                show4={true}/>
                        </PlanRow>

                        <PlanRow>
                            <SubRow
                                title={true}
                                col1="24-HOUR TECHNICAL SUPPORT"
                                col2={<Present/>}
                                col3={<Present/>}
                                col4={<Present/>}
                                show2={true}
                                show3={true}
                                show4={true}/>
                        </PlanRow>

                        <PlanRow>
                            <SubRow
                                title={true}
                                col1="DATA SCIENCE SUPPORT"
                               col2={<Present/>}
                                col3={<Absent/>}
                                col4={<Absent/>}
                                show2={true}
                                show3={true}
                                show4={true}/>
                        </PlanRow>
                </Flex>
        </div>

    )
}


const PlanCompareSmall = (props) => {
    return(
        <div style={{position:'relative', bottom:'100px'}}>
            <Flex flexDirection="column">
                <PlanHeadingSmall 
                    togglePlans={props.togglePlans}
                    show={props.show}/>
                <PlanRowSmall>
                    <SubRowSmall
                        title={true}
                        col1="USERS"
                        col2="Unlimited, Firmwide"
                        col3="Up to 100 users"
                        col4="Per user"
                        show2={props.show === 'col2'}
                        show3={props.show === 'col3'}
                        show4={props.show === 'col4'}/>
                </PlanRowSmall>

                <PlanRowSmall>
                    <SubRowSmall
                        col1="FREQUENCY"
                        col2="Real-time"
                        col3="Real-time"
                        col4="Delayed end-of-day"
                        show2={props.show === 'col2'}
                        show3={props.show === 'col3'}
                        show4={props.show === 'col4'}/>
                </PlanRowSmall>

                <PlanRowSmall>                       
                    <SubRowSmall title={true} col1="DELIVERY METHOD"/>

                    <SubRowSmall
                        col1="FIX API"
                        col2={<Absent/>}
                        col3={<Present/>}
                        col4={<Absent/>}
                        show2={props.show === 'col2'}
                        show3={props.show === 'col3'}
                        show4={props.show === 'col4'}/>

                    <SubRowSmall
                        col1="FTP"
                        col2={<Absent/>}
                        col3={<Absent/>}
                        col4={<Present/>}
                        show2={props.show === 'col2'}
                        show3={props.show === 'col3'}
                        show4={props.show === 'col4'}/>                   

                    <SubRowSmall
                        col1="EXCEL API"
                        col2={<Absent/>}
                        col3={<Present/>}
                        col4={<Absent/>}
                        show2={props.show === 'col2'}
                        show3={props.show === 'col3'}
                        show4={props.show === 'col4'}/>  

                    <SubRowSmall
                        col1="ONLINE APP"
                        col2={<Present/>}
                        col3={<Present/>}
                        col4={<Absent/>}
                        show2={props.show === 'col2'}
                        show3={props.show === 'col3'}
                        show4={props.show === 'col4'}/>     

                    <SubRowSmall
                        col1="PYTHON API"
                        col2={<Absent/>}
                        col3={<Absent/>}
                        col4={<span>Quandl Only</span>}
                        show2={props.show === 'col2'}
                        show3={props.show === 'col3'}
                        show4={props.show === 'col4'}/> 

                    <SubRowSmall
                        col1="R API"
                        col2={<Absent/>}
                        col3={<Absent/>}
                        col4={<span>Quandl Only</span>}
                        show2={props.show === 'col2'}
                        show3={props.show === 'col3'}
                        show4={props.show === 'col4'}/>

                </PlanRowSmall>
                
                <PlanRowSmall>
                    <SubRowSmall title={true} col1="INCLUDED"/>
                    <SubRowSmall
                        col1="CURATED DATASETS"
                        col2={<Present/>}
                        col3={<Present/>}
                        col4={<Present/>}
                        show2={props.show === 'col2'}
                        show3={props.show === 'col3'}
                        show4={props.show === 'col4'}
                    />   

                    <SubRowSmall
                        col1="COMPUTING COSTS"
                        col2={<Absent/>}
                        col3={<Present/>}
                        col4={<Present/>}
                        show2={props.show === 'col2'}
                        show3={props.show === 'col3'}
                        show4={props.show === 'col4'}
                    /> 

                     <SubRowSmall
                        col1="PRE-ENGINEERED FEATURES"
                        col2={<Present/>}
                        col3={<Present/>}
                        col4={<Present/>}
                        show2={props.show === 'col2'}
                        show3={props.show === 'col3'}
                        show4={props.show === 'col4'}
                    /> 

                    <SubRowSmall
                        col1="PRICING BOTS"
                        col2={<Present/>}
                        col3={<Present/>}
                        col4={<Present/>}
                        show2={props.show === 'col2'}
                        show3={props.show === 'col3'}
                        show4={props.show === 'col4'}
                    />   

                    <SubRowSmall
                        col1="TICKS WITH TREASURES (REAL-TIME)"
                        col2={<Present/>}
                        col3={<Present/>}
                        col4="Additional Fee"
                        show2={props.show === 'col2'}
                        show3={props.show === 'col3'}
                        show4={props.show === 'col4'}
                    />   

                    <SubRowSmall
                        col1="HISTORY"
                        col2={<Present/>}
                        col3="Up to 20,000 datapoints per bond"
                        col4="Additional Fee"
                        show2={props.show === 'col2'}
                        show3={props.show === 'col3'}
                        show4={props.show === 'col4'}
                    />   
                </PlanRowSmall>
 
                <PlanRowSmall>
                    <SubRowSmall title={true} col1="TRANSPARENCY" />
                    <SubRowSmall
                        col1="REAL-TIME ACCURACY MONITORING"
                        col2={<Present/>}
                        col3={<Present/>}
                        col4={<Absent/>}
                        show2={props.show === 'col2'}
                        show3={props.show === 'col3'}
                        show4={props.show === 'col4'}/> 

                    <SubRowSmall
                        col1="DAILY BACKTEST REPORT"
                        col2={<Present/>}
                        col3={<Present/>}
                        col4={<Absent/>}
                        show2={props.show === 'col2'}
                        show3={props.show === 'col3'}
                        show4={props.show === 'col4'}/> 

                    <SubRowSmall
                        col1="METHODOLOGY WHITEPAPER"
                        col2={<Present/>}
                        col3={<Present/>}
                        col4={<Absent/>}
                        show2={props.show === 'col2'}
                        show3={props.show === 'col3'}
                        show4={props.show === 'col4'}/>    
                </PlanRowSmall>

                <PlanRowSmall>
                    <SubRowSmall title={true} col1="CUSTOMIZATION" />
                    <SubRowSmall
                        col1="DATA API"
                        col2={<Present/>}
                        col3={<Absent/>}
                        col4={<Absent/>}
                        show2={props.show === 'col2'}
                        show3={props.show === 'col3'}
                        show4={props.show === 'col4'}/> 

                    <SubRowSmall
                        col1="FEATURES"
                        col2={<Present/>}
                        col3={<Absent/>}
                        col4={<Absent/>}
                        show2={props.show === 'col2'}
                        show3={props.show === 'col3'}
                        show4={props.show === 'col4'}/> 

                    <SubRowSmall
                        col1="PRICING BOTS"
                        col2={<Present/>}
                        col3={<Absent/>}
                        col4={<Absent/>}
                        show2={props.show === 'col2'}
                        show3={props.show === 'col3'}
                        show4={props.show === 'col4'}/> 

                    <SubRowSmall
                        col1="PARAMETERS"
                        col2={<Present/>}
                        col3={<Absent/>}
                        col4={<Absent/>}
                        show2={props.show === 'col2'}
                        show3={props.show === 'col3'}
                        show4={props.show === 'col4'}/>   
                </PlanRowSmall>

                <PlanRowSmall>
                    <SubRowSmall
                        title={true}
                        col1="24-HOUR TECHNICAL SUPPORT"
                        col2={<Present/>}
                        col3={<Present/>}
                        col4={<Present/>}
                        show2={props.show === 'col2'}
                        show3={props.show === 'col3'}
                        show4={props.show === 'col4'}/> 
                </PlanRowSmall>

                <PlanRowSmall>
                <SubRowSmall
                    title={true}
                    col1="DATA SCIENCE SUPPORT"
                    col2={<Present/>}
                    col3={<Absent/>}
                    col4={<Absent/>}
                    show2={props.show === 'col2'}
                    show3={props.show === 'col3'}
                    show4={props.show === 'col4'}/>  
                </PlanRowSmall>
      

            </Flex>
        </div>
    )
}

export {
    PlanCompare,
    PlanCompareSmall
}