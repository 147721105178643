import React from 'react'
import Hero from '../Shared/Hero'
import Buildings from '../../images/Shared/buildings.jpg'
import HeroS from '../../images/Shared/buildingsS.jpg'
import HeroM from '../../images/Shared/buildingsM.jpg'
import HeroL from '../../images/Shared/buildingsL.jpg'
import HeroXL from '../../images/Shared/buildingsXL.jpg'
import Styles from '../Shared/Styles'
import MediaQuery from 'react-responsive'

const Legal = (props) => {
  return(
    <div>
      <MediaQuery maxWidth={799}>
        <Hero
              width="100vw"
              height="55vh"
              imageS={HeroS}
              imageM={HeroM}
              imageL={HeroL}
              imageXL={HeroXL}
              overlay="38, 26, 33, 0.82"
              customStyle={{marginTop:'15px', backgroundSize:'100% 100%'}}
          />
          <div style={{width:'100%'}}>
            <h2 style={{textAlign:'center', fontSize:'23px', marginTop:'50px', fontFamily:Styles.avenirBold, width:'90vw', margin:'50px auto 0px auto'}}><b>
              {props.heading}, effective {props.date}
            </b></h2>

            <p style={{paddingLeft:'50px', paddingRight:'50px', lineHeight:'22px', marginTop:'50px'}}>
              {props.children}
            </p>
          </div>
      </MediaQuery>

      <MediaQuery minWidth={800}>
            <Hero
              width="100vw"
              height="55vh"
              imageS={HeroS}
              imageM={HeroM}
              imageL={HeroL}
              imageXL={HeroXL}
              overlay="38, 26, 33, 0.82"
              customStyle={{marginTop:'15px', backgroundSize:'100% 100%'}}
            />
        <div style={{width:'100%'}}>
          <h2 style={{paddingLeft:'221px', fontSize:'23px', marginTop:'50px', fontFamily:Styles.avenirBold}}><b>
            {props.heading}, effective {props.date}
          </b></h2>

          <p style={{paddingLeft:'221px', paddingRight:'221px', lineHeight:'22px', marginTop:'50px'}}>
            {props.children}
          </p>
        </div>
      </MediaQuery>

  </div>
  )
}


export default Legal;
