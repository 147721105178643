import React from 'react'


export default class Hero extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      size:'',
    }
  }
  componentWillMount = () => {
    this.determineSize()
  }

  componentDidMount(){
    window.addEventListener('resize', this.determineSize)
  }

  determineSize = () => {
    if (window.innerWidth < 650) {
      return this.setState({size:'S'})
    } else if(window.innerWidth > 650 && window.innerWidth < 1000) {
      return this.setState({size:'M'})
    } else if(window.innerWidth > 1000 && window.innerWidth < 1500){
      return this.setState({size:'L'})
    } else if(window.innerWidth > 1500 && window.innerWidth < 2000) {
      return this.setState({size:'XL'})
    } else if(window.innerWidth > 2000){
      return this.setState({size:'XXL'})
    } 
  }

  render(){
    const image = {
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      // backgroundSize:'100% 100%',
      backgroundRepeat: 'no-repeat',
      ...this.props.imageCustomStyle
    }

    const size = {
      width:`${this.props.width}`,
      height:`${this.props.height}`,
      ...this.props.customStyle
    }

    const overlay = {
      backgroundColor:`rgba(${this.props.overlay})`,
      height:'100%',
      width:'100%'
    }

    const backgroundImages = {
      S:this.props.imageS,
      M:this.props.imageM,
      L:this.props.imageL,
      XL:this.props.imageXL,
      XXL:this.props.imageXL
    }
    
    return(
      <div>
        <div style={Object.assign(image, size, {backgroundImage:`url(${backgroundImages[this.state.size]})`})}>
           <div id={this.props.id} style={overlay}/>
           {this.props.children}
        </div>
      </div>
    )
  }
}
