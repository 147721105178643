import React from 'react'
import {
    FaCheckCircle,
    FaTimes,
} from 'react-icons/fa'

const absent = {
    color:'rgba(38,26,33, .7)',
    fontSize:'16px',
}

const present = {
    color:'#27CCE1',
    fontSize:'16px',
}

const Absent = (props) => {
    return(
        <FaTimes style={absent}/>
    )
}

const Present = (props) => {
    return(
        <FaCheckCircle style={present}/>
    )
}

export {
    Absent,
    Present
}