import React from 'react'
import FormText from './FormText'
import { ContactForm } from '../Shared/Forms/ContactForm'
import AssetClasses from '../Plans/AssetClasses'
import ContactStyles from '../Shared/Forms/ContactStyles'
import Row  from '../Shared/Row'
import Column from '../Shared/Column'
import PriceTag from '../../images/Home/priceNew.svg'
import BusinessMen from '../../images/Home/user.svg'
import Cart from '../../images/Home/cartNew.svg'
import Styles from '../Shared/Styles'
import MediaQuery from 'react-responsive'

export default class Section3 extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      formOpen:false,
      waitListOpen:false,
      pageOffset:0,
      selected:[]
    }
  }


  openBondDroidProForm = () => {
    const formOpen = !this.state.formOpen
    const pageOffset = window.pageYOffset
    this.setState({...this.state, pageOffset:pageOffset, formOpen:formOpen})
  }

  openWaitListForm = () => {
    const waitListOpen = !this.state.waitListOpen
    const pageOffset = window.pageYOffset
    this.setState({...this.state, pageOffset:pageOffset, waitListOpen:waitListOpen})
  }

  updateSelectedState = (selected) => {
    const objectsKeys = Object.keys(selected)
    const selectedKeys = objectsKeys.filter(key => selected[key] === true)
    this.setState({...this.state, selected:selectedKeys}, () => console.log(this.state.selected))
  }

  updateMessageState = (message) => {
    this.setState({...this.state, message:message})
  }

  render(){
      const headingStyle = {
        fontSize:'23px',
        textAlign:'center',
        color:'#261A21',
        fontFamily:`${Styles.avenirBold}`,
        marginTop:'35px'
      }

    const textStyle = {
      fontSize:'16px',
      lineHeight:'24px',
      height:'275px',
      color:'#261A21'
    }

    const textStyleMobile = {
      ...textStyle,
      height:'230px'
    }

    const buttonStyle = {
      border: '0',
      paddingLeft:'0',
      outline:'0',
      fontSize:'16px',
      fontWeight:'600',
      // color:'#27CCE1',
      textAlign:'left',
      backgroundColor:'white',
    }

    const formPosition = {
      position:'absolute',
      top:this.state.pageOffset,
      left:'0',
      right:'0',
      margin:'0 auto'
    }

    const iconStyle = {
      width:'120px',
      display:'block',
      margin:'0 auto -40px auto',
      height:'120px',
    }

    const bonddroidAutoPricer = {
      iconStyle:iconStyle,
      image:PriceTag,
      headingStyle:headingStyle,
      heading:"Price",
      textStyle:textStyle,
      info:[
          <b>BondDroid<sup><small>TM</small></sup> Auto-Pricer</b>,` is an AI system that predicts trade bid and ask levels for 30,000+ corporate bonds with speed and precision impossible for a human to achieve.`,
          <br/>,<br/>,
          `It uses a unique approach designed to deliver accuracy across market regimes, issuer credit quality and instrument life cycles. BondDroid`, <sup><small>TM</small></sup>, ` deploys over 500 pricing bots — each representing a unique trading persona. `
        ]
    }

    const bonddroidAutoQuoter = {
      iconStyle:iconStyle,
      image:BusinessMen,
      headingStyle:headingStyle,
      heading:"Quote",
      textStyle:textStyle,
      info:[<b>BondDroid<sup><small>TM</small></sup> Auto-Quoter</b>, ` is an out-of-the-box market making engine. It automates quote generation for a single instrument or a portfolio by adjusting BondDroid`, <sup><small>TM</small></sup>, ` Auto-Pricer levels to take into account dealer’s risk appetite, positioning or any other factors unique to their business. `,
          <br/>,<br/>,
          `The tool enables traders to automatically launch and respond to inquiries, or place orders with minimal human intervention. `
        ]
    }

    const bonddroidAlpha = {
      iconStyle:iconStyle,
      image:Cart,
      headingStyle:headingStyle,
      heading:"Screen",
      textStyle:textStyle,
      info:[
          <span style={{color:'#27CCE1', fontWeight:'600'}}><i>Coming Soon!</i></span>,
          <b> BondDroid<sup><small>TM</small></sup> Alpha</b>,` is everything you need to construct bond portfolios on the fly.`,
          <br/>,<br/>,
          `Using smart technology, it predicts bond prices over extended time horizons, identifies bonds that fit a certain investment thesis and leverages AI for relative value analysis`
        ]
    }

    const bondDroidAlphaMobile = {
      ...bonddroidAlpha,
      textStyle:textStyleMobile
    }



      return(
        <div>
          {this.state.formOpen ?
            <ContactForm
              handleClick={this.openBondDroidProForm}
              formTitle={"Get Started with BondDroid Auto-Pricer for Free"}
              subHeading={"Get the first 3-months FREE when you enter into a 2-year contract. If you don’t love it, cancel within the first 3 months. Thank you for an opportunity to earn your trust. "}
              extraField={this.state.message}
              subject={"Get Started with BondDroidPro for Free"}
              positioning={formPosition}
              subscribe={true}
            >
              <MediaQuery maxWidth={699}>
                <FormText
                  styling={ContactStyles.textStyleMobile}
                  updateParentState={this.updateMessageState}
                  formValue={this.state.message}
                />
              </MediaQuery>

              <MediaQuery minWidth={700}>
                <FormText
                  styling={ContactStyles.textStyle}
                  updateParentState={this.updateMessageState}
                  formValue={this.state.message}
                />
              </MediaQuery>
            </ContactForm>
            : null}

            {this.state.waitListOpen ?
              <ContactForm
                handleClick={this.openWaitListForm}
                formTitle={"JOIN OUR WAITLIST!"}
                subHeading={[
                  [`We have big plans for 2019. Tell us which of our upcoming 
                    product launches you are most excited about and become 
                    a proof-of-concept partner.  Or just email us at `],
                  [<strong><i>sales@7-chord.com </i></strong>]
                ]}
                subject={"Waitlist Signup: What Asset Classes Interest You?"}
                extraField={this.state.selected}
                positioning={formPosition}
                subscribe={false}
                partnership={true}
              >
                <AssetClasses
                  updateParentState={this.updateSelectedState}
                />
              </ContactForm>
            : null}

          <div style={{marginTop:'50px'}}>
            <Row custom={{height:'450px'}}>
              <MediaQuery maxWidth={1263}>
                <Column
                    width="315px"
                    {...bonddroidAutoPricer}
                    action={
                      <button onClick={this.openBondDroidProForm} className="request-buttons" style={{...buttonStyle, width:'315px'}}>
                        Get started for Free >
                      </button>
                    }
                  />

                  <Column
                    width="315px"
                    {...bonddroidAutoQuoter}
                    action={
                      <a href="mailto:sales@7-chord.com">
                        <button className="request-buttons"style={buttonStyle}>
                          Book a Demo >
                        </button>
                      </a>
                    }
                  />

                  <Column
                    width="315px"
                    {...bondDroidAlphaMobile}
                    action={
                      <button onClick={this.openWaitListForm} className="request-buttons" style={{...buttonStyle, width:'315px'}}>
                        Join our Waitlist >
                      </button>
                    }
                  />
              </MediaQuery>

              <MediaQuery minWidth={1264} maxWidth={1600}>
                <Column
                  width="315px"
                  {...bonddroidAutoPricer}
                  action={
                    <button onClick={this.openBondDroidProForm} className="request-buttons" style={{...buttonStyle, width:'315px'}}>
                      Get started for Free >
                    </button>
                  }
                />

                <Column
                  width="315px"
                  {...bonddroidAutoQuoter}
                  action={
                    <a href="mailto:sales@7-chord.com">
                      <button className="request-buttons" style={buttonStyle}>
                        Book a Demo >
                      </button>
                    </a>
                  }
                />

                <Column
                  width="315px"
                  {...bonddroidAlpha}
                  action={
                    <button onClick={this.openWaitListForm} className="request-buttons" style={{...buttonStyle, width:'315px'}}>
                      Join our Waitlist >
                    </button>
                  }
                />
              </MediaQuery>

              <MediaQuery minWidth={1601}>
              <Column
                  width="335px"
                  {...bonddroidAutoPricer}
                  action={
                    <button onClick={this.openBondDroidProForm} className="request-buttons" style={{...buttonStyle, width:'315px'}}>
                      Get started for Free >
                    </button>
                  }
                />

                <Column
                  width="335px"
                  {...bonddroidAutoQuoter}
                  action={
                    <a href="mailto:sales@7-chord.com">
                      <button className="request-buttons" style={buttonStyle}>
                        Book a Demo >
                      </button>
                    </a>
                  }
                />

                <Column
                  width="335px"
                  {...bonddroidAlpha}
                  action={
                    <button className="request-buttons" onClick={this.openWaitListForm} style={{...buttonStyle, width:'315px'}}>
                      Join our Waitlist >
                    </button>
                  }
                />
              </MediaQuery>
            </Row>

          </div>
        </div>
      )
  }
}