import React from 'react'
import './Styles/Option.css'

const Option = (props) => {
    const imageStyle = {
        height:'40px',
        width:'40px'
    }

    const textStyle = {
        cursor:'pointer',
        outline:'0',
        border:'0'
    }

    textStyle.color = props.show === props.name ? '#27CCE1' : '#261A21'

    return(
        <div className="option-container">
            {props.image ? <img style={imageStyle} src={props.image} /> : null }
            <button 
                name={props.name} 
                style={textStyle}
                onClick={props.updateShow}
            >
                {props.name}
            </button>
        </div>
    )
}

export default Option