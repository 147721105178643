import React from 'react'
import Navbar from '../components/Shared/Navbar/index'
import SocialBar from '../components/Shared/SocialBar'
import PressItem from '../components/Press/PressItem'
import { PressReleaseData, PressArticleData } from './Press/PressReleaseData'
import Subscribe from '../components/Shared/Footer/Subscribe'
import MediaQuery from 'react-responsive'
import Footer from '../components/Shared/Footer/index'
import Styles from '../components/Shared/Styles'
import { TwitterTimelineEmbed } from 'react-twitter-embed'
import Hero from '../components/Shared/Hero'
import HeroS from '../images/Press/pressM.jpg'
import HeroM from '../images/Press/pressM.jpg'
import HeroL from '../images/Press/press.jpg'
import HeroXL from '../images/Press/press.jpg'
import './Styles/Press/Press.css'


export default class Press extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            show:'RELEASES'
        }
    }

    componentDidMount = () => window.scrollTo(0,0)

    switchTabs = (evt) => {
        const show = evt.target.name
        this.setState({show})
    }

    render(){
        const twitterOptions = {
            height:'90vh',
            width:'90%'
        }

        const selectButtons = {
            border:'0',
            fontWeight:'600',
            fontSize:'15px',
            color:'#261A21',
            outline:'0',
            cursor:'pointer',
            backgroundColor:'#ffffff'
        }

        const selectedButton = {
            ...selectButtons,
            color:'#27CCE1'
        }

        const pressText = {
            color:'#27CCE1',
            fontSize:'15px',
            fontWeight:'600',
        }

        const pressTextLarge = {
            ...pressText,
            width:'310px'
        }


        const heroTextContainer = {
            position:'relative',
            bottom:'calc((65vh))',
            marginLeft:'20%',
            width:'25%',
            textAlign:'right',
            height:'100%'
        }

        const heroTextContainerMed = {
            ...heroTextContainer,
            marginLeft:'10%',
            width:'40%'
        }

        const heroTextContainerSmall = {
            ...heroTextContainer,
            marginLeft:'0',
            width:'60%',
            margin:'0 auto',
            textAlign:'center'
        }

        const headingTextStyle = {
            fontFamily: `${Styles.avenirBold}`,
            fontSize: '41px',
            color: '#FFFFFF',
            lineHeight: '44px',
        }

        const headingTextStyleLarge = {
            ...headingTextStyle,
            width:'310px'
        }

        const tabSwapContainer = {
            width:'100vw',
            textAlign:'center',
            marginTop:'30px'
        }


        return(
            <div>
                <Navbar media={true}/>
                <Hero
                    height="65vh"
                    width="100vw"
                    imageS={HeroS}
                    imageM={HeroM}
                    imageL={HeroL}
                    imageXL={HeroXL}
                    customStyle={{marginTop:'15px'}}
                    imageCustomStyle={{backgroundSize:'100% 100%'}}
                >

                <MediaQuery maxWidth={620}>
                    <div style={heroTextContainerSmall}>
                            <div className="hero-flex-text">
                                <p style={pressText}>PRESS</p>
                                <h3 style={headingTextStyle}>what others say about us</h3>
                                <p>
                                    <span style={{...pressText, color:'#27CCE1'}}>for press inquiries and kit contact</span><br/>
                                    <a href="mailto:press@7-chord.com" 
                                   style={{...pressText, color:'white', textDecoration:'none'}}>press@7-chord.com
                                </a>       
                                </p>
                                
                            </div>
                    </div>
                </MediaQuery>

                <MediaQuery minWidth={621} maxWidth={999}>
                    <div style={heroTextContainerMed}>
                        <div className="hero-flex-text">
                            <p style={pressTextLarge}>PRESS</p>
                            <h3 style={headingTextStyleLarge}>what others say about us</h3>
                            <p style={{width:'310px'}}>
                                <span style={{...pressText, color:'#27CCE1'}}>for press inquiries and kit contact</span><br/>
                                <a href="mailto:press@7-chord.com" 
                                   style={{...pressText, color:'white', textDecoration:'none'}}>press@7-chord.com
                                </a>                              
                            </p>
                            
                        </div>
                    </div>
                    <SocialBar height='35vh'/>
                </MediaQuery>
                <MediaQuery minWidth={1000}>
                    <div style={heroTextContainer}>
                        <div className="hero-flex-text">
                            <p style={pressTextLarge}>PRESS</p>
                            <h3 style={headingTextStyleLarge}>what others say about us</h3>
                            <p style={{width:'310px'}}>
                                <span style={{...pressText, color:'#27CCE1'}}>for press inquiries and kit contact</span><br/>
                                <a href="mailto:press@7-chord.com" 
                                   style={{...pressText, color:'white', textDecoration:'none'}}>press@7-chord.com
                                </a>                                  
                        </p>
                            
                        </div>
                    </div>
                    <SocialBar height='35vh'/>
                </MediaQuery>

                </Hero>
                    <MediaQuery maxWidth={800}>
                        <div style={tabSwapContainer}>
                            <button
                                onClick={this.switchTabs}
                                name="RELEASES"
                                style={this.state.show === 'RELEASES' ? {...selectedButton, marginRight:'10vw'} : {...selectButtons, marginBottom:'15px', marginRight:'10vw'}}
                            >
                                PRESS RELEASES
                            </button>
                            <button
                                onClick={this.switchTabs}
                                name="ARTICLES"
                                style={this.state.show !== 'RELEASES' ? selectedButton : selectButtons}
                            >
                                ARTICLES
                            </button>
                        </div>

                    </MediaQuery>
                    <div className="press-container">
                    {this.state.show === 'RELEASES' ? 
                        <div className="press-release-container">
                            <PressItem {...PressReleaseData.row7}/>
                            <PressItem {...PressReleaseData.row1}/>
                            <PressItem {...PressReleaseData.row2}/>
                            <PressItem {...PressReleaseData.row3}/>
                            <PressItem {...PressReleaseData.row4}/>
                            <PressItem {...PressReleaseData.row5}/>
                            <PressItem {...PressReleaseData.row6}/>
                        </div> : 
                        <div className="press-release-container">
                            <PressItem {...PressArticleData.row1} />
                            <PressItem {...PressArticleData.row2} />
                            <PressItem {...PressArticleData.row3} />
                            <PressItem {...PressArticleData.row4} />
                            <PressItem {...PressArticleData.row5} />
                        </div>
                    }

                    <MediaQuery minWidth={800}>
                        <div className="press-side-container">
                            <div style={{width:'80%', margin:'0 auto'}}>
                                <button
                                    onClick={this.switchTabs}
                                    name="RELEASES"
                                    style={this.state.show === 'RELEASES' ? {...selectedButton, marginBottom:'15px'} : {...selectButtons, marginBottom:'15px'}}
                                >
                                    PRESS RELEASES
                                </button>
                                <br/>
                                <button
                                    onClick={this.switchTabs}
                                    name="ARTICLES"
                                    style={this.state.show !== 'RELEASES' ? selectedButton : selectButtons}
                                >
                                    ARTICLES
                                </button>

                                <Subscribe press={true} page='media'/>
                                <div style={{marginTop:'50px'}}></div>

                                <div style={{marginLeft:'-10px'}}>
                                    <TwitterTimelineEmbed
                                        sourceType="profile"
                                        screenName="7chordbd"
                                        options={twitterOptions}
                                    />
                                </div>

                            </div>
                        </div>
                    </MediaQuery>

                </div>
                <Footer/>

            </div>
        )
    }
}
