import React from 'react'

import Logo from '../../../images/Shared/logoOpt.png'
import { Link } from 'react-router-dom'
import Styles from '../Styles'
import MediaExpanded from './MediaExpanded'
import CustomLink from './CustomLink'

import '../Styles/Navbar.css'

export default class NavbarBig extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      mediaDropdown:false,
      links:{
        press:false,
        blog:false,
        events:false,
      }
    }
  }

  showDropdown = () => {
    const mediaDropdown = true
    this.setState({mediaDropdown:mediaDropdown})
  }

  hideDropdown = () => {
    const mediaDropdown = false
    this.setState({mediaDropdown:mediaDropdown})
  }


  highlightDropdown = (evt) => {
    let links = Object.assign({}, this.state.links)
    links[evt.target.name] = !this.state.links[evt.target.name]
    this.setState({...this.state, links:links})
  }


  render(){
      const logoLink = {
        height:'40px',
        position:'absolute',
        right:'90px',
        top:'20px'
      }
    
      const link = {
        textDecoration:'none',
        color:`${Styles.redText}`,
        fontWeight:'700',
        paddingRight:'20px'
      }
      
      return(
        <div className="navbar">
          <div className="navbar-items">
    
            <CustomLink 
              route={this.props.plans} 
              link="/plans" 
              linkName="PLANS"
              name="plans"
            />


            <div 
              className="navbar-link-media"
              onMouseOver={this.showDropdown}
              onMouseLeave={this.hideDropdown}
            >
              <div
              >
                <Link
                  style={{...link, color:`${this.props.media ? Styles.blueText : Styles.redText}`}}
                  to="#"
                >
                  MEDIA
                </Link>
                  {this.state.mediaDropdown ? 
                  <MediaExpanded
                    links={['', 'PRESS', 'BLOG', 'EVENTS']}
                    update={this.highlightDropdown}
                    highlighted={Object.keys(this.state.links).filter(key => this.state.links[key] )}
                    hideDropdown={this.hideDropdown}
                  />
                  : null}
              </div>

            </div>
    
            <CustomLink 
              route={this.props.about} 
              link="/about" 
              linkName="ABOUT" 
              name="about"
            />

          </div>
    
            <Link to="/">
              <img style={logoLink} src={Logo} alt="Company Logo"/>
            </Link>
        </div>
      )
  }
}



