import React from 'react'
import ContactContracted from './ContactContracted'
import ContactExpanded from './ContactExpanded'

import '../Styles/Shared.css'

export default class Contact extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      expanded:false
    }
  }

  expandContact = () => {
    const expanded = !this.state.expanded
    this.setState({expanded})
  }


  render(){
    return(
      <div style={{zIndex:'3', position:'absolute'}}>
        {
          this.state.expanded ?
            <ContactExpanded
              view={this.props.view}
              handleClick={this.expandContact}
            />
            :
            <ContactContracted
              left={this.props.left}
              view={this.props.view}
              handleClick={this.expandContact}
            />
        }
      </div>
    )
  }
}
