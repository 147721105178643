import React from 'react'
import { FaTwitter, FaLinkedin, FaGithub, FaAngellist } from 'react-icons/fa'

const SocialBar = (props) => {

  const socialContainer = {
    position:'absolute',
    height:props.height,
    left:'calc(100vw - 125px)',
    top:'50%',
    transform:'translateY(-50%)'
  }

  const iconStyle = {
    fontSize:'22px',
    paddingBottom:'10px',
    paddingTop:'10px',
    color:'white'
  }

  return(
    <div style={socialContainer}>
      <div className="social-bar">

        <a href="https://twitter.com/7chordbd" target="_blank" rel="noopener noreferrer" >
          <FaTwitter style={iconStyle}/>
        </a>
        <br/>

        <a href="https://www.linkedin.com/company/7-chord/" target="_blank" rel="noopener noreferrer">
          <FaLinkedin style={iconStyle}/>
        </a>
        <br/>

        <a href="https://github.com/7chord" target="_blank" rel="noopener noreferrer">
          <FaGithub style={iconStyle}/>
        </a>
        <br/>

        <a href="https://angel.co/7-chord-1" target="_blank" rel="noopener noreferrer">
          <FaAngellist style={iconStyle}/>
        </a><br/>

        <a
          style={{textDecoration:'none'}} 
          href="https://www.crunchbase.com/organization/7-chord" 
          target="_blank" rel="noopener noreferrer" >
            <i style={{...iconStyle, paddingBottom:'20px'}} className="socicon-crunchbase"></i>
        </a>
      </div>
    </div>
  )
}

export default SocialBar;
