import React from 'react'
import Logo from '../../../images/Shared/logoOpt.png'
import { FaBars } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import Expanded from './Expanded'



  export default class NavbarSmall extends React.Component {
      constructor(props){
          super(props)
          this.state = {
              expanded:false
          }
      }

        toggleExpansion = () => {
          const expanded = !this.state.expanded
          this.setState({expanded:expanded})
      }

      render(){
        const smallNavbarContainer = {
            height:'67px',
            width:'100vw',
        }

        const logoStyle = {
            height:'40px',
            display:'block',
            position:'relative',
            top:'20px',
            left:'0',
            right:'0',
            margin:'0 auto'
        }

        const toggleButton = {
            color:'#262A21',
            position:'relative',
            left:'calc(100vw - 55px)',
            bottom:'8px',
            fontSize:'28px'
        }
          return(
              <div>
                  { this.state.expanded ? 
                    <Expanded toggle={this.toggleExpansion}/> :
                        <div style={smallNavbarContainer}>

                            <Link to="/">
                                <img
                                    src={Logo}
                                    style={logoStyle}
                                    alt="7 Chord Company Logo"
                                />
                            </Link>

                            <FaBars 
                                style={toggleButton}
                                onClick={this.toggleExpansion}
                            />
                        </div>
                  }
             </div>

          )
      }
  };