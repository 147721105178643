import React from 'react'

const UniverseContent = (props) => {
    const leftColumn = {
        width:'18vw',
        padding:'15px 0'
    }

    const rightColumn = {
        width:'30vw'
    }

    const quarterText = {
        color:'#27CCE1',
        fontWeight:'700'
    }

    const buttonStyle = {
        border:'0',
        outline:'0',
        cursor:'pointer',
        textDecoration:'underline'
    }

    return(
        <div style={{width:'52vw'}}>
            <h2>BondDroid Universe</h2>
            <p>BondDroid AutoPricer predicts levels for the majority of the actively traded US Corporate Bonds. 
                We perform rigorous data checks to incorporate all structural features of this henerogeneous asset 
                class. With the help of our partners at Quantifi, Inc. we are able to leverage one of the most 
                comprehensive quantitative libraries for credit instruments. 
            </p>

            <p>
                As of April 1, 2019, we are covering 9,000+ active bond issues. In 2019 we are planning to expand our 
                coverage to 25,000+ instruments, with the bulk of the expansion on the roadmap in Q2, 2019.
            </p>  

            <p>
                The complete list of issuers can be found 
                    <button style={buttonStyle} onClick={props.downloadUniverse}>
                        here
                    </button>
            </p>

            <h2>Inclusion Criteria</h2>
            <p>We currently cover the following types of bonds:</p>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <div style={leftColumn}>Asset Classes</div>
                        </td>
                        <td>
                            <div style={rightColumn}>Corporate Bonds</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={leftColumn}>Currency</div>
                        </td>
                        <td>
                            <div style={rightColumn}>USD</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={leftColumn}>Issuer Status</div>
                        </td>
                        <td>
                            <div style={rightColumn}>Non-defaulted</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={leftColumn}>Amount Outstanding</div>
                        </td>
                        <td>Minimum $25million<br/>
                            <div style={rightColumn}>
                                <span style={quarterText}>Q1 2019:</span> Add &gt; $25mm
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={leftColumn}>Coupon Types</div>
                        </td>
                        <td>
                            <div style={rightColumn}>Fixed Rate, Floating Rate, Zero Coupon, Stepped Coupon</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={leftColumn}>Debt Rank</div>
                        </td>
                        <td>
                            <div style={rightColumn}>Senior Secured, Senior Unsecured, Subordinated</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={leftColumn}>Bond Types</div>
                        </td>
                        <td>
                            <div style={{...rightColumn, paddingTop:'15px'}}>
                                Callable, Bonds with Event-driven triggers, Amortizing Bonds with sinking schedules, RegS and 144A bonds
                                <br/><br/>
                                <span style={quarterText}>
                                    Q2 2019:
                                </span> Puttable, Original Issue Discount, Fixed-to-floaters, Callable Floaters, Pre-issues, Adjustable Rate, Perpetual
                                <br/><br/>
                                <span style={quarterText}>
                                    Q4 2019:
                                </span> Convertible, PIK, Preferred Equity
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <h2>Data Volumes</h2>
        </div>
    )
}

export default UniverseContent