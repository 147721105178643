import React from 'react'
import MediaQuery from 'react-responsive'

const Endorsement = (props) => {
    const backerContainer = {
        width:'300px',
        marginRight:'30px',
        textAlign:'center',
        height:'70px'
      }

    const mobileBackerContainer = {
        ...backerContainer,
        marginBottom:'20px'
    }
    
      const greyScale = {
        filter:'grayscale(100%)'
      }

      const addColor = () => {
          props.hoverIn(props.name)
      }

      const removeColor = () => {
          props.hoverOut(props.name)
      }
      
    return(
        <div>
            <MediaQuery maxWidth={600}>
                <div
                    name={props.name}
                    style={props.addColor===true ? {...mobileBackerContainer} : {...mobileBackerContainer, ...greyScale}}
                >
                    <a target="_blank" rel="noopener noreferrer" href={props.website}>
                        <img
                            style={{verticalAlign:'text-bottom'}}
                            alt={props.alternate} 
                            src={props.image}
                            onMouseEnter={addColor}
                            onMouseLeave={removeColor}
                        />
                    </a>
                </div>
            </MediaQuery>
            <MediaQuery minWidth={601}>
                <div
                    name={props.name}
                    style={props.addColor===true ? {...backerContainer} : {...backerContainer, ...greyScale}}
                >
                    <a target="_blank" rel="noopener noreferrer" href={props.website}>
                        <img
                            alt={props.alternate} 
                            src={props.image}
                            onMouseEnter={addColor}
                            onMouseLeave={removeColor}
                        />
                    </a>
                </div>
            </MediaQuery>
        </div>

    )
}

export default Endorsement;