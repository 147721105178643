import NavbarSmall from './NavbarSmall'
import NavbarBig from './Navbar'
import MediaQuery from 'react-responsive'
import Contact from '../Contact/Contact'
import React from 'react'

const Navbar = (props) => {
    return(
        <div>
            <MediaQuery maxWidth={850}>
                <Contact view={'home'}/>
                <NavbarSmall/>
            </MediaQuery>

            <MediaQuery minWidth={851}>
                <Contact 
                    left='60px'
                    view={'home'}
                />
                <NavbarBig
                    media={props.media}
                    about={props.about}
                    plans={props.plans}
                    support={props.support}
                />
          </MediaQuery>
        </div>
    )
}
export default Navbar;


