import React from 'react'
import './Styles/Option.css'

const DataOrgContent = (props) => {
     const columnStyle = {
        width:'8vw'
     }

     const descriptionStyle = {
         width:'20vw',
         padding:'10px'
     }

     const unitStyle = {
        width:'10vw',
        padding:'0 10px'
     }

     const availabilityStyle = {
        width:'6vw'
     }

     const typeStyle = {
        width:'8vw'
     }

     const filterStyle = {
        width:'3vw',
     }

     const primaryKeyStyle = {
        width:'6vw'
     }

     
    return(
        <div>
            Data Organization
            We are backed by an elite group of Wall Street executives, strategic investors and prestigious fintech accelerators.
            <p>Columns</p>
            <p>This table has the following columns:</p>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <div style={columnStyle}>
                                COLUMN
                            </div>
                        </td>

                        <td>
                            <div style={descriptionStyle}>
                                DESCRIPTION
                            </div>
                        </td>

                        <td>
                            <div style={unitStyle}>
                                UNIT
                            </div>
                        </td>

                        <td>
                            <div style={{...availabilityStyle, paddingRight:'20px'}}>
                                AVAILABLE BEFORE<br/>2019-01-01
                            </div>
                        </td>

                        <td>
                            <div style={typeStyle}>TYPE</div>
                        </td>
                        <td>
                            <div style={{...filterStyle, paddingRight:'20px'}}>FILTER</div>
                        </td>
                        <td>
                            <div style={primaryKeyStyle}>PRIMARY KEY</div>
                        </td>
                    </tr>

                    <tr>
                        <td>ticker</td>
                        <td style={descriptionStyle}>Issuer stock ticker</td>
                        <td></td>
                        <td>Yes</td>
                        <td>String</td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>instrument_id</td>
                        <td style={descriptionStyle}>
                            Standard industry accepted instrument identifier.  Note: this column is
                            available only to apropriately licensed customers.
                        </td>
                        <td></td>
                        <td>Yes</td>
                        <td>String</td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>maturity_date</td>
                        <td style={descriptionStyle}>
                            The date on which the principal amount of a bond is to be paid in full
                        </td>
                        <td></td>
                        <td>Yes</td>
                        <td>Date</td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>coupon</td>
                        <td style={descriptionStyle}>
                            The annual interest payment that the bondholder receives from the bond's issue date until maturity
                        </td>
                        <td>
                            <div style={unitStyle}>
                                Percentage
                            </div>
                        </td>
                        <td>Yes</td>
                        <td>BigDecimal(32,26)</td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>benchmark_treasury</td>
                        <td style={descriptionStyle}>
                            The most frequent Treasury issue used for hedging purposes
                            of a given US Corporate bond issue
                        </td>
                        <td></td>
                        <td>No</td>
                        <td>String</td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>bonddroid_industry</td>
                        <td style={descriptionStyle}>
                            Industry of the bond issuer.  Source: 7 Chord, Inc.
                        </td>
                        <td></td>
                        <td>Yes</td>
                        <td>String</td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>bonddroid_segment</td>
                        <td style={descriptionStyle}>
                            Sector of the bond issuer. Source: 7 Chord, Inc.
                        </td>
                        <td></td>
                        <td>Yes</td>
                        <td>String</td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>seq_id</td>
                        <td style={descriptionStyle}>
                            Provides the chronological ordering for each
                            <kbd>instrument_id</kbd>.  Can be sorted with the
                            <kbd>instrument_id</kbd> for the chronological ordering of the predictions
                        </td>
                        <td></td>
                        <td>Yes</td>
                        <td>String</td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>date</td>
                        <td style={descriptionStyle}>
                            Date of the Bid or Ask level
                        </td>
                        <td>
                            <div style={unitStyle}>
                                YYYY-MM-DD(UTC)
                            </div>
                        </td>
                        <td>Yes</td>
                        <td>Date</td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>time</td>
                        <td style={descriptionStyle}>
                            Time of the Bid or Ask level
                        </td>
                        <td>
                            <div style={unitStyle}>
                                HH:MM:SS(UTC)
                            </div>
                        </td>
                        <td>Yes</td>
                        <td>String</td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>bid_oas</td>
                        <td style={descriptionStyle}>
                            Option Adjusted Spread level at the Bid side of the market
                        </td>
                        <td>
                            <div style={unitStyle}>
                                Basis Points
                            </div>
                        </td>
                        <td>Yes</td>
                        <td>BigDecimal(32,26)</td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>ask_oas</td>
                        <td style={descriptionStyle}>
                            Option Adjusted Spread level at the offer side of the market
                        </td>
                        <td>
                            <div style={unitStyle}>
                                Basis Points
                            </div>
                        </td>
                        <td>Yes</td>
                        <td>Big Decimal(32,26)</td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>bid_price</td>
                        <td style={descriptionStyle}>
                            Price level at the Bid side of the market
                        </td>
                        <td>
                            <div style={{...unitStyle, paddingTop:'10;x'}}>
                                Percentage of par value converted to a point scale
                            </div>
                        </td>
                        <td>Yes</td>
                        <td>BigDecimal(32,26)</td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>ask_price</td>
                        <td style={descriptionStyle}>
                            Price level at the offer side of the market
                        </td>
                        <td>
                        <div style={{...unitStyle, paddingTop:'10;x'}}>
                                Percentage of par value converted to a point scale
                            </div>
                        </td>
                        <td>Yes</td>
                        <td>BigDecimal(32,26)</td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>bid_yield</td>
                        <td style={descriptionStyle}>
                            Yield-to-maturity implied by the Bid Price
                        </td>
                        <td></td>
                        <td>Yes</td>
                        <td>BigDecimal(32,26)</td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>ask_yield</td>
                        <td style={descriptionStyle}>
                            Yield-to-maturity implied by the Ask Price
                        </td>
                        <td></td>
                        <td>Yes</td>
                        <td>BigDecimal(32,26)</td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>bid_sot</td>
                        <td style={descriptionStyle}>
                            Spread over Benchmark Treasury implied by the Bid Price
                        </td>
                        <td></td>
                        <td>No</td>
                        <td>BigDecimal(32,26)</td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>ask_sot</td>
                        <td style={descriptionStyle}>
                            Spread over Benchmark Treasury implied by the Ask Price
                        </td>
                        <td></td>
                        <td>No</td>
                        <td>BigDecimal(32,26)</td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default DataOrgContent;