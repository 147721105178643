import React from 'react'
import Styles from '../Shared/Styles'
import Wrapper from '../Shared/Animation'


export default class Product extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      displayText:false,
    }
  }

  displayText = () => {
    this.setState({displayText:true})
  }

  hideText = () => {
    this.setState({displayText:false})
  }

  toggleText = () => {
    const textDisplayed = !this.state.displayText
    this.setState({displayText:textDisplayed})
  }

  render(){
    const productContainerStyle = {
      width:'25vw',
      height:'50vh',
      minHeight:'373px',
      backgroundImage:`url(${this.props.image})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      position:'relative',
    }

    const captionStyle = {
      position:'absolute',
      bottom:'10%',
      color:'#27CCE1',
      fontFamily:`${Styles.avenirBold}`,
      fontSize:'16px',
      textAlign:'center',
      width:'100%',
    }

    const overlay1 = {
      backgroundColor:'rgba(56, 38, 48, 0.52)',
      height:'100%',
      width:'100%'
    }

    const overlay2 = {
      ...overlay1,
      backgroundColor:'rgba(56, 38, 48, 0.82)'
    }


    return(
      <div
        style={productContainerStyle}
        onMouseEnter={this.displayText}
        onMouseLeave={this.hideText}
        onClick={this.toggleText}
        
      >
        <div style={this.state.displayText ? overlay2 : overlay1}>

          {this.state.displayText ?
            <div>
              <Wrapper description={this.props.description}/>
              <p style={{...captionStyle, color:'white'}}>
                {this.props.caption}
              </p>
            </div>:
              <p style={captionStyle}>{this.props.caption}</p>
          }
        </div>
      </div>
    )
  }
}
