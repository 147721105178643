import React from 'react'
import { Link } from 'react-router-dom'

const MediaExpanded = (props) => {
    const linkBox = {
        borderBottom:'1px solid rgba(244,244,244, .4)',
        backgroundColor:'#ffffff',
        padding:'15px 35px 10px 25px',
    }

    const highlightedLinkBox = {
        ...linkBox,
        backgroundColor:'#27CCE1',
        color:'white'
    }

    const linkStyle = {
        textDecoration:'none',
        color:'#262A21',
        fontWeight:'600',
        fontSize:'16px'
    }

    const highlightedLinkStyle = {
        ...linkStyle,
        color:'white'
    }

    
    const links = props.links.map(link => {
        if (link === 'BLOG') {
            return(
                <div 
                    key={link}
                    style={props.highlighted.includes(link.toLowerCase()) ? highlightedLinkBox : linkBox}
                >
                    <a
                        href="https://medium.com/7-chord"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={props.highlighted.includes(link.toLowerCase()) ? highlightedLinkStyle : linkStyle}
                        name={link.toLowerCase()}
                        onMouseOver={props.update}
                        onMouseOut={props.update}
                    >
                        {link}
                    </a>
                </div>
            ) 
        } 
        else if (link === '') {
            return(
                <div key={link} style={{...linkBox, border:'0', padding:'5px'}}></div>
            )
        }

        else {
            return(
                <div 
                    key={link}
                    style={props.highlighted.includes(link.toLowerCase()) ? highlightedLinkBox : linkBox}
                >
                    <Link 
                        style={props.highlighted.includes(link.toLowerCase()) ? highlightedLinkStyle : linkStyle}
                        to={link.toLowerCase()}
                        name={link.toLowerCase()}
                        onMouseOver={props.update}
                        onMouseOut={props.update}
                    >
                        {link}
                    </Link>
                </div>
            )
        }

    })
    return(
        <div 
            style={{zIndex:'4', position:'absolute', top:'15px', right:'0px'}}
            onMouseLeave={props.hideDropdown}
        > 
            {links}
        </div>
    )
}

export default MediaExpanded;




