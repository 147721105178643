import React from 'react'
import './Styles/Column.css'
import MediaQuery from 'react-responsive'

const Column = (props) => {
    const columnStyle = {
        width:props.width,
        margin:'0 2em'
    }

    const columnStyleMobile = {
        width:'props.width',
        marginLeft:'10px',
        marginRight:'10px'
    }


    return(
        <div>
            <MediaQuery maxWidth={500}>
                <div style={columnStyleMobile}>
                    <div style={{width:'100%'}}>
                        <img 
                            src={props.image}
                            style={props.iconStyle}
                        />
                    </div>
                    <p style={props.headingStyle}>{props.heading}</p>
                    <p style={props.subHeadingStyle}>{
                        props.subtitle}
                    </p>
                    {props.children}
                    <p style={props.textStyle}>
                        {props.info}
                    </p>
                    <br/>
                    {props.action}
            </div>
            </MediaQuery>

            <MediaQuery minWidth={501}>
            <div style={columnStyle}>
            <div style={{width:'100%'}}>
                <img 
                    src={props.image}
                    style={props.iconStyle}
                />
            </div>
            <p style={props.headingStyle}>{props.heading}</p>
            <p style={props.subHeadingStyle}>{
                props.subtitle}
            </p>
            {props.children}
            <p style={props.textStyle}>
                {props.info}
            </p>
            <br/>
            {props.action}
        </div>
            </MediaQuery>
        </div>
    )
}

export default Column;
