import React from 'react'
import ContactStyles from '../Shared/Forms/ContactStyles'
import MediaQuery from 'react-responsive'
import '../Shared/Forms/contactform.css'


export default class FormText extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      message:''
    }
  }

  changeHandler = (evt) => {
    const message = evt.target.value
    this.setState({message:message}, () => this.props.updateParentState(message))
  }

  render(){
    return(
      <div>
        <MediaQuery maxWidth={699}>
          <label style={ContactStyles.labelStyleMobile}>
            HOW CAN WE HELP YOU?<br/>
            <textarea
              name="message"
              className="contactform"
              style={this.props.styling}
              value={this.props.formValue}
              onChange={this.changeHandler}
            />
          </label>
        </MediaQuery>

        <MediaQuery minWidth={700}>
          <label style={ContactStyles.labelStyle}>
            HOW CAN WE HELP YOU?<br/>
            <textarea
              name="message"
              className="contactform"
              style={this.props.styling}
              value={this.props.formValue}
              onChange={this.changeHandler}
            />
          </label>
        </MediaQuery>
      </div>
    )
  }
}
