import React from 'react'
import WebApp from '../../images/Developers/webapp.svg'
const DisplayContent = (props) => {
    return(
        <div>
            Web App
            We are backed by an elite group of Wall Street executives, strategic investors and prestigious fintech accelerators.
            <br/>
            <img src={WebApp} />
        </div>
    )
}

export default DisplayContent;