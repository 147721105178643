import React from 'react'
import AboutCardDetail from './AboutCardDetail'
import Hero from '../Shared/Hero'
import MediaQuery from 'react-responsive'


export default class AboutCard extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      showBio:false
    }
  }

  toggleBio = () => {
    const showBioStatus = !this.state.showBio
    this.setState({showBio:showBioStatus})
  }
  render(){
    const containerStyle = {
      position:'relative',
      width:'300px',
      height:'300px',
      marginRight:'30px',
      marginBottom:'30px'
    }

    const backgroundStyle = {
      backgroundImage:`url(${this.props.image})`,
      backgroundPosition:'center',
      backgroundSize:'cover',
      backgroundRepeat:'no-repeat',
      height:'300px',
      width:'300px',

    }

    const containerStyleMobile = {
      ...containerStyle,
      marginRight:'0'
    }

    return(
      <div>
        <MediaQuery minWidth={660}>
          <div
            onMouseEnter={this.toggleBio}
            onMouseLeave={this.toggleBio}
            onClick={this.toggleBio}
            style={containerStyle}
          >
            <div style={backgroundStyle}>
              {this.state.showBio ?
                <div>
                  <AboutCardDetail
                    name={this.props.name}
                    title={this.props.title}
                    bio={this.props.bio}
                    linkedIn={this.props.linkedIn}
                    display={this.state.showBio}
                  />
                </div>:null
              }
            </div>
          </div>
        </MediaQuery>

        <MediaQuery maxWidth={659}>
          <div
            onMouseEnter={this.toggleBio}
            onMouseLeave={this.toggleBio}
            onClick={this.toggleBio}
            style={containerStyleMobile}
          >
            <div style={backgroundStyle}>
              {this.state.showBio ?
                <div>
                  <AboutCardDetail
                    name={this.props.name}
                    title={this.props.title}
                    bio={this.props.bio}
                    linkedIn={this.props.linkedIn}
                    display={this.state.showBio}
                  />
                </div>:null
              }
            </div>
          </div>
        </MediaQuery>
      </div>

    )
  }
}
