import React from 'react'
import { PlanRow, SubRow, PlanRowSmall, SubRowSmall} from './PlanRow'
import Styles from '../Shared/Styles'

const headingStyle = {
    color:'#27CCE1',
    fontWeight:'bold',
    fontFamily:Styles.avenirBold
}


const PlanHeading = (props) => {
    return(
        <PlanRow>
            <SubRow
                col1=""
                col2={<span style={headingStyle}>ENTERPRISE</span>}
                col3={<span style={headingStyle}>PRO</span>}
                col4={<span style={headingStyle}>DATA</span>}
                show2={true}
                show3={true}
                show4={true}/>
        </PlanRow>
    )
}

const PlanHeadingSmall = (props) => {
    return(
        <PlanRowSmall>
            <SubRowSmall
                col1=""
                col2={<span style={headingStyle}>ENTERPRISE</span>}
                show2={props.show === 'col2'}
                col3={<span style={headingStyle}>PRO</span>}
                show3={props.show === 'col3'}
                col4={<span style={headingStyle}>DATA</span>}
                show4={props.show === 'col4'}/>
        </PlanRowSmall>
    )
}

export {
    PlanHeading,
    PlanHeadingSmall
}