import React from 'react'
import ReactPlayer from 'react-player'
import Camera from '../../images/Events/Camera.jpg'

import MediaQuery from 'react-responsive'
import './Styles/Events.css'

const EventContainer = (props) => {

    const recapButton = {
        color:'#261A21',
        border:'1px solid #261A21',
        borderRadius:'17px',
        padding:'8px 22px',
        fontSize:'15px',
        fontWeight:'600',
        textAlign:'center',
        outline:'0',
        width:'100px',
        cursor:'pointer',
        backgroundColor:'#ffffff'
    }
    
    const dateText = {
        textAlign:'center',
        fontSize:'20px',
        fontWeight:'600',
        color:'#261A21',
        marginTop:'0'
    }

    const imgStyle = {
        height:'230px',
        width:'430px'
    }

    const imgStyleSmall = {
        width:'330px',
        height:'183px'
    }

    const cameraStyle = {
        height:'35px',
        width:'40px'
    }

    const descriptionStyle = {
        lineHeight:'24px',
        color:'rgb(38, 26, 33)'
    }


    return(
        <div className="events-container">
            <div className="events-media-container">
                {props.video ?
                        <ReactPlayer 
                            url={props.video}
                            width={'100%'}
                            height={'100%'}
                            light={props.light}
                            image={props.image} 
                        />
                    :
                    <div style={{display:'inline'}}>
                        <MediaQuery maxWidth={849}>
                            <img style={{width:'50vw', height:'auto'}} src={props.photo} />
                        </MediaQuery>
                        <MediaQuery minWidth={850} maxWidth={999}>
                            <img style={imgStyleSmall} src={props.photo} />
                        </MediaQuery>

                        <MediaQuery minWidth={1000}>
                            <img style={imgStyle} src={props.photo} />
                        </MediaQuery>
                    </div>
                }
            </div>

            <div className="events-date-container">
                <p style={dateText}>{props.date}</p>
                {props.video ? <img src={Camera} style={cameraStyle} /> : null }
            </div>
            
            <div className="events-description-container">
                <h3 style={{marginTop:'0', marginBottom:'0'}}>{props.title}</h3>
                <p style={{color:'#27CCE1', fontSize:'12px', fontWeight:'600'}}>{props.subtitle}</p>
                <span style={{fontSize:'12px', fontWeight:'600'}}>By {props.author}</span>
                <p style={descriptionStyle}>
                    {props.description}
                </p>
                {
                    props.link ? 
                    <a target="_blank" rel="noopener noreferrer" href={props.link}>
                        <button style={recapButton}>
                            Recap
                        </button>
                    </a> : null
                }

            </div>
        </div>
    )
}

export default EventContainer;