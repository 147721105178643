import React from 'react'
import Subscribe from './Subscribe'
import { Link } from 'react-router-dom'
import { FaTwitter, FaLinkedin, FaGithub } from 'react-icons/fa'

import '../Styles/Footer.css'

const FooterResponsive = (props) => {
    const subscribeContainer = {
        width:'100vw',
        textAlign:'center',
        marginTop:'300px',
        marginBottom:'50px'
    }

    const footerLinkContainer = {
        width:'100vw',
        textAlign:'center',
        marginBottom:'20px',
    }

    const linkStyle = {
        color:'#FFFFFF',
        textDecoration:'none',
        fontSize:'16px',
        fontWeight:'600'
    }

    const addressText = {
        color:'#FFFFFF', 
        opacity:'.55',
        fontSize:'15px',
        lineHeight:'20px'
    }
    
      const iconStyle = {
        color:'white',
        fontSize:'24px',
        paddingRight:'5px',
        marginLeft:'25px'
      }

    return(
        <div className="footer-responsive">
            <div style={subscribeContainer}>
                <Subscribe/>
            </div>

            <div className="footer-responsive-links">
                <div style={footerLinkContainer}>
                    <Link style={linkStyle} to="/">Home</Link>
                </div>

                <div style={footerLinkContainer}>
                    <Link style={linkStyle} to="/plans">Plans</Link>
                </div>

                <div style={footerLinkContainer}>
                    <Link style={linkStyle} to="/about">About</Link>
                </div>

                <div style={footerLinkContainer}>
                    <Link style={linkStyle} to="/press">Press</Link>
                </div>

                <div style={footerLinkContainer}>
                    <a style={linkStyle} target="_blank" href="https://www.medium.com/7-chord" rel="noopener noreferrer">
                        Blog
                    </a>
                </div>

                <div style={footerLinkContainer}>
                    <Link style={linkStyle} to="/events">Events</Link>
                </div>


                <div style={footerLinkContainer}>
                    <Link style={linkStyle} to="/terms">Terms</Link>
                </div>

                <div style={footerLinkContainer}>
                    <Link style={linkStyle} to="/privacy">Privacy</Link>
                </div>
            </div>

            <div style={{...footerLinkContainer, marginTop:'40px'}}>
                <span style={addressText}>
                    85 Broad Street, 17th Floor<br/>
                    New York, NY 10004<br/>
                    info@7-chord.com
                </span>
            </div>

            <div style={{...footerLinkContainer, marginTop:'35px'}}>
                    <a href="https://www.linkedin.com/company/7-chord/" target="_blank" rel="noopener noreferrer">
                        <FaLinkedin style={iconStyle}/>
                    </a>

                    <a href="https://twitter.com/7chordbd" target="_blank" rel="noopener noreferrer">
                      <FaTwitter style={iconStyle}/>
                    </a>

                    <a href="https://github.com/7chord" target="_blank" rel="noopener noreferrer">
                        <FaGithub style={iconStyle}/>
                    </a>

                    <a 
                        style={{textDecoration:'none'}}
                        href="https://www.crunchbase.com/organization/7-chord" 
                        target="_blank" rel="noopener noreferrer" >
                        <i style={iconStyle} className="socicon-crunchbase"></i>
                    </a>
            </div>
            <div style={{...footerLinkContainer, marginTop:'25px'}}>
                <span style={{fontWeight:'400', paddingTop:'65px', ...addressText, textAlign:'center'}}>
                @7 Chord, Inc. All Rights Reserved
                </span>
            </div>


        </div>
    )
}

export default FooterResponsive;