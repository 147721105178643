import React from 'react'
import { Spring } from 'react-spring'
import MediaQuery from 'react-responsive'

const Wrapper = (props) => {
  const toProp = {
    description:props.description,
    opacity:1,
    color:'white',
    width:'60%',
    position:'absolute',
    top:'50%',
    transform:'translateY(-50%)',
    left:'0',
    right:'0',
    margin:'0 auto',
    extra: props.extra
  }
  const formattedText = props.description.split('\n')
  const formattedSpan = formattedText.map(line =>{
    return(
      <div>
        <span>{line}<br/></span>
      </div>
    )
  }
  )

  return(
      <div>
          <Spring
            config={{duration:500}}
            from={{description:"", opacity:0}}
            to={toProp}
          >
          {
            props =>
            <div style={{...props, fontSize:'16px'}}>
              {formattedSpan}
            </div>
          }
          </Spring>
      </div>
  )
}

export default Wrapper;
