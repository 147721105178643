import React from 'react'
import Product from './Product'

import Hero from '../Shared/Hero'
import Styles from '../Shared/Styles'
import MediaQuery from 'react-responsive'
import Assetman from '../../images/Home/Assetman.jpg'
import Dealer from '../../images/Home/dealer.jpg'
import Hedge from '../../images/Home/hedgefund.jpg'
import Regional from '../../images/Home/regionalbank.jpg'
import '../../views/Styles/Home/Home.css'


export default class Section5 extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      expanded:false
    }
  }

  updateExpandedState = (expanded) => {
    const updatedState = {expanded:expanded}
    this.setState(updatedState)
  }

  render(){

    const productContainerStyling = {
          position:'absolute',
          top:'50%',
          transform:'translateY(-50%)',
          left:'0',
          right:'0',
          margin:'0 auto',
          color:`${Styles.redText}`,
          width:'100%'
    }

    const productTextStyling = {
        width:'450px',
        textAlign:'center',
        margin:'0 auto',
        color:'#261A21',
        fontSize:'16px'
    }

    const productTextStylingMobile = {
      ...productTextStyling,
      width:'300px',
      marginTop:'40px'
    }

    return(
          <div>
            <MediaQuery minWidth={1000}>
              <div className="product-flex-container">
                <Product
                  updateExpandedState={this.updateExpandedState}
                  caption="ASSET MANAGER"
                  image={Assetman}
                  description={"Minimize transaction costs and tracking error by integrating BondDroid™ Auto-Pricer in your security selection process.\n\nIdentify executable relative value pre-trade and pre-order."}
                />
                <Product
                  updateExpandedState={this.updateExpandedState}
                  caption="FINANCIAL INTERMEDIARY"
                  image={Regional}
                  description={"You are great at building networks, but is your competitive advantage in predictive analytics? \n\nWhite-label BondDroid™ tools in your platform and monetize your data faster."}
                />
                <Product
                  updateExpandedState={this.updateExpandedState}
                  caption="ETF TRADER"
                  image={Hedge}
                  description={"Use BondDroid™ Auto-Pricer to track and predict ETF intrinsic value in real-time. Predict ETF NAVs and identify relative value.  "}
                />
                <Product
                  updateExpandedState={this.updateExpandedState}
                  caption="LIQUIDITY PROVIDER"
                  image={Dealer}
                  description={'Use BondDroid™ Auto-Quoter to launch and respond to inquiries, while systematically managing your risk. \n\nTriage bonds depending on their liquidity profile.'}
                />
              </div>
            </MediaQuery>
            <Hero
              height="200px"
              width="99vw"
              customStyle={{position:'relative'}}
            >
              {
                !this.state.expanded ?
                <div style={productContainerStyling}>
                  <MediaQuery maxWidth={500}>
                    <br/>
                    <p style={{...productTextStylingMobile, fontSize:'22px',fontFamily:`${Styles.avenirBold}`}}>
                      A Deployment Plan for Everyone
                    </p>
                    <br/>
                    <p style={{...productTextStyling, width:'300px', lineHeight:'24px', marginBottom:'20px'}}>
                      Our clients range from the most sophisticated institutions to new entrants beginning trading strategy design. 
                      Through our product innovation and client service, we bring the predictive power of AI to everyone in the bond market. 
                      We offer the following deployment plans:
                    </p>
                    <br/>
                  </MediaQuery>

                  <MediaQuery minWidth={501}>
                    <p style={{...productTextStyling, fontSize:'26px',fontFamily:`${Styles.avenirBold}`}}>
                      A Deployment Plan for Everyone
                    </p>
                    <br/>
                    <MediaQuery maxWidth={799}>
                      <p style={{...productTextStyling, width:'450px', lineHeight:'24px'}}>
                          Our clients range from the most sophisticated institutions to new entrants beginning trading strategy design. 
                          Through our product innovation and client service, we bring the predictive power of AI to everyone in the bond market. 
                          We offer the following deployment plans:
                      </p>
                    </MediaQuery>
                    <MediaQuery minWidth={800}>
                      <p style={{...productTextStyling, width:'750px', lineHeight:'24px'}}>
                        Our clients range from the most sophisticated institutions to new entrants beginning trading strategy design. 
                        Through our product innovation and client service, we bring the predictive power of AI to everyone in the bond market. 
                        We offer the following deployment plans:
                      </p>
                    </MediaQuery>

                  </MediaQuery>

                </div>
                : null
              }
            </Hero>
        </div>
    )
  }
}
