import React, { Component } from 'react';
import './App.css';
import Styles from './components/Shared/Styles'
import Home from './views/Home'
import Forms from './views/Forms'
import About from './views/About'
import Terms from './views/Terms'
import Privacy from './views/Privacy'
import Plans from './views/Plans'
import Press from './views/Press'
import Events from './views/Events'
import Support from './views/Support'
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom'
import 'normalize.css'
import 'whatwg-fetch'

import { startsWith } from 'core-js/es6/string'
import { entries } from 'core-js/es7/object'
import { find } from 'core-js/es6/array'
import { includes } from 'core-js/es7/array'
import { assign } from 'core-js/es6/object'
import { promise } from 'core-js/es6/promise'


const appStyle = {
  fontFamily:`${Styles.avenirRegular}`
}

class App extends Component {

  render() {
    return (
      <div
        className="App"
        style={appStyle}
      >
        <Router>
          <div>
            <div>
            </div>
              <Route exact path="/" component={Home} />
              <Route path="/terms" component={Terms} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/plans" component={Plans} />
              <Route path="/about" component={About} />
              <Route path="/press" component={Press} />
              <Route path="/events" component={Events} />
              <Route path="/forms/:formname" component={Forms} />
            </div>
        </Router>
      </div>
    );
  }
}

export default App;
