import React from 'react'
import { FaLinkedin } from 'react-icons/fa'
import { Spring } from 'react-spring'

const AboutCardDetail = (props) => {
    const toProps = {
        opacity:'1',
        memberName:props.name,
        title:props.title,
        bio:props.bio,
        linkedIn:props.linkedIn,
        linkedInStyle:{
            color:'white',
            fontSize:'24px',
            position:'absolute',
            bottom:'20px',
            right:'20px'
        },
        backgroundColor:'rgba(38,26,33, .82)',
        position:'absolute',
        top:'0',
        width:'100%',
        height:'100%',
    }
    return(
        <div>
            {props.display ? 
                <Spring
                    config={{duration:'500'}}
                    from={{opacity:'0'}}
                    to={toProps}
                >
                    {
                        props => 
                        <div style={props}>
                            <div style={{padding:'20px'}}>
                                <span style={{color:'#27CCE1'}}>{props.memberName}</span><br/>
                                    <span style={{color:'white'}}>{props.title}</span>
                                    <p style={{color:'white'}}>
                                        {props.bio}
                                    </p>
                                    <br/>
                                    <a target="_blank" rel="noopener noreferrer" href={props.linkedIn}>
                                        <FaLinkedin style={props.linkedInStyle}/>
                                    </a>
                            </div>
                        </div>
                    }
                </Spring> : null
        }
        </div>


    )
}

export default AboutCardDetail;