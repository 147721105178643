import React from 'react'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { Link } from 'react-router-dom'

const Expanded = (props) => {

    const linksContainer = {
        position:'relative',
        left:'10vw',
        top:'67px'
    }

    const linkStyle = {
        color:'#ffffff',
        textDecoration:'none',
        display:'block',
        marginBottom:'20px',
        fontSize:'24px'
    }

    const expandedStyle = {
        backgroundColor:'#261A21',
        position:'absolute',
        height:'100vh',
        width:'100vw',
        zIndex:'4'
    }

    const exitIcon = {
        fontSize:'48px',
        color:'#ffffff',
        position:'relative',
        left:'calc(100vw - 90px)',
        top:'15px',
        cursor:'pointer'
    }

    return(
            <div style={expandedStyle}>
                <IoIosCloseCircleOutline style={exitIcon} onClick={props.toggle}/>
                <div style={linksContainer}>
                    <Link style={linkStyle} to="/">Home</Link>
                    <Link style={linkStyle} to="/plans">Plans</Link>
                    <Link style={linkStyle} to="/about">About</Link>
                    <Link style={linkStyle} to="/press">Press</Link>
                    <a target="_blank" href="https://medium.com/7-chord" style={linkStyle}>Blog</a>
                    <Link style={linkStyle} to="/events">Events</Link>
                    <Link style={linkStyle} to="/privacy">Privacy</Link>
                    <Link style={linkStyle} to="/terms">Terms</Link>
                </div>

            </div> 

    )
}

export default Expanded;