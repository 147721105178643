import React from 'react'
import ContactStyles from '../Shared/Forms/ContactStyles'
import './Styles/Plans.css'

export default class AssetClasses extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      selected:{
        'High Yield':false,
        'Muni':false,
        'Mortgage PRoducts and Real Estate':false,
        'EM':false,
        'European Corporates':false,
        'Canadian Corporates':false,
        'BondDroid™ Auto-Pricer':false,
        'BondDroid™ Alpha':false,
        'BondDroid™ Auto-Quoter':false,
      }
    }
  }

  handleInputChange = (evt) => {
    let selectedInputs = Object.assign({}, this.state.selected)
    selectedInputs[evt.target.name] = !this.state.selected[evt.target.name]
    this.setState({selected:selectedInputs}, () => this.props.updateParentState(this.state.selected))
  }

  render(){
    const leftColumn = [
      'High Yield',
      'Muni',
      'Mortgage Products and Real Estate'
    ]

    const rightColumn = [
      'EM',
      'European Corporates',
      'Canadian Corporates'
    ]

    const apps = [
      'BondDroid™ Auto-Pricer',
      'BondDroid™ Alpha',
      'BondDroid™ Auto-Quoter'
    ]

    const leftCheckboxes = leftColumn.map((selection) => {
      return(
        <label style={{marginBottom:'5px'}} key={selection}>
          <input
            name={selection}
            style={ContactStyles.roundCheckboxStyle}
            className="round-checkbox"
            type="checkbox"
            onChange={this.handleInputChange}
          />
          {selection}
        </label>
      )
    })

    const rightCheckboxes = rightColumn.map((selection) => {
      return(
        <label style={{marginBottom:'5px'}} key={selection}>
          <input
            name={selection}
            style={ContactStyles.roundCheckboxStyle}
            className="round-checkbox"
            type="checkbox"
            onChange={this.handleInputChange}
          />
          {selection}
        </label>
      )
    })

    const appCheckBoxes = apps.map((selection) => {
      return(
        <label style={{marginBottom:'5px'}} key={selection}>
          <input
            name={selection}
            style={ContactStyles.roundCheckboxStyle}
            className="round-checkbox"
            type="checkbox"
            onChange={this.handleInputChange}
          />
            {selection}
        </label>

      )
    })

    return(
      <div>
          <span className="asset-heading">WHAT ASSET CLASSES CAN BE MOST HELPED WITH AI?</span>
          <div className="asset-classes-row-container">

            <div className="asset-classes-column-container">
              {leftCheckboxes}
            </div>

            <div className="asset-classes-column-container">
              {rightCheckboxes}
            </div>
          </div>
          <br/>
          <span className="asset-heading">WHAT BONDDROID™ APPS WOULD YOU BUY?</span>
            <div className="asset-classes-row-container">
              <div className="asset-classes-column-container">
                {appCheckBoxes}
              </div>
            </div>
      </div>
    )
  }
}
