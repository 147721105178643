import React from 'react'
import ContactExpandedIcon from '../../../images/Shared/ContactExpandedOptimized.png'
import Styles from '../Styles'
import { Spring } from 'react-spring'

const ContactExpanded = (props) => {
  const expandedContainerStyle = {
    height:'300px',
    width:'275px',
    backgroundColor:`${Styles.backgroundVeryDark}`
  }

  const expandedContainerIcon = {
    position:'relative',
    height:'70px',
    width:'70px',
    left:'15px',
    display:'block'
  }

  const contactUsTextContainer = {
    width:'90%',
    color:'white',
    margin:'0 auto',
    borderBottom:'3px solid white',
    fontFamily:`${Styles.avenirBold}`,
    paddingBottom:'5px'
  }

  const infoContainer = {
    color:'#D8D8D8',
    fontSize:'12px',
    margin:'10px auto 0 auto',
    width:'90%',
    lineHeight:'20px'
  }

  const emailStyle = {
    fontFamily:`${Styles.avenirBold}`,
    color:'white',
    textDecoration:'none'
  }

  props.view !== 'home' ? expandedContainerIcon.top = '0px' : expandedContainerIcon.top = '10px'


  return(
    <div 
      onMouseLeave={props.handleClick}
      onClick={props.handleClick}
    >
      <Spring
        from={{ transform: 'translateY(-100px)' }}
        to={{ transform: 'translateY(0)' }}
      >
      {
        props =>
         <div style={{...props, ...expandedContainerStyle}}>
            <img
              src={ContactExpandedIcon}
              style={expandedContainerIcon}
              alt="Click to exit contact information"
            />
            <br/>
            <div style={contactUsTextContainer}>
              Contact Us
            </div>

          <div style={infoContainer}>
            85 Broad Street, 17th Floor<br/>
            New York, NY 10004

            <br/><br/><br/>
            Product:&nbsp;<a style={emailStyle} href="mailto:sales@7-chord.com">sales@7-chord.com</a><br/>
            General:&nbsp;<a style={emailStyle} href="mailto:info@7-chord.com">info@7-chord.com</a>
         </div>
          </div>
      }

      </Spring>

    </div>
  )
}

export default ContactExpanded;
