import React from 'react'
import Hero from '../components/Shared/Hero'
import styled from 'styled-components'
import {
    Box,
    Flex
} from 'rebass'
import Navbar from '../components/Shared/Navbar/index'
import ToggleContainer from '../components/Support/ToggleContainer'
import Content from '../components/Support/Content'
import InitialContent from '../components/Support/InitialContent'
import UniverseContent from '../components/Support/UniverseContent'
import ContactContent from '../components/Support/ContactContent'
import DataOrgContent from '../components/Support/DataOrgContent'
import DisplayContent from '../components/Support/DisplayContent'
import ExcelContent from '../components/Support/ExcelContent'
import FixContent from '../components/Support/FixContent'
import FtpContent from '../components/Support/FtpContent'
import MediaQuery from 'react-responsive'
import Footer from '../components/Shared/Footer/index'
import Issuers from './issuers.csv'
import FTP from './FTP.pdf'
import Fix from './Fix.pdf'

import { saveAs } from 'file-saver'
import './Developers/Developers.css'

export default class Resources extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            show:'Getting Started',
        }
    }

    componentDidMount(){
        window.scrollTo(0,0)
    }

    updateShow = (e) => {
        const show = e.target.name
        this.setState({show})
    }

    downloadUniverse = () => saveAs(Issuers, "Issuers.csv")
    downloadFTP = () => saveAs(FTP, "7Chord FTP Instructions.pdf")
    downloadFix = () => saveAs(Fix, "7Chord Fix Documentation.pdf")

    render(){

        return(
            <div style={{color:'#262A21'}}>
                <Navbar support={true}/>
                <Hero
                    width="100vw"
                    height="30vh"
                    customStyle={{backgroundSize:'100% 100%', marginTop:'15px'}}
                    overlay="38, 26, 33, 1"
                />

                <MediaQuery maxWidth={1299}>
                    <Flex>
                            <Box
                                pt={4}
                                pl={2}
                                pr={4}
                                width={3/10}
                            >
                                <ToggleContainer 
                                    updateShow={this.updateShow}
                                    show={this.state.show}
                                />
                            </Box>
                            <Box
                                width={7/10}
                                pr={7}
                                pt={4}
                            >   
                            {this.state.show === "Getting Started" ?
                                <Content><InitialContent/></Content> : null
                            }

                            {this.state.show === "Universe" ?
                                <Content>
                                    <UniverseContent
                                        downloadUniverse={this.downloadUniverse} 
                                    />
                                </Content> : null
                            }

                            {this.state.show === "Data Organization" ?
                                <Content><DataOrgContent/></Content> : null
                            }
                            
                            {this.state.show === "FTP" ?
                                <Content><FtpContent downloadFtp={this.downloadFTP}/></Content> : null
                            }
                            
                            {this.state.show === "FIX API" ?
                                <Content><FixContent downloadFixDocs={this.downloadFix}/></Content> : null
                            }
                            
                            {this.state.show === "Excel API" ?
                                <Content><ExcelContent/></Content> : null
                            }


                            {this.state.show === "Display" ?
                                <Content><DisplayContent/></Content> : null
                            }


                            {this.state.show === "Get in Touch" ?
                                <Content><ContactContent/></Content> : null
                            }
                            

                            </Box>
                        </Flex>
                
                </MediaQuery>


                <MediaQuery minWidth={1300}>
                <Flex>
                        <Box
                            pt={4}
                            pl={6}
                            pr={4}
                            width={3/10}
                        >
                            <ToggleContainer 
                                updateShow={this.updateShow}
                                show={this.state.show}
                            />
                        </Box>
                        <Box
                            width={7/10}
                            pr={7}
                            pt={4}
                        >   
                        {this.state.show === "Getting Started" ?
                            <Content><InitialContent/></Content> : null
                        }

                        {this.state.show === "Universe" ?
                            <Content>
                                <UniverseContent
                                    downloadUniverse={this.downloadUniverse} 
                                />
                            </Content> : null
                        }

                        {this.state.show === "Data Organization" ?
                            <Content><DataOrgContent/></Content> : null
                        }
                        
                        {this.state.show === "FTP" ?
                            <Content><FtpContent downloadFtp={this.downloadFTP}/></Content> : null
                        }
                        
                        {this.state.show === "FIX API" ?
                            <Content><FixContent downloadFixDocs={this.downloadFix}/></Content> : null
                        }
                        
                        {this.state.show === "Excel API" ?
                            <Content><ExcelContent/></Content> : null
                        }


                        {this.state.show === "Display" ?
                            <Content><DisplayContent/></Content> : null
                        }


                        {this.state.show === "Get in Touch" ?
                            <Content><ContactContent/></Content> : null
                        }
                        

                        </Box>
                    </Flex>
                </MediaQuery>
                <Footer/>
            </div>
        )
    }
}