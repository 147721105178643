import React from 'react'
import { Box, Flex } from 'rebass'
import styled from 'styled-components'

const Container = styled(Box)({
    borderBottom:'1px solid rgba(216,216,216,.5)',
    margin:'0 auto',
    width:'858px',
    paddingBottom:'15px'
})

const ContainerSmall = styled(Box)({
    ...Container,
    width:'100%'
})

const SubRowContainer = styled(Flex)({
    height:'20px',
    textAlign:'center',
})

SubRowContainer.defaultProps = {
    alignItems:"center",
    justifyContent:"center",
    width:2/10
}

const SubRowSmallContainer = styled(Flex)({
    ...SubRowContainer
})

SubRowSmallContainer.defaultProps = {
    alignItems:'center',
    justifyContent:"center",
    width:5/10
}

const title = {
    display:'block',
    padding:'0'
}

const titleSmall = {
    display:'block',
    paddingLeft:'10px'
}

const subTitle = {
    display:'block',
    paddingLeft:'25px'
}

const PlanRow = (props) => {
    return(
        <Container>
            {props.children}
        </Container>
    )
}

const PlanRowSmall = (props) => {
    return(
        <ContainerSmall>
            {props.children}
        </ContainerSmall>
    )
}

const SubRow = (props) => {
    return(
        <Flex my={3}>
            <Flex alignItems="center" width={4/10}>
                <span style={props.title ? title : subTitle }>{props.col1}</span>
            </Flex>

            {
                props.show2 ? <SubRowContainer>{props.col2}</SubRowContainer> : null
            }

            {
                props.show3 ? <SubRowContainer>{props.col3}</SubRowContainer> : null
            }

            {
                props.show4 ? <SubRowContainer>{props.col4}</SubRowContainer> : null
 
            }
        </Flex>
    )
}

const SubRowSmall = (props) => {
    return(
        <Flex my={3}>
            <Flex alignItems="center" width={5/10}>
                <span style={props.title ? titleSmall : subTitle }>{props.col1}</span>
            </Flex>

            {
                props.show2 ? <SubRowSmallContainer>{props.col2}</SubRowSmallContainer> : null
            }

            {
                props.show3 ? <SubRowSmallContainer>{props.col3}</SubRowSmallContainer> : null
            }

            {
                props.show4 ? <SubRowSmallContainer>{props.col4}</SubRowSmallContainer> : null
 
            }
        </Flex>
    )
}

export { PlanRow, PlanRowSmall, SubRow, SubRowSmall }