import React from 'react'
import Footer from '../components/Forms/Footer'
import { ContactForm } from '../components/Shared/Forms/ContactForm'
import FormText from '../components/Home/FormText'
import AssetClasses from '../components/Plans/AssetClasses'
import MediaQuery from 'react-responsive'
import ContactStyles from '../components/Shared/Forms/ContactStyles'
import { Redirect } from 'react-router-dom'

export default class Forms extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            pageYOffset:undefined,
            form:this.props.match.params['formname'],
            message:'',
            selected:[],
            submitted:false
        }
    }

    componentDidMount(){
        const pageYOffset = window.innerHeight
        this.setState({...this.state, pageYOffset})
    }

    redirectAfterSubmission = () => {
        setTimeout(() => this.setState({...this.state, submitted:true}), 1500)
    }

    updateMessageState = (message) => {
        this.setState({...this.state, message:message})
    }

    updateSelectedState = (selected) => {
        const objectsKeys = Object.keys(selected)
        const selectedKeys = objectsKeys.filter(key => selected[key] === true)
        this.setState({...this.state, selected:selectedKeys})
    }

    render(){
        const formPosition = {
            position:'relative',
            left:'0',
            right:'0',
            margin:'0 auto'
        }

        const freeTrialForm = {
            formTitle:'Get Started with BondDroid™ Auto-Pricer for Free',
            subHeading:"Get the first 3-months FREE when you enter into a 2-year contract. If you don’t love it, cancel within the first 3 months. Thank you for an opportunity to earn your trust.",
            subject:"Get Started with BondDroid™ Pro for Free",
            extraField:this.state.message,
            positioning:formPosition,
            subscribe:true,
            redirect:this.redirectAfterSubmission
        }

        const waitListForm = {
            formTitle:'JOIN OUR WAITLIST',
            subHeading:[[`We have big plans for 2019. Tell us which of our upcoming 
            product launches you are most excited about and become 
            a proof-of-concept partner.  Or just email us at `],
            [<strong><i>sales@7-chord.com </i></strong>]],
            subject:'Waitlist Signup: What Asset Classes Interest You?',
            extraField:this.state.selected,
            positioning:formPosition,
            subscribe:false,
            partnership:true,
            redirect:this.redirectAfterSubmission
        }

        let form;
        if (this.state.form === 'trial') {
            form = <ContactForm {...freeTrialForm} >
                        <MediaQuery maxWidth={699}>
                            <FormText
                                styling={ContactStyles.textStyleMobile}
                                updateParentState={this.updateMessageState}
                            />
                        </MediaQuery>
                        <MediaQuery minWidth={700}>
                            <FormText
                                styling={ContactStyles.textStyle}
                                updateParentState={this.updateMessageState}
                            />
                        </MediaQuery>
                    </ContactForm>  
        }

        if (this.state.form === 'waitlist') {
            form = <ContactForm {...waitListForm}>
                        <AssetClasses
                            updateParentState={this.updateSelectedState}
                        />
                    </ContactForm>
        }

        return(
            <div style={{marginTop:'40px'}}>
                {this.state.submitted ? <Redirect to="/" /> : null}
                {form}
                <Footer/>
            </div>
        )
    }
} 
