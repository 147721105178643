import React from 'react'
import EventContainer from '../components/Events/EventContainer'
import Hero from '../components/Shared/Hero'
import HeroS from '../images/Events/eventsM.jpg'
import HeroM from '../images/Events/eventsM.jpg'
import HeroL from '../images/Events/events.jpg' 
import HeroXL from '../images/Events/events.jpg'
import Navbar from '../components/Shared/Navbar/index'
import Data from './Events/Data'
import Styles from '../components/Shared/Styles'
import SocialBar from '../components/Shared/SocialBar'
import MediaQuery from 'react-responsive'
import Footer from '../components/Shared/Footer/index'
import './Styles/Events/Events.css'



export default class Events extends React.Component {
    constructor(props){
        super(props)

    }

    componentDidMount(){
        window.scrollTo(0,0)
    }

    render(){
        const heroTextContainer = {
            position:'relative',
            bottom:'calc(65vh)',
            marginLeft:'20%',
            width:'20%',
            textAlign:'right',
            height:'100%'
        }

        const heroRegularText = {
            color:'#27CCE1',
            fontSize:'15px',
            fontWeight:'600',
        }

        const heroRegularTextMobile = {
            ...heroRegularText,
        }

        const heroBigText = {
            fontFamily: `${Styles.avenirBold}`,
            fontSize: '41px',
            color: '#FFFFFF',
            lineHeight: '44px',
        }

        const heroBigTextMobile = {
            ...heroBigText,
        }

        return(
            <div>
                <Navbar media={true}/>
                <Hero
                    height="65vh"
                    width="100vw"
                    imageS={HeroS}
                    imageM={HeroM}
                    imageL={HeroL}
                    imageXL={HeroXL}
                    customStyle={{marginTop:'15px'}}
                >
                    <div style={heroTextContainer}>
                        <div className="event-hero-container">
                            <MediaQuery maxWidth={599}>
                                <p style={heroRegularTextMobile}>EVENTS, VIDEOS, PODCASTS</p>
                                <h3 style={heroBigTextMobile}>hear from our team</h3>
                                <p style={heroRegularTextMobile}>for speaking engagements contact <br/>
                                <a href="mailto:press@7-chord.com" 
                                   style={{color:'white', textDecoration:'none'}}>press@7-chord.com
                                </a>                                       </p>
                            </MediaQuery>

                            <MediaQuery minWidth={600}>
                                <p style={heroRegularText}>EVENTS, VIDEOS, PODCASTS</p>
                                <h3 style={heroBigText}>hear from our team</h3>
                                <p style={heroRegularText}>for speaking engagements contact <br/>
                                <a href="mailto:press@7-chord.com" 
                                   style={{color:'white', textDecoration:'none'}}>press@7-chord.com
                                </a>       
                                </p>
                            </MediaQuery>
                        </div>
                    </div>
                    <MediaQuery minWidth={600}>
                        <SocialBar height='35vh'/>
                    </MediaQuery>
                </Hero>
                <div style={{marginTop:'50px'}}>
                    <EventContainer {...Data.row12} />
                    <EventContainer {...Data.row1}/>
                    <EventContainer {...Data.row6}/>
                    <EventContainer {...Data.row2}/>
                    <EventContainer {...Data.row3}/>
                    <EventContainer {...Data.row4}/>
                    <EventContainer {...Data.row5}/>
                    <EventContainer {...Data.row7}/>
                    <EventContainer {...Data.row10}/>
                    <EventContainer {...Data.row11}/>
                </div>
                <Footer/>
            </div>
        )
    }
}
