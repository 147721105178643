import React from 'react'
import Section1 from '../components/Home/Section1'
import Section3 from '../components/Home/Section3'
import Section4 from '../components/Home/Section4'
import Section5 from '../components/Home/Section5'
import Section6 from '../components/Home/Section6'
import Footer from '../components/Shared/Footer/index'
import './Styles/Home/Home.css'


export default class Home extends React.Component {

  componentDidMount(){
    window.scrollTo(0,0)
  }

  render(){
    return(
      <div>
        <Section1/>
        <Section3/>
        <Section4/>
        <Section5/>
        <Section6/>
        <Footer/>
      </div>
    )
  }
}