import React from 'react'
import { TwitterShareButton, TwitterIcon } from 'react-share'
import MediaQuery from 'react-responsive'
import './Styles/Press.css'

const PressItem = (props) => {
    const titleStyle = {
        fontSize:'20px',
        lineHeight:'24px',
        marginTop:'0',
        fontWeight:'600'
    }


    const subheadingStyle = {
        fontSize: '12px',
        color: '#27CCE1',
        fontWeight:'600'
    }

    const imageStyle = {
        width:'190px',
        height:'60px'
    }

    const imageStyleSmall = {
        ...imageStyle,
        display:'block',
        margin:'0 auto',
        marginBottom:'20px'
    }

    const buttonStyle = {
        color:'#261A21',
        border:'1px solid #261A21',
        borderRadius:'17px',
        padding:'9px 20px',
        fontSize:'15px',
        fontWeight:'600',
        textAlign:'center',
        outline:'0',
        cursor:'pointer',
        backgroundColor:'white'
    }

    const descriptionStyle = {
        lineHeight:'24px',
        color:'rgb(38, 26, 33)'
    }


    return(
        <div className="press-item-container">
            <div style={{marginRight:'50px'}}>
                <MediaQuery maxWidth={1000}>
                    <img style={imageStyleSmall} src={props.image} alt="Press Event" />
                </MediaQuery>   

                <MediaQuery minWidth={1001}>
                    <img style={imageStyle} src={props.image} />
                </MediaQuery>
            </div>
            <div>
                <p style={titleStyle}>{props.title}</p>
                <p style={subheadingStyle}>{props.subheading} - {props.date}</p>
                <p style={descriptionStyle}>{props.description}</p>
                <div className="social-container">
                    <MediaQuery maxWidth={1000}>
                        <a href={props.link} target="_blank" rel="noopener noreferrer">
                                <button style={buttonStyle}>
                                    Read More
                                </button>
                            </a>
                            <div style={{marginTop:'-7px'}} className="twitter-custom-button">
                            <TwitterShareButton 
                                url={'https://7-chord.com/press'}
                                title={props.description}>
                                <div className="twitter-container" style={{color:'#1b95e0', cursor:'pointer'}}>
                                    <TwitterIcon 
                                        size={48} 
                                        logoFillColor={'#1b95e0'} 
                                        round
                                        iconBgStyle={{fill:'white'}}/>
                                        Tweet This
                                </div>
                            </TwitterShareButton>
                            </div>
                    </MediaQuery>

                    <MediaQuery minWidth={1001}>
                        <a href={props.link} target="_blank" rel="noopener noreferrer">
                            <button style={buttonStyle}>
                                Read More
                            </button>
                        </a>
                        <div style={{marginLeft:'40px', marginTop:'-7px'}}>
                            <TwitterShareButton 
                                url={'https://7-chord.com/press'}
                                title={props.description}>
                                <div className="twitter-container" style={{color:'#1b95e0', cursor:'pointer'}}>
                                    <TwitterIcon 
                                        size={48} 
                                        logoFillColor={'#1b95e0'} 
                                        round
                                        iconBgStyle={{fill:'white'}}/>
                                        Tweet This
                                </div>
                            </TwitterShareButton>
                        </div>
                    </MediaQuery>

                </div>


            </div>
        </div>
    )
}

export default PressItem;