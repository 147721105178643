import Styles from '../../Shared/Styles'

const formContainer = {
  zIndex:'3',
  width:'700px',
  backgroundColor:`#fff`,
  borderRadius:'4px',
  boxShadow:'0 0 1px 1px rgb(244, 244, 244)',
  marginTop:'1em'
}

const formContainerMobile = {
  ...formContainer,
  width:'100vw'
}

const formStyle = {
  display:'block',
  width:'600px',
  color:`${Styles.redText}`,
  paddingLeft:'50px',
  paddingTop:'2vh'
}

const formHeadingStyle = {
  color:`${Styles.blueText}`,
  position:'absolute',
  left:'50px',
}

const formHeadingStyleMobile = {
  ...formHeadingStyle,
  fontSize:'20px',
  width:'80vw',
  left:'0',
  right:'0',
  margin:'0 auto',
  top:'35px'
}

const subheadingStyle = {
  paddingTop:'80px',
  width:'550px',
  paddingLeft:'50px',
  marginBottom:'.5em'
}

const subheadingStyleMobile = {
  paddingTop:'80px',
  width:'80vw',
  margin:'20px auto'
}

const inputStyle = {
  height:'18px',
  width:'525px',
  color:`${Styles.redText}`,
  padding:'10px',
  marginBottom:'2.3em',
  marginTop:'.2em',
  backgroundColor:`#fff`,
  outline:'0',
  fontSize:'14px',
  border:`1px solid rgba(56, 38, 48, .2)`,
}

const inputStyleMobile = {
  ...inputStyle,
  width:'80vw',
  display:'block',
  margin:'0 auto'
}

const squareCheckboxStyle = {
  appearance:'none',
  outline:'0',
  position:'relative',
  top:'.24em',
  marginRight:'.5em'
}

const roundCheckboxStyle = {
  appearance:'none',
  outline:'0',
  position:'relative',
  top:'.2em',
  marginRight:'.5em'
}

const nameContainer = {
  display:'inline-block',
  marginRight:'50px'
}

const nameContainerMobile = {

}

const nameInputStyle = {
  ...inputStyle,
  width:'225px',
}

const nameInputStyleMobile = {
  ...inputStyle,
  display:'block',
  margin:'0 auto',
  width:'80vw'
}

const textStyle = {
  backgroundColor:`#fff`,
  color:`${Styles.redText}`,
  outline:'0',
  fontSize:'16px',
  padding:'10px',
  width:'525px',
  height:'15vh',
  border:`1px solid rgba(56, 38, 48, .2)`,
  fontFamily:`${Styles.avenirRegular}`
}

const textStyleMobile = {
  ...textStyle,
  width:'80vw',
  display:'block',
  margin:'0 auto'
}

const submitButton = {
  backgroundColor:`${Styles.blueText}`,
  padding:'10px 30px',
  outline:'0',
  color:'white',
  borderRadius:'2px',
  border:`1px solid ${Styles.blueText}` ,
  marginRight:'10px',
  fontSize:'18px',
  marginBottom:'10px'
}

const exitIcon = {
  fontSize:'36px',
  position:'absolute',
  color:`${Styles.redText}`,
  right:'10px',
  top:'10px',
  cursor:'pointer'
}

const exitIconMobile = {
  ...exitIcon,
  display:'block'
}

const asteriskIcon = {
  fontSize:'8px', 
  position:'relative', 
  bottom:'5px',
  left:'5px'
}

const labelStyle = {
  marginLeft:'5px'
}

const labelStyleMobile = {
  marginLeft:'10vw',
}

const checkboxMobile = {
  display:'block',
  marginLeft:'8vw'
}

const submitButtonMobile = {
...submitButton,
marginLeft:'8vw'
}

const ContactStyles = {
  formContainer:formContainer,
  formContainerMobile:formContainerMobile,
  formStyle:formStyle,
  formHeadingStyle:formHeadingStyle,
  formHeadingStyleMobile:formHeadingStyleMobile,
  subheadingStyle:subheadingStyle,
  subheadingStyleMobile:subheadingStyleMobile,
  inputStyle:inputStyle,
  inputStyleMobile:inputStyleMobile,
  squareCheckboxStyle:squareCheckboxStyle,
  roundCheckboxStyle:roundCheckboxStyle,
  nameContainer:nameContainer,
  nameInputStyle:nameInputStyle,
  textStyle:textStyle,
  textStyleMobile:textStyleMobile,
  submitButton:submitButton,
  exitIcon:exitIcon,
  exitIconMobile:exitIconMobile,
  asterisk:asteriskIcon,
  labelStyle:labelStyle,
  labelStyleMobile:labelStyleMobile,
  nameContainerMobile:nameContainerMobile,
  nameInputStyleMobile:nameInputStyleMobile,
  checkboxMobile:checkboxMobile,
  submitButtonMobile:submitButtonMobile
}

export default ContactStyles;
