import React from 'react'

export default class Carousel extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            timer:null,
            current:0
        }
    }

    componentDidMount(){
        const timer = setInterval(this.nextItem, 6000)
        this.setState({...this.state, timer:timer})
    }

    componentWillUnmount() {
        clearInterval(this.state.timer);
    }

    nextItem = () => {
        // evt.preventDefault()
        if (this.state.current === this.props.items.length - 1 ) {
            this.setState({current:0})
        } else {
            const nextItem = this.state.current + 1
            this.setState({current:nextItem})
        }
    }

    render(){
        const carouselItems = this.props.items.map(item => {
            return(
                <div>
                    {item}
                </div>
            )
        })
        return(
            <div>
                {carouselItems[this.state.current]}
            </div>
        )
    }
}