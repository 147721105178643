import React from 'react'
import PlanType from '../components/Plans/PlanType'
import {PlanCompare, PlanCompareSmall} from '../components/Plans/PlanCompare'
import { Flex } from 'rebass'
import {
  FaAngleDoubleRight,
  FaAngleDoubleLeft
} from 'react-icons/fa'
import styled from 'styled-components'
import Quandl from '../images/Plans/quandl.jpg'
import Hero from '../components/Shared/Hero'
import HeroS from '../images/Plans/Planshero.jpg'
import HeroM from '../images/Plans/Planshero.jpg'
import HeroL from '../images/Plans/Planshero.jpg'
import HeroXL from '../images/Plans/Planshero.jpg'
import Navbar from '../components/Shared/Navbar/index'
import { ContactForm, ContactButton } from '../components/Shared/Forms/ContactForm'
import Styles from '../components/Shared/Styles'
import MediaQuery from 'react-responsive'
import Footer from '../components/Shared/Footer/index'
import FormText from '../components/Home/FormText'
import ContactStyles from '../components/Shared/Forms/ContactStyles'
import './Styles/Plans/Plans.css'

const HeroTextContainer = styled(Flex)({
  width:'100vw',
  position:'relative',
  bottom:'40vh',
  textAlign:'center'
})

HeroTextContainer.defaultProps = {
  justifyContent:'center',
  alignItems:'center'
}

const PlanTypes = (props) => {
  return(
    <div style={props.container}>
    <Flex
      width={'100vw'}
      justifyContent="center"
      flexWrap="wrap">
        <PlanType
          timeFrame="WEEKS" 
          planType="ENTERPRISE"
          price="CALL US"
          priceDetails="BILLED ANNUALLY FIRMWIDE LICENSE"
          deployment="BONDDROID LIVES BEHIND YOUR FIREWALL"
          details={[
            'Fully customizable predictive pricing system',
            <br/>,
            'Public, Vendor or your proprietary data',
            <br/>,
            'Standard APIs',
            '3 Custom APIs included']}
            demo={true}
            top={true}
            buttonText="Book a Demo"/>

        <MediaQuery maxWidth={629}>
          <PlanType
            timeFrame="DAYS" 
            planType="PRO"
            price="$4,500"
            priceDetails="PER MONTH, BILLED ANNUALLY DESK LICENSE"
            deployment="BONDDROID LIVES ON OUR CLOUD"
            details={[
              `Not ready to manage BondDroid in house?  We will maintain it for you`,
              <br/>,
              'Curated public and vendor data',
              <br/>,
              'Ask us about an exclusive version powered by your unique dataset']}
            buttonText="Try for Free"
            handleClick={props.handleClick}/>
        </MediaQuery>

        <MediaQuery minWidth={630}>
          <PlanType
            timeFrame="DAYS" 
            planType="PRO"
            price="$4,500"
            priceDetails="PER MONTH, BILLED ANNUALLY DESK LICENSE"
            deployment="BONDDROID LIVES ON OUR CLOUD"
            details={[
              `Not ready to manage BondDroid in house?  We will maintain it for you`,
              <br/>,
              'Curated public and vendor data',
              <br/>,
              'Ask us about an exclusive version powered by your unique dataset']}
            buttonText="Try for Free"
            top={true}
            handleClick={props.handleClick}/>
        </MediaQuery>

        <MediaQuery maxWidth={944}>
          <PlanType
            timeFrame="HOURS" 
            planType="DATA"
            price={<img src={Quandl} />}
            priceDetails={<span>AVAILABLE ON <a style={{color:'rgba(243,87,56,0.90)', textDecoration:'none'}} href="https://www.quandl.com/databases/BD/data" target="_blank" rel="noopener noreferrer">QUANDL.COM</a></span>}
            deployment="BONDDROID TICK DATA"
            details={[
              'BondDroid generated intraday levels are now available in a bulk data format through Quandl.',
              <br/>,
              'End-of-day tick data History back to 2012',
            ]}
            action="Download Sample"
            button="Download Sample"
            quandl={true}
            top={false}
            background="rgba(243,87,56,0.90)"/>
        </MediaQuery>

        <MediaQuery minWidth={945}>
          <PlanType
            timeFrame="HOURS" 
            planType="DATA"
            price={<img src={Quandl} />}
            priceDetails={<span>AVAILABLE ON <a style={{color:'rgba(243,87,56,0.90)', textDecoration:'none'}} href="https://www.quandl.com/databases/BD/data" target="_blank" rel="noopener noreferrer">QUANDL.COM</a></span>}
            deployment="BONDDROID TICK DATA"
            details={[
              'BondDroid generated intraday levels are now available in a bulk data format through Quandl.',
              <br/>,
              'End-of-day tick data History back to 2012',
            ]}
            action="Download Sample"
            button="Download Sample"
            quandl={true}
            top={true}
            background="rgba(243,87,56,0.90)"/>
        </MediaQuery>

    </Flex>
  </div>
  )
}

export default class Plans extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      formOpen:false,
      pageOffset:'80px',
      message:'',
      show:['col2', 'col3', 'col4']
    }
  }

  componentDidMount(){
    window.scrollTo(0,0)
  }


  updateParentState = (selectedObjects) => {
      const objectsKeys = Object.keys(selectedObjects)
      const selectedKeys = objectsKeys.filter(key => selectedObjects[key] === true)
      this.setState({...this.state, selected:selectedKeys}, () => console.log(this.state.selected))
  }

  updateMessageState = (message) => {
    this.setState({...this.state, message:message})
  }

  
  toggleForm = () => {
    this.setState((prevState, props) => {
      const formOpen = !prevState['formOpen']
      let pageOffset = prevState['pageOffset']
      if (window.pageYOffset > 80)
        pageOffset = window.pageYOffset + 'px'

      return {...prevState, formOpen, pageOffset}
    })
  }

  togglePlans = evt => {
    const show = [...this.state.show]

    if (evt.target.name === 'next') {
      const last = show.shift()
      show.push(last)
    }
    else {
      const first = show.pop()
      show.unshift(first)
    }

    this.setState({...this.state, show})
  }
  
  openProForm = () => {
    const proFormOpen = !this.state.proFormOpen
    this.setState({...this.state, proFormOpen:proFormOpen})
  }



  render(){
      const container = {
        position:'relative',
        bottom:'220px'
      }

      const containerLarge = {
        position:'relative',
        bottom:'240px'
      }

      const heroText = {
        color:'#fff',
        width:'80%',
        position:'relative',
        bottom:'20px'
      }

      const heroTextSmall = {
        ...heroText,
        fontSize:'24px',
        position:'relative',
        bottom:'30px'
      }

      const buttons = {
          padding:'0',
          width:'178px',
          height:'30px',
          boxShadow:'0 5px 5px 0 rgba(38,26,33,0.17)',
          textAlign:'center',
          borderRadius:'15px',
          border:'1px solid #27CCE1',
          cursor:'pointer',
      }

      const toggleButtons = {
        color:'#261A21',
        backgroundColor:'#fff',
        border:'1px solid #fff',
        borderRadius:'50%',
        cursor:'pointer',
        fontSize:'18px',
        outline:'0'
      }

    

      const heading = {
        color:'#262A21', 
        fontFamily:`${Styles.avenirBold}`, 
        fontSize:'23px',
      }

    return(
      <div>
        {this.state.formOpen ?
          <ContactForm
            handleClick={this.toggleForm}
            formTitle={"Get Started with BondDroid™ Auto-Pricer for Free"}
            subHeading={"Get the first 3-months FREE when you enter into a 2-year contract. If you don’t love it, cancel within the first 3 months. Thank you for an opportunity to earn your trust. "}
            extraField={this.state.message}
            subject={"Get Started with BondDroid™ Pro for Free"}
            positioning={{position:'absolute', top:this.state.pageOffset, left:'0', right:'0', margin:'0 auto'}}
            subscribe={true}
          >              
            <FormText
              styling={ContactStyles.textStyle}
              updateParentState={this.updateMessageState}
              formValue={this.state.message}
            /></ContactForm> : null
          }
          <Navbar/>
          <Hero
            height="50vh"
            width="100vw"
            imageS={HeroS}
            imageM={HeroM}
            imageL={HeroL}
            imageXL={HeroXL}
            customStyle={{marginTop:'15px'}}>
              <HeroTextContainer>
                <MediaQuery maxWidth={916}>
                  <h1 style={heroTextSmall}>
                    Intraday algorithmic bond prices on your screen in
                  </h1>
                </MediaQuery>

                <MediaQuery minWidth={917}>
                  <h1 style={heroText}>
                    Intraday algorithmic bond prices on your screen in
                  </h1> 
                </MediaQuery>
              </HeroTextContainer>
          </Hero>
          
          <MediaQuery maxHeight={725}>
            <PlanTypes
              container={container}
              handleClick={this.toggleForm}/>          
          </MediaQuery>

          <MediaQuery minHeight={726}>
            <PlanTypes
              container={containerLarge}
              handleClick={this.toggleForm}/>          
          </MediaQuery>


          <div style={{position:'relative', bottom:'150px'}}>
            <Flex
              flexDirection="column"
              width={1/1}
              alignItems="center"
              justifyContent="center">
                <h2 style={heading}>Compare Features</h2>

                <MediaQuery maxWidth={949}>
                  <div>
                    <button style={toggleButtons} name="next" onClick={this.togglePlans}>
                      <FaAngleDoubleLeft/>
                    </button>
                    <button style={toggleButtons} name="previous" onClick={this.togglePlans}>
                      <FaAngleDoubleRight/>
                    </button>
                  </div>
                </MediaQuery>

            </Flex>
          </div>

          <MediaQuery maxWidth={949}>
            <PlanCompareSmall
              show={this.state.show[0]}
              togglePlans={this.togglePlans}/>
          </MediaQuery>
          
          <MediaQuery minWidth={950}>
            <PlanCompare/>
          </MediaQuery>

          <MediaQuery maxWidth={950}>
            <div>
              <Flex
                width={1/1}
                alignItems="center"
                flexDirection="column"
                justifyContent="center">
                  <h2 style={{...heading, marginBottom:'35px'}}>Questions?</h2>
                  <ContactButton 
                    buttonText="Get in Touch"
                    custom={{...buttons, marginBottom:'20px'}}/>
              </Flex>
            </div>
          </MediaQuery>

          <MediaQuery minWidth={951}>
            <div style={{position:'relative', bottom:'100px'}}>
              <Flex
                width={1/1}
                alignItems="center"
                flexDirection="column"
                justifyContent="center">
                  <h2 style={{...heading, marginBottom:'35px'}}>Questions?</h2>
                  <ContactButton 
                    buttonText="Get in Touch"
                    custom={{...buttons, marginBottom:'20px'}}/>
              </Flex>
            </div>
          </MediaQuery>


          <Footer/>

          
      </div>
    )
  }
}


