import './Fonts/fonts.css'


const Styles = {
  backgroundOpaque:'rgba(56, 38, 48, 0.82)',
  backgroundDark:'rgba(56, 38, 48, 1)',
  backgroundVeryDark:'#261A21',
  whiteText:'white',
  redText:'#382630',
  blueText:'#27CCE1',
  beigeText:'#CDBFBF',
  errorText:'#FF6053',
  avenirRegular:'Avenir Next LT Pro Regular',
  avenirBoldCondensed:'Avenir Next LT Pro Bold Condensed',
  avenirMediumCondensed:'Avenir Next LT Pro Medium Condensed',
  avenirMediumCondensedItalic:'Avenir Next LT Pro Medium Condensed Italic',
  avenirProDemiCondensed:'Avenir Next LT Pro Demi Condensed',
  avenirDemi:'Avenir Next LT Pro Demi',
  avenirNextProCondensed:'Avenir Next LT Pro Condensed',
  avenirBold:'Avenir Next LT Pro Bold'

}

export default Styles;
