import React from 'react'

import Tickerbar from './Tickerbar'
import SocialBar from '../Shared/SocialBar'
import Navbar from '../Shared/Navbar/index'
import Drift from 'react-driftjs'
import Hero from '../Shared/Hero'
import Styles from '../Shared/Styles'
import FormText from './FormText'
import { ContactButton, ContactForm } from '../Shared/Forms/ContactForm'
import { Spring } from 'react-spring'
import MediaQuery from 'react-responsive'
import HeroS from '../../images/Home/homeS.jpg'
import HeroM from '../../images/Home/home.jpg'
import HeroL from '../../images/Home/home.jpg'
import HeroXL from '../../images/Home/home.jpg'
import ContactStyles from '../Shared/Forms/ContactStyles'
import '../../views/Styles/Home/Home.css'


export default class Section1 extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      tickers:{},
      formOpen: false,
      message:'',
      pageOffset:'80px'
    }
  }

  componentWillMount(){
    const params = {
      method:'POST',
      body:'key=eB2t35oLaEWAGKZI',
      headers: {
        "Content-type":"application/x-www-form-urlencoded; charset=UTF-8"
      },
    }
    fetch('/scrolling_tickers', params)
        .then(res => res.json())
        .then(data => {
          const tickers = Object.assign({}, data)
          this.setState({tickers})
        }
      )
        .catch(err => {
          console.log(`could not fetch scrolling cusips: ${err}`)
        })

  }

  toggleForm = () => {
    const formOpen = !this.state.formOpen
    let pageOffset = this.state.pageOffset
    if (window.pageYOffset > 80){
      pageOffset = window.pageYOffset + 'px'
    }
    console.log(pageOffset)
    this.setState({...this.state, formOpen, pageOffset})
  }

  updateMessageState = (message) => {
    this.setState({...this.state, message:message})
  }

  render(){
    const heroMainText = {
      fontFamily:`${Styles.avenirProDemiCondensed}, ${Styles.avenirBold}`,
      color:'white',
      textAlign:'right',
      letterSpacing:'-0.9px',
      lineHeight: '91px',
      marginRight:'6vw',
    }


    const heroMainTextMobile = {
      color:'white',
      fontFamily:`${Styles.avenirBold}`,
      letterSpacing:'-0.9px',
      lineHeight: '44px',
      fontSize:'80px',
      textAlign:'right',
    }

    const heroMainTextMobileSmall = {
      ...heroMainTextMobile, 
      fontSize:'60px', 
      lineHeight:'70px', 
      textAlign:'center', 
      margin: '30px 0'
    }


    const heroMainAnimationConfig = {
      delay:800,
      duration:1000,
    }

    const heroSubheadingText = {
      color:'white',
      textAlign:'left',
      fontFamily:`${Styles.avenirDemi}`,
      fontSize:'20px',
      lineHeight:'26px',
      width:'286px',
      marginBottom:'0',
      marginTop:'60px'
    }

    const heroSubheadingTextMobile = {
      ...heroSubheadingText,
      fontSize:'16px'
    }

    const heroSubheadingAnimationConfig = {
      delay:1800,
      duration:1000
    }

    const tickerAnimationConfig = {
      delay:2800,
      duration:500
    }

    const buttonStyle = {
      fontFamily:Styles.avenirDemi,
      fontSize:'16px',
      letterSpacing: '-0.41px',
      borderRadius:'100px',
      marginTop:'5vh',
      padding:'10px 30px'
    }


    return(
      <div>
        {this.state.formOpen ?
          <ContactForm
            handleClick={this.toggleForm}
            formTitle={"Get Started with BondDroid™ Auto-Pricer for Free"}
            subHeading={"Get the first 3-months FREE when you enter into a 2-year contract. If you don’t love it, cancel within the first 3 months. Thank you for an opportunity to earn your trust. "}
            extraField={this.state.message}
            subject={"Get Started with BondDroid™ Pro for Free"}
            positioning={{position:'absolute', top:this.state.pageOffset, left:'0', right:'0', margin:'0 auto'}}
            subscribe={true}
          >
            <MediaQuery maxWidth={699}>
              <FormText
                styling={ContactStyles.textStyleMobile}
                updateParentState={this.updateMessageState}
                formValue={this.state.message}
              />
            </MediaQuery>

            <MediaQuery minWidth={700}>
              <FormText
                styling={ContactStyles.textStyle}
                updateParentState={this.updateMessageState}
                formValue={this.state.message}
              />
            </MediaQuery>

          </ContactForm>
          : null}

        <div>
          
          <Navbar/>

          <Hero
            id="hero"
            height="calc(100vh - 80px)"
            width="100vw"
            imageS={HeroS}
            imageM={HeroM}
            imageL={HeroL}
            imageXL={HeroXL}
            customStyle={{position:'relative', top:'13px'}}
          >
            <div className="landing-flex-container">
              <div className="landing-flex-left-side">

                <div className="hero-text">
                    <Spring 
                      config={heroMainAnimationConfig}
                      from={{opacity:'0'}}
                      to={{opacity:'1'}}
                    >
                      { props =>                     
                        <div style={props}>
                          <MediaQuery maxWidth={599}>
                            <p style={heroMainTextMobileSmall}>
                                AI
                                <span style={{fontFamily:`${Styles.avenirMediumCondensedItalic}, ${Styles.avenirRegular}`, fontSize:'50px', display:'inline-block', lineHeight:'20px'}}>&nbsp; for</span><br/>
                                <span style={{color:'#27CCE1', display:'inline-block'}}>BOND</span><br/>
                                TRADERS
                            </p>
                          </MediaQuery>

                          <MediaQuery minWidth={600} maxWidth={650}>
                            <p style={{...heroMainTextMobile, fontSize:'65px', lineHeight:'70px'}}>
                                AI
                                <span style={{fontFamily:`${Styles.avenirMediumCondensedItalic}, ${Styles.avenirRegular}`, fontSize:'50px', display:'inline-block', lineHeight:'20px'}}>&nbsp; for</span><br/>
                                <span style={{color:'#27CCE1', display:'inline-block'}}>BOND</span><br/>
                                TRADERS
                            </p>
                          </MediaQuery>

                          <MediaQuery minWidth={651} maxWidth={800}>
                            <p style={heroMainTextMobile}>
                                AI
                                <span style={{fontFamily:`${Styles.avenirMediumCondensedItalic}, ${Styles.avenirRegular}`, fontSize:'50px', display:'inline-block', lineHeight:'20px'}}>&nbsp; for</span><br/>
                                <span style={{color:'#27CCE1', display:'inline-block'}}>BOND</span><br/>
                                TRADERS
                            </p>
                          </MediaQuery>

                          <MediaQuery minWidth={801}>
                            <p style={heroMainText}>
                                AI
                                <span style={{fontFamily:`${Styles.avenirMediumCondensedItalic}, ${Styles.avenirRegular}`, fontSize:'50px', display:'inline-block', lineHeight:'20px'}}>&nbsp; for</span><br/>
                                <span style={{color:'#27CCE1', display:'inline-block'}}>BOND</span><br/>
                                TRADERS
                            </p>
                          </MediaQuery>
                        </div>
                      }
                    </Spring>
                    </div>
              </div>

              <div className="landing-flex-right-side">
                <div style={{marginBottom:'30px'}}>
                  <Spring 
                    config={heroSubheadingAnimationConfig}
                    from={{opacity:'0'}}
                    to={{opacity:'1'}}
                  >
                    { props =>                     
                      <div style={props}>
                        <MediaQuery maxWidth={599}>
                          <p style={{...heroSubheadingTextMobile, marginTop:'0', textAlign:'center'}}>
                                cutting edge predictive pricing and trend forecasting apps &nbsp; for institutional traders and investors
                          </p>
                        </MediaQuery>

                        <MediaQuery minWidth={600} maxWidth={800}>
                          <p style={heroSubheadingTextMobile}>
                              cutting edge predictive pricing and trend forecasting apps &nbsp; for institutional traders and investors
                          </p>
                        </MediaQuery>

                        <MediaQuery minWidth={801}>
                          <p style={heroSubheadingText}>
                              cutting edge predictive pricing and trend forecasting apps &nbsp; for institutional traders and investors
                          </p>
                        </MediaQuery>
                          <ContactButton
                            handleClick={this.toggleForm}
                            buttonText={"TRY BONDDROID™ FOR FREE"}
                            custom={buttonStyle}
                          /> 
                      </div>
                    }
                  </Spring>
                </div>

                <MediaQuery minWidth={1000}>
                    <SocialBar height='100vh - 80px'/>
                </MediaQuery>

              </div>
            </div>
          </Hero>
          <MediaQuery minWidth={1000}>
            <Drift
              appId={process.env.REACT_APP_DRIFT_APP_ID}
              userId={process.env.REACT_APP_DRIFT_USER_ID}
              attributes={{ email: "kadeem-walsh@7-chord.com", company: "7-Chord"}}
            />
          </MediaQuery>
        </div>
      </div>
    )
  }
}
