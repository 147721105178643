import React from 'react'
import { Link } from 'react-router-dom'
import Styles from '../Styles'
import '../Styles/Navbar.css'

const CustomLink = (props) => {
    const link = {
        textDecoration:'none',
        color:`${Styles.redText}`,
        paddingRight:'20px',
        display:'block',
      }
    

    return(
        <div 
            className="navbar-link"
        >
            <div className="link-animation">          
                <Link
                    name={props.name}
                    style={{...link, color:`${props.route ? Styles.blueText : Styles.redText}`}}
                    to={props.link}
                >
                    {props.linkName}
                </Link>
                <br/>
                <Link
                    name={props.name}
                    style={{...link, color:`${props.route ? Styles.blueText : Styles.redText}`}}
                    to={props.link}
                >
                    {props.linkName}
                </Link>
            </div>

      </div>
    )
}

export default CustomLink;