import React from 'react';
import isEmail from 'validator/lib/isEmail'
import Styles from '../Styles'
import MediaQuery from 'react-responsive'
import '../Styles/Shared.css'


export default class Subscribe extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      email:'',
      valid:null
    }
  }

  updateEmail = (evt) => {
    const email = evt.target.value
    this.setState({email})
  }

  handleSubmit = (evt) => {
    evt.preventDefault()
    if (isEmail(this.state.email) !== true) {
      const valid = false
      this.setState({valid})
    } else {
      const valid = true
      this.setState({valid})
      const requestBody = {
        key:'CbFxeEOj5VKuoSZXbfF4JCNOTeT3QFC8',
        email:this.state.email
      }

      const params = {
        method:'POST',
        body:JSON.stringify(requestBody),
        headers: {
          "content-type": "application/json"
        }
      }
      fetch('/subscribe', params)
        .then(res => console.log(res.text()))
        .catch(err => console.log(`error: ${err}`))
    }

  }

  render(){
  const inputStyle = {
        backgroundColor:'rgba(100,100,100,.5)',
        height:'27px',
        width:'200px',
        color:`${Styles.beigeText}`,
        outline:'0',
        border:'0',
        fontSize:'16px',
        marginLeft:'10px',
        paddingLeft:'10px'
    }
  
    const mediaInputStyle = {
      ...inputStyle, 
      marginTop:'10px',
      marginLeft:'0',
      backgroundColor:'#ffffff',
      border:'1px solid rgba(38, 26, 33, .44)',
      borderRight:'0',
      color:'#261A21'
    }


    const goStyle = {
      backgroundColor:`${Styles.blueText}`,
      fontSize:'14px',
      color:'white',
      outline:'0',
      border:'0px solid #27CEE1',
      height:'29px',
      width:'51px',
      marginLeft:'1px',
      cursor:'pointer'
    }

    const goStylePress = {
      ...goStyle,
      height:'31px',
      margin:'0'
    }

    const errorStyle = {
      color:'#F56464',
      marginLeft:'7px'
    }

    const successStyle = {
      color:'#2BB673',
      marginLeft:'7px',
    }

    const footerTopStyle = {
      borderBottom:'1px solid rgba(216, 216, 216, .4)', 
      width:'80vw', marginLeft:'10vw', 
      paddingBottom:'10px',
      height:'80px',

    }

    const subscribeText = {
      color:'white',
      fontWeight:'600'
    }

    return(
      <div style={this.props.page === 'media' ? {...footerTopStyle, marginLeft:'0', paddingBottom:'30px', borderBottom:'0'} : {...footerTopStyle, paddingBottom:'30px'}}>
        <MediaQuery maxWidth={538}>
          <form style={{display:'inline-block', paddingTop:'30px'}}onSubmit={this.handleSubmit}>
            <label style={this.props.page === 'media' ? {...subscribeText, color:'#261A21'} : subscribeText}>
              Subscribe to Updates
              <br/>
              <input
                id="footer"
                type="text"
                placeholder="Email"
                className={!this.props.press ? "subscription" : "press-subscribe"}
                onChange={this.updateEmail}
                value={this.state.email}
                style={inputStyle}
              />
            </label>
            <input
              type="submit"
              value={"GO"}
              style={!this.props.press ? goStyle : goStylePress}

            />
          </form>
        </MediaQuery>

        <MediaQuery minWidth={539}>
          <form style={{display:'inline-block', paddingTop:'30px'}}onSubmit={this.handleSubmit}>
            <label style={this.props.page === 'media' ? {...subscribeText, color:'#261A21'} : subscribeText}>
              Subscribe to Updates
              {this.props.page === 'media' ? <br/> : null}
              <input
                id="footer"
                type="text"
                placeholder="Email"
                className={!this.props.press ? "subscription" : "press-subscribe"}
                onChange={this.updateEmail}
                value={this.state.email}
                style={this.props.press ? mediaInputStyle : inputStyle}
              />
            </label>
            <input
              type="submit"
              value={"GO"}
              style={!this.props.press ? goStyle : goStylePress}

            />
          </form>
        </MediaQuery>

        <MediaQuery maxWidth={663}>
          {this.state.valid === true ? <div><span style={{...successStyle, display:'block', paddingTop:'5px'}}>Subscribed!</span></div> : null}
          {this.state.valid === false ? <div><span style={{...errorStyle, display:'block', paddingTop:'5px'}}>Invalid Email</span></div> : null}
        </MediaQuery>

        <MediaQuery minWidth={664}>
          {this.state.valid === true ? <span style={successStyle}>Subscribed!</span> : null}
          {this.state.valid === false ? <span style={errorStyle}>Invalid Email</span> : null}
        </MediaQuery>
      </div>
    )
  }
}
