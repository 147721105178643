import React from 'react'

const ExcelContent = (props) => {
    return(
        <div>
            Supports Microsoft Excel 2010 and up
        </div>
    )
}

export default ExcelContent;