import React from 'react'

const ContactContent = (props) => {
    return(
        <div>
            You can contact us at support@7-chord.com.  We typically reply within several hours.
        </div>
    )
}

export default ContactContent

