import React from 'react'

const FtpContent = (props) => {

    const buttonStyle = {
        border:'0',
        outline:'0',
        cursor:'pointer',
        textDecoration:'underline',
        padding:'0'
    }

    return(
        <div>
            <h2>FTP</h2>
            <p>We are backed by an elite group of Wall Street Executives</p>
            <h2>Delivery</h2>
            <p>We are backed by an elite group of Wall Street Executives</p>
            <h2>Connectivity</h2>
            <p>We are backed by an elite group of Wall Street Executives</p>

            <button 
                style={buttonStyle}
                onClick={props.downloadFtp}>This</button> document provides connectivity and access information for connecting to the 7 Chord Secure File Transfer Protocol(SFTP)
            server using Secure Sockets Layer(SSL) encrypted communication.  Data files are made available on the 7 Chord SFTP server 
            to be pulled by clients.
        </div>
    )
}

export default FtpContent;