import React from 'react'
import ContactIcon from '../../../images/Shared/ContactOptimized.png'


const ContactContracted = (props) => {
  const condensed = {
    height:'70px',
    width:'70px',
    position:'absolute',
    left:props.left,
    top:'10px',
    cursor:'pointer'
  }
  // props.view !== 'home' ? condensed.top = '0px' : condensed.top = '0px'

  return(
    <img
      onClick={props.handleClick}
      onMouseEnter={props.handleClick}
      style={condensed}
      src={ContactIcon}
      alt="Click to see contact information"
    />
  )
}

export default ContactContracted;
