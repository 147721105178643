import React from 'react'
import './Styles/Row.css'

const Row = (props) => {
    return(
        <div className="row-container" style={props.custom}>
            {props.children}
        </div>
    )
}
export default Row;
