import React from 'react'
import AboutCard from '../components/About/AboutCard'
import Endorsement from '../components/About/Endorsement'
import Hero from '../components/Shared/Hero'
import Navbar from '../components/Shared/Navbar/index'
import Footer from '../components/Shared/Footer/index'
import Kristina from '../images/About/kristina.jpg'
import Pat from '../images/About/pat.jpg'
import Roy from '../images/About/roy.jpg'
import Kadeem from '../images/About/kadeem.jpg'
import Oleg from '../images/About/oleg.jpg'
import Dennis from '../images/About/dennis.jpg'
import Jim from '../images/About/jim.jpg'
import Denis from '../images/About/denis.jpg'
import Sam from '../images/About/sam.jpg'
import Sarah from '../images/About/sarah.jpg'
import Krishan from '../images/About/krishan.jpg'
import HeroS from '../images/About/aboutHeroM.jpg'
import HeroM from '../images/About/aboutHeroM.jpg'
import HeroL from '../images/About/aboutHero.jpg'
import HeroXL from '../images/About/aboutHero.jpg'
import Styles from '../components/Shared/Styles'
import Sandbox from '../images/About/sandbox.jpg'
import Barclays from '../images/About/barlcays.jpg'
import MediaQuery from 'react-responsive'
import './Styles/About/About.css'

export default class About extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      sandbox:false,
      barclays:false,
      cdl:false
    }
  }
  componentDidMount = () => window.scrollTo(0,0)
  

  addColor = (name) => {
    let newState = Object.assign({}, this.state)
    newState[name] = true
    this.setState(newState)
  }

  removeColor = (name) => {
    let newState = Object.assign({}, this.state)
    newState[name] = false
    this.setState(newState)    
  }

  render(){
    const angelListButton = {
      fontFamily: `${Styles.avenirDemi}`,
      width:'110px'
    }

    const ourFirmText = {
      color:'#27CCE1',
      textAlign:'right',
      display:'block',
      fontSize:'15px',
      fontWeight:'600'
    }

    const heroTextLeft = {
      fontFamily:`${Styles.avenirBold}`,
      fontSize:'41px',
      width:'300px',
      textAlign:'right',
      margin:'0'
    }

    const heroTextRight = {
      width:'350px',
      fontSize:'15px',
      lineHeight:'23px'
    }

    const headingStyle = {
      fontSize:'23px',
      fontFamily:`${Styles.avenirBold}`,
      color:'rgb(38, 26, 33)',
      marginRight:'30px',
      width:'300px',
      textAlign:'center'
    }

    const container = {
      position:'relative',
      bottom:'65vh',
      color:'#fff'
    }

    return(
      <div>
        <Navbar about={true}/>
        <Hero
            height="65vh"
            width="100vw"
            imageS={HeroS}
            imageM={HeroM}
            imageL={HeroL}
            imageXL={HeroXL}
            customStyle={{marginTop:'15px'}}>

              <MediaQuery maxWidth={900}>
                <div style={container} className="about-hero-med-small">
                  <span style={ourFirmText}>OUR FIRM</span>
                  <h2 style={{...heroTextLeft, textAlign:'center'}}>
                    deep tech <span style={{color:'#27CCE1'}}>meets</span> trading expertise
                  </h2>
                  <p style={{width:'50%', textAlign:'center'}}>
                    while others are just paying lip service to AI, our team of former traders, 
                    machine learning researchers and trading technologists have purpose built 
                    revolutionary smart system from the ground up
                  </p>
                  <button>
                    Join Us
                  </button>
                </div>
              </MediaQuery>

              <MediaQuery minWidth={901} maxWidth={1999}>
                <div className="about-hero-container" style={container}>
                  <div className="about-hero-left">
                    <div className="about-hero-left-content">

                      <div style={{height:'20px', position:'relative', bottom:'30px'}}>
                        <span style={ourFirmText}>OUR FIRM</span>
                      </div>

                      <div style={{height:'130px'}}>
                        <h2 style={heroTextLeft}>
                          deep tech <span style={{color:'#27CCE1'}}>meets</span> trading expertise
                        </h2>
                      </div>

                      <div style={{height:'20px'}} />

                    </div>
                  </div>

                  <div className="about-hero-right">
                    <div className="about-hero-right-content">
                      <div style={{height:'20px'}} />
                      <div style={{height:'130px'}}>
                        <p style={heroTextRight}>
                          while others are just paying lip service to AI, our team of former traders, 
                          machine learning researchers and trading technologists have purpose built 
                          revolutionary smart system from the ground up
                        </p>                      
                      </div>
                      <div style={{height:'20px'}}>
                        <a target="_blank" href={"https://angel.co/company/7-chord-1/jobs"}>
                          <button style={angelListButton} className="angel-list-button">
                            Join Us
                          </button>
                        </a>
                      </div>
                    </div>
                    <div>
                    </div>
                  </div>
                </div>
              </MediaQuery>

              <MediaQuery minWidth={2000}>
                <div className="about-hero-container" style={container}>
                  <div className="about-hero-left">
                    <div className="about-hero-left-content">

                      <div style={{height:'20px', marginBottom:'30px', position:'relative', bottom:'20px'}}>
                        <span style={{...ourFirmText, fontSize:'23px'}}>OUR FIRM</span>
                      </div>

                      <div style={{height:'150px'}}>
                        <h2 style={{...heroTextLeft, fontSize:'56px', width:'380px'}}>
                          deep tech <span style={{color:'#27CCE1'}}>meets</span> trading expertise
                        </h2>
                      </div>

                      <div style={{height:'20px'}} />

                    </div>
                  </div>

                  <div className="about-hero-right">
                    <div className="about-hero-right-content">
                      <div style={{height:'20px', marginBottom:'30px'}} />
                      <div style={{height:'150px'}}>
                        <p style={{...heroTextRight, fontSize:'23px', width:'400px', lineHeight:'27px'}}>
                          while others are just paying lip service to AI, our team of former traders, 
                          machine learning researchers and trading technologists have purpose built 
                          revolutionary smart system from the ground up
                        </p>                      
                      </div>
                      <div style={{height:'20px', marginTop:'30px', position:'relative', top:'20px'}}>
                        <button style={angelListButton} className="angel-list-button">
                          Join Us
                        </button>          
                      </div>
                    </div>
                    <div>
                    </div>
                  </div>
                </div>
              </MediaQuery>
        </Hero>
          
          <MediaQuery maxWidth={704}>
            <p style={{width:'90vw', margin:'50px auto 80px auto', textAlign:'center'}}>
              <span style={{color:'#262A21', fontFamily:`${Styles.avenirBold}`, fontSize:'23px'}}>
                Experts can tell the difference.
              </span><br/>
              <br/>
              We are backed by an elite group of Wall Street executives, strategic investors and
              prestigious fintech accelerators.
            </p>
          </MediaQuery>

          <MediaQuery minWidth={705}>
            <p style={{width:'60vw', margin:'50px auto 80px auto', textAlign:'center'}}>
              <span style={{color:'#262A21', fontFamily:`${Styles.avenirBold}`, fontSize:'23px'}}>Experts can tell the difference.</span><br/>
              <br/>We are backed by an elite group of Wall Street executives, strategic investors and prestigious fintech accelerators.
            </p>
          </MediaQuery>



          <div className="about-flex-container">
            <Endorsement
              name="sandbox"
              type={"image/svg+xml"}
              addColor={this.state.sandbox}
              alternate="Fintech SandBox Endorsement"
              website={"https://fintechsandbox.org/"}
              image={Sandbox}
              hoverIn={this.addColor}
              hoverOut={this.removeColor}
            />

            <Endorsement
              name="barclays"
              type={"image/jpg"}
              addColor={this.state.barclays}
              alternate="Barclays Accelerator Endorsement"
              website={"http://www.barclaysaccelerator.com/"}
              image={Barclays}
              hoverIn={this.addColor}
              hoverOut={this.removeColor}
            />

          </div>

          
        <div className="title-flex-container">
          <p style={headingStyle}>Team</p>
        </div>

        <div className="about-flex-container">
          <AboutCard
            name={"KRISTINA FAN"}
            title={"CEO AND FOUNDER"}
            bio={
             [`Survived twenty years in credit trading and market structure; wants to use the next twenty to turn sci-fi ideas into industry-shifting products. Previously at BlackRock, JP Morgan and Bear Stearns. Recipient of the 2017 Markets Media Women in Finance Award: Excellence in Trading Platforms. MSc from LSE and a BA from NYU.`]
            }
            linkedIn={"https://www.linkedin.com/in/kristinafan/"}
            image={Kristina}
          />

          <AboutCard
            name={"ROY LOWRANCE"}
            title={"CHIEF SCIENTIST"}
            bio={
              `Visionary technologist, AI researcher, and the brain behind BondDroid. Former CTO at Capital One and Reuters, he designed Center for Data Science at NYU. PhD in Machine Learning under the supervision of Yann LeCun and Dennis Shasha. MBA from Harvard. BA in Math from Vanderbilt.`
            }
            linkedIn={"https://www.linkedin.com/in/roylowrance/"}
            image={Roy}
          />
        </div>
        <div className={"about-flex-container"}>
          <AboutCard
            name={"SAM MAO"}
            title={"VP OF ENGINEERING"}
            bio={`Cut his machine learning teeth on computational DNA research project at Structural DNA 
            Nanotechnology Lab at NYU.  He holds degrees in Chemistry and Computer Science from NYU. Bronx 
            Science graduate, aspiring chef and Manchester United football club fan.`}
            linkedIn={"https://www.linkedin.com/in/samuel-mao/"}
            image={Sam}/>

          <AboutCard
            name={"DENIS VRITOV"}
            title={"MACHINE LEARNING ENGINEER"}
            bio={`Data scientist and software engineer. A sailing, skiing and moto enthusiast, and survivor 
            of Dr. Shasha’s Omniheurist Puzzle class. M.S. candidate in CS at NYU and B.S. in Applied Math 
            and CS from the University of Connecticut. `}
            linkedIn={"https://www.linkedin.com/in/denis-vritov-16236a143/"}
            image={Denis}/>

          <AboutCard
            name={"KADEEM WALSH"}
            title={"SOFTWARE ENGINEER"}
            bio={`Software engineer and proud, native New Yorker. Holds a B.S. in Chemistry from NYU, though 
            his love of building has moved him to computer science. Notable research projects include using 
            modeling software to investigate protein binding in metabolic pathways.`}
            linkedIn={"https://www.linkedin.com/in/kadeem-walsh-a75811128/"}
            image={Kadeem}/>
          
        </div>

        <div className="title-flex-container">
            <p style={headingStyle}>Advisors</p>
        </div>


        <div className="about-flex-container">
          <AboutCard
            name={"DENNIS SHASHA"}
            title={""}
            bio={`Renowned professor of computer science at the Courant Institute at NYU. Well-known on 
            Wall Street for his research in pattern discovery in time series; previously at IBM’s Data 
            Systems Division. He holds a Ph.D from Harvard and B.S. from Yale. CV can be found at:
            www.cs.nyu.edu/shasha`}
            linkedIn={"https://www.linkedin.com/in/dennis-shasha-a91a567/"}
            image={Dennis}/>

          <AboutCard
            name={"SARAH COLE"}
            title={""}
            bio={[ `Credit trading veteran with 15+ years of experience in high-yield markets. Currently, 
            she serves as Managing Director at Barclays. She holds a MBA from Columbia and a B.A. from Georgetown. 
            As well, she is a Women-on-Boards participant and Brooklyn mom of two.`]}
            linkedIn={"https://www.linkedin.com/in/sarah-cole-99918357/"}
            image={Sarah}/>

          <AboutCard
            name={"OLEG MOVCHAN"}
            title={""}
            bio={`Asset management industry veteran. Active investor in the global legal cannabis 
            industry, capital markets infrastructure, consumer finance, cyber security, and real estate. 
            MS degree in Financial Mathematics and MBA 
            in Analytic Finance from the University of Chicago. MS degree in Applied Math and 
            Physics from Kharkov State University, Ukraine.`}
            linkedIn={"https://www.linkedin.com/in/olegmovchan/"}
            image={Oleg}/>

          <AboutCard
            name={"JIM MILLER"}
            title={""}
            bio={`Technology entrepreneur and accomplished CTO. He was the first employee of CreditEx, 
            an electronic marketplace for CDS trading. As well, he was Co-founder and CTO of trueEx. He is a 
            passionate mentor and advisor to startups and growth companies. Studied MIS at Virginia Tech.`}
            linkedIn={"https://www.linkedin.com/in/jimmiller1/"}
            image={Jim}/>

          <AboutCard
            name={"PAT DEFRANCESCO"}
            title={""}
            bio={`Expert in deploying and maintaining large scale low-latency trading systems in production.
            Previously was Vice President of Credit Technology at ICE.  Creditex alumni, investor and technology 
            advisor to early-stage start-ups. MS from Columbia and a BA from Saint Bonaventure University.`}
            linkedIn={"https://www.linkedin.com/in/pat-defrancesco/"}
            image={Pat}/>

          <AboutCard
            name={"KRISHAN SINGH"}
            title={""}
            bio={`Angel investor, fintech exec, and crypto enthusiast. His focus is on developing institutional 
            market structures for crypto trading. Previously, ran CreditEx in London before becoming President 
            and overseeing global operations. B.S. from the Stern School at NYU.`}
            linkedIn={"https://www.linkedin.com/in/krishansingh1/"}
            image={Krishan}/>


        </div>
        <Footer/>
      </div>
    )
  }
}
